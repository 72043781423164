import firebase from '../database/FirebaseConfig';

const db = firebase.firestore();


async function UpdateAllOccurrencesToCheck(uid: string) {

    db.collection('notifications').doc(uid).update({
        check: true,
    }).then(() => {
        console.log('Profile Successfully Edited!');
    }).catch((error: any) => {
        console.log('Error updating the document:', error);
    });
}
async function UpdateNotifications(uid: string, msg: string) {

    db.collection('notifications').doc(uid).update({
        response: msg,
    }).then(() => {
        console.log('Profile Successfully Edited!');
    }).catch((error: any) => {
        console.log('Error updating the document:', error);
    });
}

export { UpdateAllOccurrencesToCheck, UpdateNotifications };