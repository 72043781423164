import { FormsData, IForms, IFormsFormatted, IFormsFormattedSnapshot, IFormsSnapshot, } from 'ctx/useForms';
import { dbForms } from '../database/FirebaseConfig';


export async function GetAllForms() {
    const formRef = await dbForms.collection("forms");
    const snapshot = await formRef.get();
    const dataFormattedArray = [] as IFormsFormatted[];

    await Promise.all(snapshot.docs.map(async doc => {  //Fetching in Paralel the data
        const docData = doc.data() as IForms
        const aux = [] as FormsData[];
        const uid = doc.id;

        for (const id of docData.formsFieldId) {
            const field = await GetOneFormsField(id);
            aux.push(field);
        }

        const dataFormatted = { uid, ...docData, formsFieldId: [...aux] };

        dataFormattedArray.push(dataFormatted);
    }));

    return dataFormattedArray;
}

const GetOneFormsField = async (id: string) => {
    const formFieldRef = await dbForms.collection("form_field").doc(id).get();
    const formField = {
        ...formFieldRef.data(),
        uid: formFieldRef.id,
    } as FormsData
    return formField;
}

export async function GetAllFormsSnapshot(setAllFormsSnapshot: React.Dispatch<React.SetStateAction<IFormsFormattedSnapshot[]>>) {
    const formRef = await dbForms.collection("forms");

    formRef.onSnapshot(async querySnapshot => {
        const dataFormattedArray = [] as IFormsFormattedSnapshot[];

        querySnapshot.forEach(async doc => {
            const docData = doc.data() as IFormsSnapshot
            const aux = [] as FormsData[];
            const uid = doc.id;

            for (const id of docData.formsFieldId) {
                const field = await GetOneFormsField(id);
                aux.push(field);
            }

            const dataFormatted = { uid, ...docData, formsFieldId: [...aux] };

            dataFormattedArray.push(dataFormatted);

            setAllFormsSnapshot([...dataFormattedArray]);
        })
    });
}


export async function UpdateOneForm(id: string, active: boolean) {
    const formFieldRef = await dbForms.collection("forms").doc(id);
    await formFieldRef.update({ active });
}




