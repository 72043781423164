import { useState } from 'react';
import { Colors, Images } from 'utils/template';
import firebase from '../database/FirebaseConfig';

function UseUpdateColorTheme(color: string, uid: string) {
    const db = firebase.firestore();

    db.collection('colors').doc(uid).update({
        'color': color,
    }).then(() => {
        console.log('Profile Successfully Edited!');
    }).catch((error: any) => {
        console.log('Error updating the document:', error);
    })
}

function ResetChanges(uid: string) {
    const db = firebase.firestore();

    db.collection('colors').doc(uid).update({
        logo: Images.logo,
        background: Images.background,
        color: Colors.secondary
    }).then(() => {
        console.log('Profile Successfully Edited!');
    }).catch((error: any) => {
        console.log('Error updating the document:', error);
    })
}


function UseUpdateColorThemeLogo(uid: string, logo: any) {
    const db = firebase.firestore();

    db.collection('colors').doc(uid).update({
        'logo': logo,
    }).then(() => {
        console.log('Profile Successfully Edited!');
    }).catch((error: any) => {
        console.log('Error updating the document:', error);
    })

}
function UseUpdateColorThemeBackground(uid: string, background: any) {
    const db = firebase.firestore();

    db.collection('colors').doc(uid).update({
        'background': background
    }).then(() => {
        console.log('Profile Successfully Edited!');
    }).catch((error: any) => {
        console.log('Error updating the document:', error);
    })

}

const useGetThemes = () => {
    const db = firebase.firestore();
    const controller = new AbortController();
    const [loading, setLoading] = useState(false);

    const getTheme = async ({ onComplete }: any) => {
        setLoading(true);

        db.collection("colors")
            .get()
            .then((querySnapshot: any) => {
                const colors = querySnapshot.docs.map((doc: any) => doc.data());
                onComplete(colors[0].color);
                setLoading(false);
            })
            .catch((error: any) => {
                setLoading(false);
                console.log("erro: ", error);
            });

        return () => {
            const timeout = setTimeout(() => controller.abort(), 5000);
            clearTimeout(timeout);
        };
    };


    return { loading, getTheme, setLoading };
}

export { ResetChanges, useGetThemes, UseUpdateColorThemeLogo, UseUpdateColorThemeBackground, UseUpdateColorTheme as default };