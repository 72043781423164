// eslint-disable-next-line
import styled from "styled-components";
import React, { memo, useEffect, useRef, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Redirect, useHistory } from "react-router-dom";
import Cookies from "js-cookie";
import MoonLoader from "react-spinners/MoonLoader";
import { css } from "@emotion/react";

// components
import { useUpdate } from "ctx/UpdateContext";
import { useCard } from "ctx/CardRasterContext";
import { CardRasterProps } from "types/InterfaceCard";

// assets
import filter from "assets/icons/filter.svg";
import searchSVG from "assets/icons/search.svg";
import checkbox from "assets/icons/checkTrue.svg";
import plus from "assets/icons/plus.svg";

// styles
import "react-tabs/style/react-tabs.css";
import { useAuth } from "ctx/AuthContext";
import { Helmet } from "react-helmet";
import Popup from "reactjs-popup";
import { Checkbox } from "components/Checkbox";
import { ResetPassword } from "components/ResetPassword";

import styles from "./styles.module.scss";
import { CollapsibleTable } from "../../components/CollapsibleTable";
import UserForms from "components/UserForms";
import CreateFormsToForms from "components/CreateFormsToForms";
import { InputFormsView } from "components/InputFormsView";
import {
  IFormsFormatted,
  IFormsFormattedSnapshot,
  useForms,
} from "ctx/useForms";
import { GetAllForms, GetAllFormsSnapshot } from "hooks/useGetForms";
import { Colors, Images, Labels } from "utils/template";

type DivProps = {
  colorTheme: string;
};

const Div = styled.div<Pick<DivProps, "colorTheme">>`
  * {
    --secondary: ${(props) => props.colorTheme};
  }
`;

const StyledPopup = styled(Popup)`
  &-content {
    padding: 0;
    border-radius: 8px;
    width: 700px;
    border: none;
  }
`;
const StyledPopupMenu = styled(Popup)`
  &-content {
    width: 10px;
    border: none;
  }
`;

const StyledCheckBox = styled(Checkbox)`
  border-left: 1px solid var(--black);
  border-right: 1px solid var(--black);
`;

export default function DocumentsView() {
  const { switchTabs, tabs } = useUpdate();
  const colorTheme = Cookies.get("colorTheme");

  const override = css`
    display: block;
    margin: 20% auto;
    border-color: red;
  `;
  // saving of values from checkbox
  const [listAll, setListAll] = useState(true);
  const [delivery, setDelivery] = useState(false);
  const [collect, setCollect] = useState(false);
  const [finish, setFinish] = useState(false);
  // Store Documents and Romaneios
  const [dataDocuments, setDataDocuments] = useState<any>([]);
  const cardIdWillNotChange = useRef<CardRasterProps[]>([]);
  const formsWillNotChange = useRef<IFormsFormatted[]>([]);
  // filter id's
  const [param, setParam] = useState<number>(0);
  const currentUserForms = Boolean(Cookies.get("currentUser"));
  // to store occurrence of search
  // uid of card to create romaneios
  const { allDocs } = useCard();
  const useSearch = useRef<any>(false);
  const [openResetPassword, setOpenResetPassword] = useState(false);
  const handleOpenResetPassword = (value: boolean) =>
    setOpenResetPassword(value);
  const [openMenu, setOpenMenu] = useState(false);
  const { setStateCurrentUserPainel, userInfoPainel } = useAuth();
  const history = useHistory();
  const style = { background: "var(--secondary)" };
  const [allForms, setAllForms] = useState<IFormsFormatted[]>([]);
  const [allFormsSnapshot, setAllFormsSnapshot] = useState<
    IFormsFormattedSnapshot[]
  >([]);

  const { refetch, isFormActive, setIsFormActive } = useForms();

  const loadData = async () => {
    try {
      const data = await GetAllForms();
      await GetAllFormsSnapshot(setAllFormsSnapshot);

      setAllForms([...data]);
      formsWillNotChange.current = data;
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    loadData();
  }, [refetch]);

  function searchFields(e: any) {
    useSearch.current = true;
    let data = [] as IFormsFormatted[];
    if (e.target.value === "") {
      useSearch.current = false;
      console.log(formsWillNotChange.current);
      setAllForms([...formsWillNotChange.current]);
      return;
    }
    for (let i = 0; i < allForms.length; i++) {
      if (allForms[i].uid === e.target.value) {
        data.push(allForms[i]);
        setAllForms([...data]);
      } else if (
        allForms[i].formDescription
          .toLocaleUpperCase()
          .includes(e.target.value.toLocaleUpperCase())
      ) {
        data.push(allForms[i]);
        setAllForms([...data]);
      } else if (
        allForms[i].company.name
          .toLocaleUpperCase()
          .includes(e.target.value.toLocaleUpperCase())
      ) {
        data.push(allForms[i]);
        setAllForms([...data]);
      }
    }
  }

  if (currentUserForms === undefined || currentUserForms === false) {
    return <Redirect to="/forms" />;
  }

  if (!currentUserForms) {
    return (
      <div className={styles.sweetLoading}>
        <MoonLoader color="var(--secondary)" loading={true} css={override} size={75} />
      </div>
    );
  }

  colorTheme
    ? document.documentElement.style.setProperty("--secondary", colorTheme)
    : document.documentElement.style.setProperty("--secondary", "var(--secondary)");

  return (
    <Div colorTheme={colorTheme || Colors.secondary}>
      <div className={styles.bodyForms}>
        <header>
          <Helmet>
            <title>`Painel ${Labels.name}`</title>
          </Helmet>
          <img
            src={Images.logo}
            alt=""
          />
          <StyledPopupMenu
            trigger={
              <button
                type="button"
                id="buttonOpenPopup"
                onClick={() => setOpenMenu(!openMenu)}
              >
                <label id="painel-users-label">R</label>
              </button>
            }
            className="mypopup-menu"
            position={["top center", "bottom right", "bottom left"]}
            open={openMenu}
            closeOnDocumentClick={openResetPassword}
            lockScroll
          >
            <div className="block-menu-painel">
              <legend
                onClick={() => {
                  setStateCurrentUserPainel();
                  sessionStorage.removeItem("user-painel");
                  Cookies.remove("currentUser");
                  Cookies.remove("name");
                  Cookies.remove("cpf_cnpf");
                  Cookies.remove("company");
                  history.push("/forms");
                }}
              >
                Sair
              </legend>
              <ResetPassword
                uid={userInfoPainel.uid}
                style={style}
                collection="painel"
                handleOpenResetPassword={handleOpenResetPassword}
              >
                <>Alterar senha</>
              </ResetPassword>
            </div>
          </StyledPopupMenu>
        </header>

        <Tabs>
          <TabList>
            <Tab
              // disabled={tab}
              className="react-tabs__tab"
            >
              <legend>Gestão de formulários</legend>
              {/* <AiOutlineCloseCircle
                                // onClick={() => setTab(false)}
                                size={20}
                                className='iconClose'
                            /> */}
            </Tab>
          </TabList>

          <TabPanel>
            <main>
              <div className={styles.manualTabs}>
                <div className={styles.firstTabs}>
                  <span
                    className={tabs === 1 ? styles.focusClick : ""}
                    onClick={() => switchTabs(1)}
                  >
                    Forms
                  </span>
                  <span
                    className={tabs === 2 ? styles.focusClick : ""}
                    onClick={() => switchTabs(1)}
                  >
                    Forms Preenchidos
                  </span>
                </div>
              </div>
              <div />
              {tabs && (
                <>
                  <section>
                    <div className={styles.btnsDocument}>
                      <div className={styles.createRegister}>
                        <CreateFormsToForms
                          Target={
                            <InputFormsView
                              title="Novo Formulário"
                              img={plus}
                              select={true}
                            />
                          }
                          title="cadastrar formulário"
                        />
                      </div>

                      <div className={styles.blockCheckbox}>
                        {" "}
                        {/* Filtrar Formularios */}
                        <label className="filter">
                          <img src={filter} alt="search" />
                          <span>Filtrar:</span>
                        </label>
                        <div className={styles.inputCheckbox}>
                          {" "}
                          {/* Ver todos */}
                          <input
                            type="checkbox"
                            onClick={() => {
                              setListAll(true);
                              setCollect(false);
                              setDelivery(false);
                              setFinish(false);
                              setParam(0);
                            }}
                          />
                          <label>todos</label>
                          {listAll === true && (
                            <img
                              src={checkbox}
                              alt="checkbox true"
                              onClick={() => setListAll(true)}
                            />
                          )}
                        </div>
                        <div className={styles.inputCheckbox}>
                          {" "}
                          {/* Ativo */}
                          <input
                            type="checkbox"
                            onClick={() => {
                              setListAll(false);
                              setCollect(true);
                              setDelivery(false);
                              setFinish(false);
                              setParam(1);
                            }}
                          />
                          <label>ativo</label>
                          {collect === true && (
                            <img
                              src={checkbox}
                              alt="checkbox true"
                              onClick={() => setCollect(true)}
                            />
                          )}
                        </div>
                        <div className={styles.inputCheckbox}>
                          {" "}
                          {/* Inativo */}
                          <input
                            type="checkbox"
                            onClick={() => {
                              setListAll(false);
                              setCollect(false);
                              setDelivery(true);
                              setFinish(false);
                              setParam(2);
                            }}
                          />
                          <label>inativo</label>
                          {delivery === true && (
                            <img
                              src={checkbox}
                              alt="checkbox true"
                              onClick={() => setDelivery(true)}
                            />
                          )}
                        </div>
                      </div>

                      <div className={styles.search}>
                        <img
                          src={searchSVG}
                          alt="search"
                          onClick={searchFields}
                        />
                        <input
                          type="text"
                          placeholder="pesquisa por descrição, empresa"
                          onChange={(e: any) => {
                            searchFields(e);
                          }}
                        />
                      </div>
                    </div>
                  </section>

                  <CollapsibleTable forms={allForms} />
                </>
              )}

              {!tabs && (
                <div className="cardsRomaneios">
                  <UserForms />
                  <UserForms />
                </div>
              )}
            </main>
          </TabPanel>
        </Tabs>
      </div>
    </Div>
  );
}
