import styled from "styled-components";
import { Popup } from "reactjs-popup";
import { useState } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useAlert } from "react-alert";
import styles from "./styles.module.scss";
import IncludeFieldForm from "components/IncludeFieldForm";
import { useEffect, useRef } from "react";
import { FormsData, IForms, useForms } from "ctx/useForms";
import { useForm, SubmitHandler } from "react-hook-form";
import FormField from "../FormField";
import { updateForm } from "hooks/useCreateForms";
import { getCompanies } from "hooks/useGetCompanie";
import { deleteFormField } from "hooks/useDeleteForms";
import { default as CreateFormsField } from "hooks/useCreateFormsField";

const StyledPopup = styled(Popup)`
  &-content {
    padding: 0px;
    border: none;
    border-radius: 18px;
  }

  @media (max-width: 520px) {
    &-content {
      width: 95%;
    }
  }
`;

type rowType = {
  code: string;
  description: string;
  company: {
    id: string;
    name: string;
  };
  active: boolean;
  uid: string;
  history: {
    uid: string | undefined;
    name: string;
    description: string;
    type: string;
    size: number;
    maxMin: string;
  }[];
};

interface UpdateFormProps {
  Target: JSX.Element;
  title: string;
  formData: rowType;
}

export function UpdateForm({ Target, title, formData }: UpdateFormProps) {
  //useState
  const [open, setOpen] = useState(false);
  const [Company, setCompany] = useState("");
  const [openModal2, setOpenModal2] = useState(false);
  const [description, setDescription] = useState("");
  const [changeClass, setChangeClass] = useState(0);
  const [selectedForm, setSelectedForm] = useState<string | number | undefined>(
    ""
  );
  const [companies, setCompanies] = useState<any[]>([]);
  const [fields, setFields] = useState<FormsData[]>([]);
  const [fieldsToDelete, setFieldsToDelete] = useState<string[]>([]);
  const [fieldsToCreate, setFieldsToCreate] = useState<FormsData[]>([]);
  //useRef
  const ref = useRef<HTMLDivElement>(null);
  const initialFieldsState = useRef(formData.history);
  //hooks
  const alert = useAlert();
  const { setRefetch, refetch, formsField, setFormsField } = useForms(); //Forms Context API
  const { handleSubmit } = useForm();
  //functions
  const closeModal = () => setOpen(false);
  const closeModal2 = () => setOpenModal2(false);

  useEffect(() => {
    loadCompanies();
    if (formsField.name !== undefined) {
      setFields((prev) => [...prev, formsField]);
      setFieldsToCreate((prev) => {
        return [...prev, formsField];
      });
    } else {
      setFields([...initialFieldsState.current]);
    }
  }, [formsField]);

  useEffect(() => {
    setFields([...initialFieldsState.current]);
  }, [refetch]);

  async function loadCompanies() {
    const dataCompanies = await getCompanies();
    setCompanies(dataCompanies);
  }

  function clearFields() {
    setDescription("");
    setCompany("");
  }

  function exitModal() {
    clearFields();
    setChangeClass(0);
    closeModal();
    setFields(initialFieldsState.current);
    setFieldsToCreate([]);
    setFormsField({} as FormsData);
  }

  const onSubmit: SubmitHandler<IForms> = async () => {
    let formsFieldId = [] as string[];

    if (fields.length === 0) {
      alert.error("Insira algum campo para o formulário");
      return;
    }

    try {
      for (const field of fieldsToCreate) {
        const uid = await CreateFormsField(field);
        formsFieldId = [...formsFieldId, uid];
      }

      const newFieldsWithNoUndefined = fields.filter(
        (field) => field.uid !== undefined
      );

      formsFieldId = [
        ...formsFieldId,
        ...newFieldsWithNoUndefined.map((uid) => uid.uid!),
      ];

      const dataFormatted = {
        company: formData.company,
        formDescription: formData.description,
        formsFieldId,
      };
      await updateForm(formData.uid, dataFormatted);

      for (const field of fieldsToDelete) {
        await deleteFormField(field);
      }

      alert.success("Campo de formulário atualizado com sucesso");
      setRefetch((prev) => !prev);

      const fieldFormatted = fields.map((field) => {
        return {
          uid: field.uid,
          name: field.name,
          description: field.description,
          type: field.type,
          size: field.size,
          maxMin: `${field.min} - ${field.max}`,
        };
      });

      initialFieldsState.current = [...fieldFormatted];
      setFields([]);
    } catch {
      alert.success("Erro ao atualizar campo no formulário");
    }
    exitModal();
  };

  async function handleDeleteField() {
    if (selectedForm !== undefined) {
      setFields((prev) => {
        const newFields = prev.filter((field, index) => index !== selectedForm);
        return newFields;
      });
      setSelectedForm(undefined);
      return;
    } else {
      alert.error("Field ID Undefined!");
    }
  }

  return (
    <div>
      <StyledPopup
        trigger={
          <div>
            <button id="buttonOpenPopup" onClick={() => setOpen(!open)}>
              {Target}
            </button>
          </div>
        }
        open={open}
        closeOnDocumentClick={!open}
        onClose={closeModal}
        modal
        position="top center"
        nested={true}
        lockScroll
      >
        <div className={styles.popupCreateDocument}>
          <div className={styles.headerDocument}>
            <span>{title}</span>
            <AiOutlineCloseCircle
              color="white"
              style={{ cursor: "pointer" }}
              onClick={() => {
                exitModal();
              }}
              size={22}
            />
          </div>
          <form
            key={1}
            id="myForm"
            style={{ overflow: "scroll", paddingBottom: 20 }}
          >
            <div className={styles.subHeaderContainer}>
              <h3>Empresa: {formData.company.name}</h3>
              <h4>Descriçãp: {formData.description}</h4>
            </div>
            <legend>Informações do formulário</legend>
            <div className={styles.blockForms}>
              <span>Ordem</span>
              <span>Nome</span>
              <span>Descrição</span>
              <span>Tipo</span>
              <span>Tamanho</span>
              <span>Min/Max</span>
            </div>
            <section className={styles.blockFormsContainer} ref={ref}>
              {fields.map((form, index) => {
                return (
                  <FormField
                    key={index}
                    form={form}
                    index={index}
                    onSelectForm={setSelectedForm}
                    formsFieldRef={ref}
                  />
                );
              })}
            </section>

            <div className={styles.submitFormsContainer}>
              <button type="button" onClick={() => exitModal()}>
                Cancelar
              </button>
              <button type="submit">Salvar</button>
            </div>
          </form>
          <div className={styles.buttonFormsContainer}>
            <StyledPopup
              trigger={
                <div>
                  <button
                    type="button"
                    onClick={() => setOpenModal2(!openModal2)}
                  >
                    Incluir
                  </button>
                </div>
              }
              position="top center"
              modal
              open={openModal2}
              nested={true}
              onClose={closeModal2}
              closeOnDocumentClick={false}
              lockScroll
            >
              <IncludeFieldForm setOpenModal={setOpenModal2} />
            </StyledPopup>
            {/* <button type="button">Alterar</button> */}
            {selectedForm !== undefined && (
              <button
                type="button"
                onClick={() => {
                  handleDeleteField();
                }}
              >
                Excluir
              </button>
            )}
          </div>
        </div>
      </StyledPopup>
    </div>
  );
}
