import React, { useCallback, useEffect, useRef, useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { AiOutlineCloseCircle } from "react-icons/ai";
import Popup from "reactjs-popup";
import { useAlert } from "react-alert";
import Cookies from "js-cookie";

// assets
import arrowUp from "assets/icons/arrowUp.svg";
import arrowDown from "assets/icons/arrowDown.svg";

// components
import {
  UpdateStatusRomaneioToDemandsDone,
  UpdateStatusRomaneioToDone,
  UpdateStatusRomaneioToWainting,
  UpdateStatusTotalFinishRomaneio,
  UpdateStatusRomaneioDelivery,
} from "hooks/useUpdateRomaneio";

import {
  FinishCard,
} from "hooks/useUpdateCard";

import DND from "ctx/DNDContext";
import { CreateRomaneio } from "../CreateRomaneio/";
import { useUpdate } from "ctx/UpdateContext";
import { useApi } from "ctx/ApiContext";
import { useCard } from "ctx/CardRasterContext";

import FirebaseConfig, { db } from "database/FirebaseConfig";

import { CardRasterProps } from "types/InterfaceCard";
import { SectionRomaneiosProps } from "types/InterfaceRomaneio";
import TypeIconCardRomaneios from "utils/TypeIconCardRomaneios";
import {
  UpdateCardRomaneiosField,
  UpdateCardsToOutRomaneio,
  UpdatePositionIdCard,
} from "hooks/useUpdateCard";
import { default as CreateDocument } from "hooks/useCreateDocument";

// styles
import "./index.scss";
import { useUpdateDriversRasterMB as UseUpdateDriversRasterMB } from "hooks/useUpdateDriversRasterMB";
import { getDriverLocation } from "hooks/getDriverLocation";
import secondsToDate from "utils/timestampToDate";
import { usePagination } from "ctx/PaginationContext";
import useGetRomaneios from "hooks/useGetRomaneios";

type RomaneiosProps = {
  romaneio: SectionRomaneiosProps;
  reference: any;
  setParam: (value: number) => void;
};

type Location = {
  latitude: number;
  longitude: number;
  serverTimestamp: any;
}

// controler open or close in popup
export default function Romaneios({ romaneio, reference, setParam }: RomaneiosProps) {
  const alert = useAlert();
  const company = Cookies.get("company");
  const [isExpand, setIsExpand] = useState(false);
  const { refreshPage } = useUpdate();
  const [totalFinishDemands, setTotalFinishDemands] = useState(0);
  const [orderChanged, setOrderChanged] = useState(false);
  const cardsOfRomaneioReferences = romaneio.cardsRomaneios;
  const { pagination, setLastVisibleDoc, unsubscribeGetWaybills } = usePagination()
  const waybills = useGetRomaneios();


  // console.log(romaneio.cardsRomaneios)

  // const [driverLocation, setDriverLocation] = useState<Location>({} as Location); !------ GET LOCATION DISABLED -------!
  // const [driverLastLocation, setDriverLastLocation] = useState(''); !------ GET LOCATION DISABLED -------!

  // controller open or close in popup
  const [open, setOpen] = useState(false);
  const closeModal = useCallback(() => {
    setOpen(false);
  }, [open]);

  const [openRomaneio, setOpenRomaneio] = useState(true);

  const [dataRomaneiosCard, setDataRomaneiosCard] = useState<CardRasterProps[]>(
    []
  );
  const [cardsIds, setCardsIds] = useState<string[]>([]);

  const updateRomaneioToStatusToAWainting = useRef<any>(true);
  const allowFinish = useRef<any>(0);

  const uid = useRef<any>(null);

  const { driversRM } = useApi();
  const { allDocs } = useCard();
  const { update } = useUpdate();

  const drivers = driversRM;

  const hasWarningCard = !!dataRomaneiosCard.find(card => card.status === 3);

  // console.log('Waybill status:', romaneio.status)

  var totalF = 0;

  // !-------------------------- GET LOCATION DISABLED --------------------------------!
  // useEffect(() => {
  //   const unsubscribe = getDriverLocation(romaneio.lastLocation, setDriverLocation);

  //   return () => {
  //     unsubscribe();
  //   }
  // }, [romaneio]);

  // useEffect(() => {
  //   if (Object.keys(driverLocation).length === 0) return;
  //   async function fetchOriginCordinates() {
  //     try {
  //       const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${[driverLocation.latitude, driverLocation.longitude]}&key=AIzaSyDjH61gMyfH74GijZuURFu0Yll4AZSKrx0`)
  //       const data = await response.json();

  //       const adressFormated = data.results[0]?.formatted_address.split(',');
  //       const date = secondsToDate(driverLocation.serverTimestamp.seconds).replace(',', ' ');
  //       adressFormated.splice(adressFormated.length - 2, adressFormated.length)

  //       setDriverLastLocation(`${date} - ${adressFormated}`);
  //     }
  //     catch (err) {
  //       console.log(err);
  //     }
  //   }
  //   fetchOriginCordinates();
  // }, [driverLocation, romaneio]);
  // !-------------------------------------------------------------------------------------!


  // useEffect(() => {
  //   if (romaneio.uid === undefined) return;

  //   for (let i = 0; i < drivers.length; i++) {
  //     if (drivers[i].document === romaneio.document) {
  //       uid.current = drivers[i].uid || "empty";
  //     }
  //   }
  //   let references: any = [];
  //   let docs: any = [];

  //   for (let i = 0; i < reference.length; i++) {
  //     references.push(reference[i]._delegate.path.substr(10));

  //     for (let j = 0; j < allDocs.length; j++) {
  //       if (allDocs[j].uid === references[i]) {
  //         docs.push(allDocs[j]);
  //         break;
  //       }
  //     }
  //   }

  //   if (romaneio.status === 2) {
  //     updateRomaneioToStatusToAWainting.current = true;
  //     for (var i = 0; i < docs.length; i++) {
  //       if (docs[i].status === 3) {
  //         updateRomaneioToStatusToAWainting.current = false;
  //       }
  //     }
  //     if (updateRomaneioToStatusToAWainting.current) {
  //       UpdateStatusRomaneioToWainting(romaneio.uid);
  //       refreshPage();
  //     }
  //   }

  //   if (romaneio.status === 0 && updateRomaneioToStatusToAWainting.current) {
  //     for (var i = 0; i < docs.length; i++) {
  //       if (docs[i].status === 5) {
  //         UpdateStatusRomaneioDelivery(romaneio.uid);
  //         refreshPage();
  //         break;
  //       }
  //     }
  //   }

  //   for (let i = 0; i < docs.length; i++) {
  //     if (docs[i].status === 4) {
  //       totalF = totalF + 1;
  //       allowFinish.current = allowFinish.current + 1;
  //     }
  //   }

  //   // whether finished_demand in romaneio it is outdated, is set new value to finished_demands
  //   if (romaneio.finished_demands < totalF) {
  //     if (romaneio.uid && romaneio.status !== 3) {
  //       UpdateStatusTotalFinishRomaneio(romaneio.uid, totalF);
  //       refreshPage();
  //     }
  //   }

  //   loadData(docs);

  //   if (
  //     allowFinish.current === romaneio.cardsRomaneios.length &&
  //     romaneio.status !== 4 &&
  //     romaneio.status !== 3
  //   ) {
  //     UpdateStatusRomaneioToDemandsDone(romaneio?.uid);
  //   }

  //   return () => {
  //     allowFinish.current = 0;
  //     docs = [];
  //   };
  // }, [reference, update]);

  // function loadData(cards: CardRasterProps[]) {
  //   var allDocumentsIntoRomaneio = cards.sort((a: any, b: any) =>
  //     a.id > b.id ? 1 : -1
  //   );
  //   setDataRomaneiosCard(allDocumentsIntoRomaneio);
  //   setCardsIds(allDocumentsIntoRomaneio);
  //   setTotalFinishDemands(totalF);
  // }

  useEffect(() => {
    if (dataRomaneiosCard.length === 0) return;
    const isAllCardsFinished = dataRomaneiosCard.findIndex((card: any) => card.status !== 4) === -1
    if (isAllCardsFinished && !!romaneio.uid && romaneio.status !== 4) {
      romaneio.uid && UpdateStatusRomaneioToDemandsDone(romaneio.uid);
    }
    if (cardsIds.length > 0) return;
  }, [dataRomaneiosCard])

  useEffect(() => {
    setCardsIds(dataRomaneiosCard.map(card => card.id))
  }, [dataRomaneiosCard.length])


  async function removeDocumentIntoRomaneio(card: CardRasterProps) {
    if (romaneio.uid !== undefined) {
      const db = FirebaseConfig.firestore();

      const CardToBeUpdatedIntoRomaneio = dataRomaneiosCard.filter(cardRomaneio => cardRomaneio.uid !== card.uid);
      const references = CardToBeUpdatedIntoRomaneio.map(card => {
        const format = db.doc("documents/" + card.uid)
        return format
      });
      const number = references.length;
      await UpdateCardsToOutRomaneio(card);
      await UpdateCardRomaneiosField(number, references, romaneio.uid);
      refreshPage();
    }
  }

  function handlePopup() {
    setOpenRomaneio(!openRomaneio);
  }

  async function handlePositionOfCards() {
    if (dataRomaneiosCard === undefined) return;
    if (cardsIds.length === 0) return;

    for (let i = 0; i < cardsIds.length; i++) {
      if (dataRomaneiosCard[i].id !== cardsIds[i]) {
        await UpdatePositionIdCard(
          dataRomaneiosCard[i].uid!,
          cardsIds[i]
        );
        // await UpdatePositionIdCard(
        //   cardsIds[i].uid!,
        //   dataRomaneiosCard[i].id!,
        // );
      }
    }
    // const cardsToUpdate = cardsIds
    //   .filter((card, index) => card.id !== dataRomaneiosCard[index].id)
    //   .map(card => ({
    //     uid: card.uid,
    //     id: card.id,
    //   }))



    // setCardsIds([...dataRomaneiosCard]);

    // console.log(cardsToUpdate)
    setOrderChanged(false);
    refreshPage();
  }

  async function handleFinishRomaneio() {
    for (let i = 0; i < drivers.length; i++) {
      if (drivers[i].document === romaneio.document) {
        uid.current = drivers[i].uid;
        break;
      }
    }

    if (romaneio.uid !== undefined) UpdateStatusRomaneioToDone(romaneio.uid);

    alert.success(romaneio.general_data + " FINALIZADO!");

    unsubscribeGetWaybills.current()
    unsubscribeGetWaybills.current = waybills.getWaybills(pagination, setLastVisibleDoc, 4)

    for (let i = 0; i < dataRomaneiosCard.length; i++) {
      if (dataRomaneiosCard[i].status === 7) {
        // await UpdateCardsToOutRomaneioAndRemoveStatusReturn(dataRomaneiosCard[i]);
        const data = {
          id: allDocs.length + 1,
          cod: dataRomaneiosCard[i].cod,
          nameDocument: dataRomaneiosCard[i].nameDocument,
          client: {
            name: dataRomaneiosCard[i].client.name,
            fone: dataRomaneiosCard[i].client.fone,
            cpf_cnpj: dataRomaneiosCard[i].client.cpf_cnpj,
          },
          origin: {
            address: dataRomaneiosCard[i].origin.address,
            state: dataRomaneiosCard[i].origin.state,
            citie: dataRomaneiosCard[i].origin.citie,
          },
          clientDestiny: dataRomaneiosCard[i].clientDestiny,
          destiny: {
            addressDestiny: dataRomaneiosCard[i].destiny.addressDestiny,
            stateDestiny: dataRomaneiosCard[i].destiny.stateDestiny,
            citieDestiny: dataRomaneiosCard[i].destiny.citieDestiny,
          },
          timeToExitSender: dataRomaneiosCard[i].timeToExitSender,
          timeToArrivalSender: dataRomaneiosCard[i].timeToArrivalSender,
          timeToArrival: dataRomaneiosCard[i].timeToArrival,
          status: 1,
          intoRomaneio: false,
          type: dataRomaneiosCard[i].type,
          currentRomaneio: "",
          company: company,
          history: dataRomaneiosCard[i].history,
          oldDocumentLocation: "Romaneios/" + romaneio.general_data,
        };
        await CreateDocument(data);
      } else {
        await FinishCard(dataRomaneiosCard[i]);
      }
    }

    setParam(4); //Mudando pra visualizar romaneios finalizados

    // try {
    //   UseUpdateDriversRasterMB(uid.current, 0);
    // } catch (e) {
    //   alert.error(romaneio.driver + " ERRO AO LIBERAR MOTORISTA");
    //   console.log(e);
    // }
  }


  return (
    <div>
      <div
        className={
          isExpand
            ? romaneio.status === 2 || hasWarningCard
              ? "main main-focus-warning"
              : "main main-focus"
            : romaneio.status === 2 || hasWarningCard
              ? "main warning"
              : romaneio.status === 3
                ? "main green"
                : "main"
        }
      >
        <section>
          <div className="block-info">
            <img
              src={isExpand ? arrowUp : arrowDown}
              style={{ zIndex: 1 }}
              onClick={() => {
                setIsExpand(!isExpand);
              }}
              alt="close and open card"
            />
            <div className="blockFistData">
              <span>{romaneio.general_data}</span>
              <span>{romaneio.driver}</span>
              {/* <span>{driverLastLocation}</span> */}
            </div>
          </div>
          <div className="block-info">
            <span id="vehicle">{romaneio.vehicle}</span>
          </div>
          <div className="block-info">
            <TypeIconCardRomaneios typeImage={romaneio.status} hasWarningCard={hasWarningCard} />
            {romaneio.status === 3 ? (
              <>
                <button onClick={handleFinishRomaneio}>Finalizar</button>
              </>
            ) : (
              // total of card is more of 0 and have changes in position of cards
              orderChanged && (
                <button onClick={handlePositionOfCards}>Salvar ordem</button>
              )
            )}
          </div>
          <div className="block-info">
            <span>{romaneio.finished_demands}/{romaneio.cardsRomaneios.length}</span>
          </div>
        </section>
        <div className="changeCardBlock tooltipBoundary">
          <Popup
            trigger={
              <div>
                <button
                  type="button"
                  className="button"
                  id="buttonOpenPopup"
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  <BsThreeDotsVertical size={24} />
                </button>
              </div>
            }
            position={["top center", "top right", "top left"]}
            open={open}
            onClose={closeModal}
            closeOnDocumentClick={openRomaneio}
            keepTooltipInside=".tooltipBoundary"
            className="infoUserCpfCnpj"
          >
            <div className="changeCard">
              <AiOutlineCloseCircle
                size={12}
                onClick={closeModal}
                style={{
                  position: "absolute",
                  right: "4px",
                  top: "4px",
                  cursor: "pointer",
                }}
              />

              <CreateRomaneio
                Target={"Alterar"}
                romaneio={romaneio}
                handlePopup={handlePopup}
                title="Atualizar Romaneio"
                update={true}
                totalFinishDemands={romaneio.finished_demands}
                updateRomaneio={refreshPage}
                firstTooltip={closeModal}
                driverUID={uid.current}
                dataRomaneiosCard={dataRomaneiosCard}
              />
            </div>
          </Popup>
        </div>
      </div>
      {isExpand && (
        <>
          <DND
            checkbox={true}
            removeDocumentIntoRomaneio={removeDocumentIntoRomaneio}
            cardsOfRomaneioReferences={cardsOfRomaneioReferences}
            setDataRomaneiosCard={setDataRomaneiosCard}
            romaneio={romaneio}
            setOrderChanged={setOrderChanged}
          />
        </>
      )}
    </div>
  );
}

// export default React.memo(Romaneios, function areEqual(prevProps, nextProps) {
//   if (prevProps.romaneio !== nextProps.romaneio) {
//     return false;
//   }

//   return true;
// });
