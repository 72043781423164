import { useState } from 'react';
import firebase from '../database/FirebaseConfig';

const UseGetTheme = () => {
    const db = firebase.firestore();
    const controller = new AbortController();
    const [loading, setLoading] = useState(false);

    const getTheme = async ({ onComplete, company }: any) => {
        setLoading(true);

        db.collection("colors")
            .where('company', '==', company)
            .get()
            .then((querySnapshot: any) => {
                const colors = querySnapshot.docs.map((doc: any) => {
                    return { ...doc.data(), uid: doc.id }
                });

                onComplete(colors[0]);
                setLoading(false);
            })
            .catch((error: any) => {
                setLoading(false);
                onComplete(undefined);
                console.log("erro: ", error);
            });

        return () => {
            const timeout = setTimeout(() => controller.abort(), 5000);
            clearTimeout(timeout);
        };
    };


    return { loading, getTheme, setLoading };
}

export { UseGetTheme as default };