import { useState } from 'react';
import firebase from '../database/FirebaseConfig';

const UseGetDrivers = () => {
    const db = firebase.firestore();
    const controller = new AbortController();
    const [loadingActivities, setLoginActivities] = useState(false);

    const getDrivers = async ({ onComplete, company }: any) => {
        setLoginActivities(true);
        db.collection("drivers")
            .where('company', '==', company)
            .onSnapshot((querySnapshot: any) => {
                const drivers = querySnapshot.docs.map((doc: any) => {
                    return { ...doc.data(), uid: doc.id };
                });
                onComplete(drivers);
                setLoginActivities(false);
            }, err => {
                setLoginActivities(false);
                console.log('erro: ', err);

            });
        return () => {
            const timeout = setTimeout(() => controller.abort(), 5000);
            clearTimeout(timeout);
        };
    };


    return { loadingActivities, getDrivers, setLoginActivities };
}

const UseGetOneDriver = () => {
    const db = firebase.firestore();
    const controller = new AbortController();
    const [loadingActivities, setLoginActivities] = useState(false);

    const getDriver = async ({ company, cpf, onComplete }: any) => {
        setLoginActivities(true);
        db.collection("drivers")
            .where('company', '==', company)
            .where('document', '==', cpf)
            .get()
            .then((querySnapshot: any) => {
                const documents = querySnapshot.docs.map((doc: any) => {
                    return { ...doc.data(), uid: doc.id }
                });
                onComplete(documents);
                setLoginActivities(false);
            })
            .catch((error: any) => {
                setLoginActivities(false);
                console.log("erro: ", error);
            });
        return () => {
            const timeout = setTimeout(() => controller.abort(), 5000);
            clearTimeout(timeout);
        };
    };


    return { loadingActivities, getDriver, setLoginActivities };
}

export { UseGetOneDriver, UseGetDrivers as default };
