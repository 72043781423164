import { FormsData, useForms } from 'ctx/useForms';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import styles from './styles.module.scss';
import { useForm, SubmitHandler } from "react-hook-form";
import { SelectField } from './SelectField';

interface IncludeFieldFormProps {
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>
}

export default function IncludeFieldForm({ setOpenModal }: IncludeFieldFormProps) {
  const { formsFields, setFormsField } = useForms();
  const { register, handleSubmit } = useForm()

  const onSubmitField: SubmitHandler<FormsData> = async data => {
    setFormsField(data);
    exitModal();
  };

  function exitModal() {
    setOpenModal(false);
  }

  return (
    <div className={styles.popupCreateDocument}>
      <div className={styles.headerDocument}>

        <span>Incluir novo campo de formulário</span>

        <AiOutlineCloseCircle color="white" style={{ cursor: 'pointer' }} onClick={() => {
          setOpenModal(false);
        }} size={22} />

      </div>
      <form key={2} id="myForm2" style={{ overflow: 'scroll', paddingBottom: 20 }}>
        <legend>Informações do campo</legend>
        <div className={styles.infoDocumentos}>

          <div className={styles.blockInfoDocuments}>
            <label>Dependemcia</label>
            <select {...register("dependencie")}>
              <option value="">
                Nenhuma
              </option>
              {formsFields.map((form, index) => (
                <option key={index} value={form.name}>{form.name}</option>
              ))}
            </select>
          </div>

          <div className={styles.blockInfoDocuments}>
            <SelectField label="Opção dependencia" {...register("dependencieOption")} />
          </div>

          <div className={styles.blockInfoDocuments}>
            <label>Nome</label>
            <input {...register("name", {
              pattern: {
                value: /[A-Za-z]{3}/,
                message: 'error message' // JS only: <p>error message</p> TS only support string
              }
            })} />
          </div>

          <div className={styles.blockInfoDocuments}>
            <label>Descrição</label>
            <input {...register("description")} />
          </div>

          <div className={styles.blockInfoDocuments}>
            <label>Tipo</label>
            <select {...register("type")}>
              <option value="">Tipo do campo</option>
              <option value="Texto">Texto</option>
              <option value="Sim/Não">Sim/Não</option>
              <option value="Lista de Opções">Lista de Opções</option>
              <option value="Intervalo">Intervalo</option>
              <option value="Assinatura">Assinatura</option>
              <option value="Inteiro">Inteiro</option>
              <option value="Decimal">Decimal</option>
              <option value="Data">Data</option>
              <option value="Hora">Hora</option>
              <option value="Geolocalização">Geolocalização</option>
            </select>
          </div>

          <div className={styles.blockInfoDocuments}>
            <label>Tamanho</label>
            <input {...register("size")} />
          </div>

          <div className={styles.blockMinMax}>

            <div className={styles.valueMinMax}>
              <label>Valor Mínimo</label>
              <input {...register("min", { required: false })} />
            </div>

            <div className={styles.valueMinMax}>
              <label>Valor Máximo</label>
              <input {...register("max", { required: false })} />
            </div>

          </div>
        </div>

        <div className={styles.submitFormsContainer}>
          <button type="button" onClick={() => exitModal()}
          >Cancelar</button>
          <button type="submit">Salvar</button>
        </div>
      </form>
    </div>
  );
}

