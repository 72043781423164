import { createContext, ReactNode, useContext, useState } from 'react';
import { useCard } from './CardRasterContext';

type UpdateContextProps = {
    tabs: number,
    update: boolean,
    refreshPage: () => void,
    switchTabs: (tab: number) => void,
}

type UpdateProviderProps = {
    children: ReactNode;
}

export const UpdateContext = createContext({} as UpdateContextProps);

export const UpdateProvider = ({ children, }: UpdateProviderProps) => {
    const { clearIds } = useCard();

    const [update, setUpdate] = useState(false);
    const [tabs, setTabs] = useState(1);

    function refreshPage() {
        setUpdate(prevState => !prevState);
    }

    function switchTabs(tab: number) {
        setTabs(tab);
        clearIds();
    }

    return (
        <UpdateContext.Provider
            value={{
                update,
                refreshPage,
                switchTabs,
                tabs,
            }}
        >
            {children}
        </UpdateContext.Provider>
    );
}

export const useUpdate = () => {
    return useContext(UpdateContext);
}