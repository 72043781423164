
const timestampToDate = (date: any, isOnInput?: boolean) => {
  let dateFormated
  if (date?.seconds === undefined) {
    dateFormated = new Date(date * 1000).toLocaleString('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    });
  } else {
    dateFormated = new Date(date.seconds * 1000).toLocaleString('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    });
  }
  if (!isOnInput) {
    return dateFormated;
  } else {

    const newDateString = dateFormated.replace(',', '').split(' ')[0].split('/').reverse();
    const newTimeString = dateFormated.split(' ')[1];
    newDateString[0] = `20${newDateString[0]}`
    const newString = `${newDateString.join('-')}T${newTimeString}`

    return newString;
  }
};

export const secondsToTime = (secs: number) => {
  return new Date(secs * 1000).toLocaleString('pt-BR', {
    hour: '2-digit',
    minute: '2-digit',
  });
}

const secondsToDateToClient = (secs: number) => {
  const day = new Date(secs * 1000).getDay();
  const time = new Date(secs * 1000).toLocaleString();

  var days = [
    "segunda-feira",
    "terça-feira",
    "quarta-feira",
    "quinta-feira",
    "sexta-feira",
    "sábado-feira",
    "domingo-feira",
  ];

  return days[day - 1] + " " + (day + 1) + ", " + time.substr(12);
};

export { secondsToDateToClient, timestampToDate as default };