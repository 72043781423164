export function validateEmails(mails: string) {
  const mailsArray = mails.replaceAll(' ', '').split(',')
  const invalidsEmails = mailsArray.map(mail => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return false;
    }
    return mail;
  }).filter(validEmail => validEmail);

  return invalidsEmails;
}