import Routes from "./routes";

import 'reactjs-popup/dist/index.css';
import './global.scss';

function App() {
  return (
    <div>
        <Routes />
    </div>
  );
}

export default App;
