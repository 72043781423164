import { FormEvent, memo, useContext, useEffect, useState } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { cities } from "estados-cidades";
import { useAlert } from "react-alert";
import { Popup } from "reactjs-popup";
import Styled from "styled-components";

import firebase from "database/FirebaseConfig";

// components
import { default as CreateDocument } from "hooks/useCreateDocument";
import { UpdateAllFields } from "hooks/useUpdateCard";

import MaskCPFCNPJ from "utils/MaskCpfCnpj";
import MaskPhone from "utils/Phone";

// context
import { useUpdate } from "ctx/UpdateContext";
import { AuthContext } from "ctx/AuthContext";
import { useCard } from "ctx/CardRasterContext";

// assets
import checkbox from "assets/icons/checkboxBlue.svg";
import estados from "utils/estados.json";

// interface

// styles
import "./index.scss";
import "reactjs-popup/dist/index.css";
import timestampToDate from "utils/timestampToDate";
import { countries, getCities, getStates } from "hooks/getCountries";

const StyledPopup = Styled(Popup)`  
&-content {
    padding: 0px;
    border: none;
    border-radius: 18px;
}
  
    @media(max-width:520px) {
        &-content  {
            width: 95%;
        }
    }
      
`;

type CreateDocumentsProps = {
  Target: any;
  title: string;
  update?: boolean;
  handleSubmitF?: any;
  cardProps?: any;
  tam?: number;
  xml?: boolean;
  close?: () => void;
  closeModalInfoCard?: () => void;
};

function CreateDocumentsComponent({
  Target,
  title,
  cardProps,
  update,
  tam,
  closeModalInfoCard,
  xml,
  handleSubmitF,
  close,
}: CreateDocumentsProps) {
  const { refreshPage } = useUpdate();

  const { userInfo } = useContext(AuthContext);

  const alert = useAlert();

  const { allDocs } = useCard();

  const collectOrDilivery = cardProps?.type === 1 ? true : false;

  // controller switch-box
  const [isCollect, setIsCollect] = useState(collectOrDilivery);
  const [isDelivery, setIsDelivery] = useState(!collectOrDilivery);

  // fields 'Documento' and 'Código'
  const [nameDocument, setNameDocument] = useState<string>(
    cardProps?.nameDocument || ""
  );
  const [cod, setCod] = useState(cardProps?.cod || "");

  const company = userInfo?.company;

  // Convert number to type datelocal-timme
  let originExit: any;
  let originArrival: any;
  let destinyArrival: any;

  if (cardProps !== undefined && !xml) {
    originExit = timestampToDate(cardProps?.timeToExitSender, true);
    originArrival = timestampToDate(cardProps?.timeToArrivalSender, true);
    destinyArrival = timestampToDate(cardProps?.timeToArrival, true);
  }

  // Origin Fields, set with database data or default values
  const [name, setName] = useState<string>(cardProps?.client?.name);
  const [originCountry, setOriginCountry] = useState<string>("Brasil");
  const [destinyCountry, setDestinyCountry] = useState<string>("Brasil");
  const [state, setState] = useState<any>(cardProps?.origin?.state || "Selecione um estado...");
  const [stateDestiny, setStateDestiny] = useState<string>(
    cardProps?.destiny?.stateDestiny || "Selecione um estado..."
  );

  const [citie, setCitie] = useState<any>([]);
  const [address, setAddress] = useState<string>(
    cardProps?.origin?.address || ""
  );
  const [exit, setExit] = useState<any>(originExit || "");
  const [arrival, setArrival] = useState<any>(originArrival || "");
  const [cpfCnpj, setCpfCnpj] = useState<any>(cardProps?.client?.cpf_cnpj);
  const [phone, setPhone] = useState(cardProps?.client?.fone);
  const [allStatesByCountryDestiny, setAllStatesByCountryDestiny] = useState<any>();
  const [allCitiesByStateDestiny, setAllCitiesByStateDestiny] = useState<any>();
  const [allCitiesByStateOrigin, setAllCitiesByStateOrigin] = useState<any>();
  const [allStatesByCountryOrigin, setAllStatesByCountryOrigin] = useState<any>();

  // Destiny Fields, set with database data or default values
  const [clientDestiny, setClientDestiny] = useState<string>(
    cardProps?.clientDestiny || ""
  );

  const [addressDestiny, setAddressDestiny] = useState<string>(
    cardProps?.destiny?.addressDestiny || ""
  );
  const [arrivalDestiny, setArrivalDestiny] = useState<any>(
    destinyArrival || ""
  );

  // // Value that are convert to formart UF and Citie
  const [originCitie, setOriginCitie] = useState<string>(
    cardProps?.origin?.citie || "Selecione uma cidade..."
  );
  const [destinyCitie, setDestinyCitie] = useState<string>(
    cardProps?.destiny?.citieDestiny || "Selecione uma cidade..."
  );
  const [refetch, setRefetch] = useState(true)

  const [open, setOpen] = useState(false);
  const closeModal = () => setOpen(false);
  const db = firebase.firestore();

  function clearFields() {
    setState('')
    setOriginCitie('')
    setStateDestiny('')
    setDestinyCitie('')
  }

  useEffect(() => {
    async function loadStatesCities() {
      const allStates = await getStates(originCountry)
      const statesSort = allStates.sort((a: any, b: any) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      })
      setAllStatesByCountryOrigin(statesSort)
    }
    loadStatesCities()
  }, [originCountry])

  useEffect(() => {
    async function loadStatesCities() {
      const allStates = await getStates(destinyCountry)
      const statesSort = allStates.sort((a: any, b: any) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      })
      setAllStatesByCountryDestiny(statesSort)
    }
    loadStatesCities()
  }, [destinyCountry])

  useEffect(() => {
    if (!state) return
    async function loadStatesCities() {
      const allCities = await getCities(originCountry, state)
      setAllCitiesByStateOrigin(allCities)
    }
    loadStatesCities()
  }, [state])

  useEffect(() => {
    if (!stateDestiny) return
    async function loadStatesCities() {
      const allCities = await getCities(destinyCountry, stateDestiny)
      setAllCitiesByStateDestiny(allCities)
    }
    loadStatesCities()
  }, [stateDestiny])

  useEffect(() => {
    if (cardProps === undefined) return;
    setName(cardProps?.client?.name);
    setState(cardProps?.origin?.state);
    setCitie([]);
    setAddress(cardProps?.origin?.address);
    setCpfCnpj(cardProps?.client?.cpf_cnpj);
    setPhone(cardProps?.client?.fone);
    setClientDestiny(cardProps?.clientDestiny);
    setStateDestiny(cardProps?.destiny?.stateDestiny);
    setAddressDestiny(cardProps?.destiny?.addressDestiny);
    setOriginCitie(cardProps?.origin?.citie);
    setDestinyCitie(cardProps?.destiny?.citieDestiny);
    setArrivalDestiny(destinyArrival);
    setArrival(originArrival);
    setExit(originExit);
  }, [refetch]);

  // Create or Update Values
  function handleSubmit(event: FormEvent) {
    event.preventDefault();
    if (update) {
      handleUpdateFields();
    } else {
      const type = isDelivery ? 2 : 1;

      var dateExitSender = new Date(exit);
      var dateArrivalSender = new Date(arrival);
      var dateArrival = new Date(arrivalDestiny);

      const tam = allDocs.length;
      const id_card = tam ? tam + 1 : 1;

      const data = {
        id: id_card,
        cod: cod,
        nameDocument,
        client: {
          name,
          fone: phone,
          cpf_cnpj: cpfCnpj,
        },
        origin: {
          address,
          state,
          citie: originCitie,
        },
        clientDestiny,
        destiny: {
          addressDestiny,
          stateDestiny,
          citieDestiny: destinyCitie,
        },
        timeToExitSender: dateExitSender,
        timeToArrivalSender: dateArrivalSender,
        timeToArrival: dateArrival,
        status: 1,
        intoRomaneio: false,
        type,
        currentRomaneio: "",
        company: company,
        history: [],
        companyGroupId: userInfo?.selectedCompanyGroupId
      };
      CreateDocument(data);
      alert.success("Documento salvo");
      ClearFields();
      refreshPage();
      closeModal();
    }
  }

  async function handleUpdateFields() {
    const type = isDelivery ? 2 : 1;

    var dateExitSender = new Date(exit);
    var dateArrivalSender = new Date(arrival);
    var dateArrival = new Date(arrivalDestiny);

    const tam = allDocs.length;
    const id_card = tam ? tam + 1 : 1;

    if (cardProps === undefined) {
      return;
    } else {
      const data = {
        nameDocument,
        client: {
          name,
          fone: phone,
          cpf_cnpj: cpfCnpj,
        },
        origin: {
          address,
          state,
          citie: originCitie,
        },
        clientDestiny,
        destiny: {
          addressDestiny,
          stateDestiny,
          citieDestiny: destinyCitie,
        },
        timeToExitSender: dateExitSender,
        timeToArrivalSender: dateArrivalSender,
        timeToArrival: dateArrival,
        id: !!cardProps?.isTypesense ? id_card : cardProps.id,
        status: cardProps.status,
        intoRomaneio: !!cardProps.intoRomaneio,
        type,
        cod,
        currentRomaneio: cardProps.currentRomaneio,
        history: cardProps.history,
        companyGroupId: userInfo?.selectedCompanyGroupId
      };

      const idOfDocument = cardProps.intoRomaneio ? cardProps.uid : cardProps.id;

      await UpdateAllFields(data, idOfDocument);
      alert.success("Atualizado");
      if (closeModalInfoCard) closeModalInfoCard();
      setOpen(false);
      closeModal();
    }
  }
  // Clear all fields after submit or close popup
  function ClearFields() {
    setNameDocument('');
    setName('');
    setState('');
    setCod('');
    setCitie([]);
    setAddress('');
    setExit('');
    setCpfCnpj('');
    setPhone('')
    setArrival('');
    setClientDestiny('');
    setStateDestiny('');
    setAddressDestiny('');
    setArrivalDestiny('');
  }

  function handleSubmitFile(event: FormEvent) {
    event.preventDefault();

    const type = isDelivery ? 2 : 1;

    var dateExitSender = new Date(exit);
    var dateArrivalSender = new Date(arrival);
    var dateArrival = new Date(arrivalDestiny);
    const tam = allDocs.length;
    const id_card = tam ? tam + 1 : 1;

    const data = {
      id: id_card,
      cod: cod,
      nameDocument,
      client: {
        name,
        fone: phone,
        cpf_cnpj: cpfCnpj,
      },
      origin: {
        address,
        state,
        citie: originCitie,
      },
      clientDestiny,
      destiny: {
        addressDestiny,
        stateDestiny,
        citieDestiny: destinyCitie,
      },
      timeToExitSender: dateExitSender,
      timeToArrivalSender: dateArrivalSender,
      timeToArrival: dateArrival,
      ...(!update) && { status: 1 },
      type,
      intoRomaneio: false,
      currentRomaneio: "",
      company,
      history: [],
      companyGroupId: userInfo?.selectedCompanyGroupId
    };

    db.collection("documents")
      .add(data)
      .then((ref: any) => {
        handleSubmitF(ref.id);
      });
    ClearFields();
    alert.success("Documento importado");
    closeModal();
  }

  return (
    <div>
      {Target === null ? (
        <form
          onSubmit={handleSubmitFile}
          id="myForm"
          style={{ overflow: "scroll", height: 600 }}
        >
          <div className="collectOrDelivery">
            <label className={isCollect ? "label-active" : ""}>
              <input
                type="checkbox"
                onClick={() => {
                  setIsDelivery(false);
                  setIsCollect(true);
                }}
              />
              Coleta
              {isCollect === true && (
                <img
                  src={checkbox}
                  alt="checkbox true"
                  onClick={() => {
                    setIsDelivery(false);
                    setIsCollect(!isCollect);
                  }}
                />
              )}
            </label>
            <label className={isDelivery ? "label-active" : ""}>
              <input
                type="checkbox"
                onClick={() => {
                  setIsDelivery(true);
                  setIsCollect(false);
                }}
              />
              Entrega
              {isDelivery === true && (
                <img
                  src={checkbox}
                  alt="checkbox true"
                  onClick={() => {
                    setIsDelivery(!isDelivery);
                    setIsCollect(false);
                  }}
                />
              )}
            </label>
          </div>
          <legend>Informações do documento</legend>
          <div
            className="infoDocumentos"
            style={{ gridTemplateColumns: "70% 30%" }}
          >
            <div className="blockInfoDocuments">
              <label>Documento</label>
              <input
                value={nameDocument}
                maxLength={12}
                type="text"
                placeholder="Informe o nome do documento max. 12 caracteres"
                onChange={(event) => setNameDocument(event.target.value)}
              />
            </div>
            <div className="blockInfoDocuments">
              <label>Código</label>
              <input
                value={cod.substr(0, 24)}
                maxLength={24}
                required
                type="text"
                placeholder="Ex. 501"
                onChange={(event) => setCod(event.target.value)}
              />
            </div>
          </div>
          <legend>Informação de origem</legend>
          <div className="input-form-createDocuments">
            <div className="blockInput-createDocuments">
              <label>Cliente</label>
              <input
                value={name}
                required
                type="text"
                placeholder="Informe o nome do cliente"
                onChange={(event) => setName(event.target.value)}
              />
            </div>
            <div className="blockInput-createDocuments-secondary">
              <div
                className="blockInput-createDocuments"
                style={{ marginLeft: 0 }}
              >
                <label>Telefone</label>
                <input
                  value={phone}
                  required
                  type="text"
                  placeholder="(XX) X XXXX-XXXX"
                  maxLength={14}
                  onInput={(e: any) => {
                    let doc = MaskPhone(e.target.value, e.target);
                    e.target.value = doc;
                    setPhone(doc);
                  }}
                />
              </div>
              <div className="blockInput-createDocuments">
                <label>CPF/CNPJ</label>
                <input
                  value={cpfCnpj}
                  type="text"
                  placeholder="Informe o CPF ou CNPJ"
                  onInput={(e: any) => {
                    let doc = MaskCPFCNPJ(e.target.value, e.target);
                    e.target.value = doc;
                    setCpfCnpj(doc);
                  }}
                />
              </div>
            </div>
            <div className="blockInput-createDocuments" style={{ maxWidth: '50%' }}>
              <label>Pais</label>
              <select
                id="originCountry"
                defaultValue={"DEFAULT"}
                value={originCountry}
                required
                onChange={(event) => {
                  setOriginCountry(event.target.value)
                  setOriginCitie('Selecione uma cidade...')
                  setState('Selecione um estado...')
                }}
              >
                {Object.keys(countries).map((item) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
              </select>
            </div>
            <div className="blockInput-createDocuments-secondary">
              <div className="blockInput-createDocuments">
                <label>Estado</label>
                <select
                  id="states"
                  defaultValue={"DEFAULT"}
                  required
                  value={state}
                  onChange={(event) => {
                    setState(event.target.value)
                    setOriginCitie('Selecione uma cidade...')
                  }}
                >
                  <option value="DEFAULT">
                    {state}
                  </option>
                  {!!allStatesByCountryOrigin && allStatesByCountryOrigin.map((item: any) => (
                    <option key={item.id} value={item.iso2}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="blockInput-createDocuments">
                <label>Cidade</label>
                <select
                  id="citiesBR"
                  placeholder="Selecione uma cidade"
                  defaultValue={"DEFAULT"}
                  required
                  value={originCitie}
                  onChange={(event) => setOriginCitie(event.target.value)}
                >
                  <option value="DEFAULT">
                    {originCitie}
                  </option>
                  {allCitiesByStateOrigin !== undefined &&
                    allCitiesByStateOrigin.map((item: string) => (
                      <option key={item} value={item}>
                        {item}
                      </option>
                    ))}
                </select>
              </div>
              <div
                className="blockInput-createDocuments"
                style={{ marginLeft: 0 }}
              >
                <label>Telefone</label>
                <input
                  value={phone}
                  required
                  type="text"
                  placeholder="(XX) X XXXX-XXXX"
                  maxLength={14}
                  onInput={(e: any) => {
                    let doc = MaskPhone(e.target.value, e.target);
                    e.target.value = doc;
                    setPhone(doc);
                  }}
                />
              </div>
              <div className="blockInput-createDocuments">
                <label>CPF/CNPJ</label>
                <input
                  value={cpfCnpj}
                  type="text"
                  placeholder="Informe o CPF ou CNPJ"
                  onInput={(e: any) => {
                    let doc = MaskCPFCNPJ(e.target.value, e.target);
                    e.target.value = doc;
                    setCpfCnpj(doc);
                  }}
                />
              </div>
            </div>
            <div className="blockInput-createDocuments">
              <label>Endereço</label>
              <input
                value={address}
                required
                type="text"
                placeholder="Rua/Avenida"
                onChange={(event) => setAddress(event.target.value)}
              />
            </div>
            <div className="blockInput-createDocuments-secondary">
              <div className="blockInput-createDocuments">
                <label>Data e hora de chegada</label>
                <div className="inputTime" style={{ display: "flex" }}>
                  <input
                    value={arrival}
                    required
                    type="datetime-local"
                    onChange={(event) => setArrival(event.target.value)}
                  />
                </div>
              </div>
              <div className="blockInput-createDocuments">
                <label>Data e hora de saída</label>
                <div className="inputTime" style={{ display: "flex" }}>
                  <input
                    value={exit}
                    required
                    type="datetime-local"
                    onChange={(event) => setExit(event.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
          <legend id="infoDestiny">Informação de destino</legend>
          <div className="input-form-createDocuments">
            <div className="blockInput-createDocuments">
              <label>Cliente</label>
              <input
                value={clientDestiny}
                required
                type="text"
                placeholder="Informe o nome do cliente destinátario"
                onChange={(event) => setClientDestiny(event.target.value)}
              />
            </div>
            <div className="blockInput-createDocuments" style={{ maxWidth: '50%' }}>
              <label>Pais</label>
              <select
                id="destinyCountry"
                required
                defaultValue={"DEFAULT"}
                value={destinyCountry}
                onChange={(event) => {
                  setDestinyCountry(event.target.value)
                  setStateDestiny('Selecione um estado...')
                  setDestinyCitie('Selecione uma cidade')
                }}
              >
                {Object.keys(countries).map((item) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
              </select>
            </div>
            <div className="blockInput-createDocuments-secondary">
              <div className="blockInput-createDocuments">
                <label>Estado</label>
                <select
                  id="states"
                  defaultValue={"DEFAULT"}
                  required
                  value={stateDestiny}
                  onChange={(event) => {
                    setStateDestiny(event.target.value)
                    setDestinyCitie('Selecione uma cidade...')
                  }}
                >11111
                  <option value="DEFAULT">
                    {stateDestiny}
                  </option>
                  {!!allStatesByCountryDestiny && allStatesByCountryDestiny.map((item: any) => (
                    <option key={item.id} value={item.iso2}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="blockInput-createDocuments">
                <label>Cidade</label>
                <select
                  id="citiesBR"
                  placeholder="Selecione uma cidade"
                  defaultValue={"DEFAULT"}
                  required
                  value={destinyCitie}
                  onChange={(event) => setDestinyCitie(event.target.value)}
                >
                  <option value="DEFAULT">
                    {destinyCitie}
                  </option>
                  {allCitiesByStateDestiny !== undefined &&
                    allCitiesByStateDestiny.map((item: string) => (
                      <option key={item} value={item}>
                        {item}
                      </option>
                    ))}
                </select>
              </div>
            </div>
            <div className="blockInput-createDocuments">
              <label>Endereço</label>
              <input
                value={addressDestiny}
                required
                type="text"
                placeholder="Rua/Avenida, bairro, nº, cep"
                onChange={(event) => setAddressDestiny(event.target.value)}
              />
            </div>
            <div className="blockInput-createDocuments-secondary">
              <div className="blockInput-createDocuments">
                <label>Data e hora de chegada</label>
                <div className="inputTime" style={{ display: "flex" }}>
                  <input
                    required
                    value={arrivalDestiny}
                    type="datetime-local"
                    placeholder="formulário preenchido"
                    onChange={(event) => setArrivalDestiny(event.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="button-form">
            <button
              type="button"
              onClick={() => {
                setRefetch(prevState => !prevState);
                if (close) close();
              }}
            >
              Cancelar
            </button>
            <button type="submit">Importar</button>
          </div>
        </form>
      ) : (
        <>
          <StyledPopup
            trigger={
              <div>
                <button onClick={() => setOpen((o) => !o)} id="buttonOpenPopup">
                  {Target}
                </button>
              </div>
            }
            open={open}
            closeOnDocumentClick={false}
            onClose={closeModal}
            modal
            position="top center"
            lockScroll
          >
            <div className="popupCreateDocument">
              <div className="headerDocument">
                <span>{title}</span>
                <AiOutlineCloseCircle
                  color="white"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    closeModal();
                    // ClearFields();
                  }}
                  size={22}
                />
              </div>
              <form
                onSubmit={handleSubmit}
                id="myForm"
                style={{ overflow: "scroll", height: 600 }}
              >
                <div className="collectOrDelivery">
                  <label className={isCollect ? "label-active" : ""}>
                    <input
                      type="checkbox"
                      onClick={() => {
                        setIsDelivery(false);
                        setIsCollect(true);
                      }}
                    />
                    Coleta
                    {isCollect === true && (
                      <img
                        src={checkbox}
                        alt="checkbox true"
                        onClick={() => {
                          setIsDelivery(false);
                          setIsCollect(!isCollect);
                        }}
                      />
                    )}
                  </label>
                  <label className={isDelivery ? "label-active" : ""}>
                    <input
                      type="checkbox"
                      onClick={() => {
                        setIsDelivery(true);
                        setIsCollect(false);
                      }}
                    />
                    Entrega
                    {isDelivery === true && (
                      <img
                        src={checkbox}
                        alt="checkbox true"
                        onClick={() => {
                          setIsDelivery(!isDelivery);
                          setIsCollect(false);
                        }}
                      />
                    )}
                  </label>
                </div>
                <legend>Informações do documento</legend>
                <div className="infoDocumentos">
                  <div className="blockInfoDocuments">
                    <label>Documento</label>
                    <input
                      value={nameDocument}
                      maxLength={12}
                      type="text"
                      placeholder="Informe o nome do documento max. 12 caracteres"
                      onChange={(event) => setNameDocument(event.target.value)}
                    />
                  </div>
                  <div className="blockInfoDocuments">
                    <label>Código</label>
                    <input
                      value={cod.substr(0, 24)}
                      maxLength={24}
                      required
                      type="text"
                      placeholder="Ex. 501"
                      onChange={(event) => setCod(event.target.value)}
                    />
                  </div>
                </div>
                <legend>Informação de origem</legend>
                <div className="input-form-createDocuments">
                  <div className="blockInput-createDocuments">
                    <label>Cliente</label>
                    <input
                      value={name}
                      required
                      type="text"
                      placeholder="Informe o nome do cliente"
                      onChange={(event) => setName(event.target.value)}
                    />
                  </div>
                  <div className="blockInput-createDocuments" style={{ maxWidth: '50%' }}>
                    <label>Pais</label>
                    <select
                      id="originCountry"
                      defaultValue={"DEFAULT"}
                      value={originCountry}
                      required
                      onChange={(event) => {
                        setOriginCountry(event.target.value);
                        setOriginCitie('Selecione uma cidade')
                        setState('Selecione um estado')
                      }}
                    >
                      {Object.keys(countries).map((item) => (
                        <option key={item} value={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="blockInput-createDocuments-secondary">
                    <div className="blockInput-createDocuments">
                      <label>Estado</label>
                      <select
                        id="states"
                        defaultValue={"DEFAULT"}
                        required
                        value={state}
                        onChange={(event) => {
                          setState(event.target.value)
                          setOriginCitie('Selecione uma cidade...')
                        }}
                      >
                        <option value="DEFAULT">
                          {state}
                        </option>
                        {!!allStatesByCountryOrigin && allStatesByCountryOrigin.map((item: any) => (
                          <option key={item.id} value={item.iso2}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="blockInput-createDocuments">
                      <label>Cidade</label>
                      <select
                        id="citiesBR"
                        placeholder="Selecione uma cidade"
                        defaultValue={"DEFAULT"}
                        required
                        value={originCitie}
                        onChange={(event) => setOriginCitie(event.target.value)}
                      >
                        <option value="DEFAULT">
                          {originCitie}
                        </option>
                        {allCitiesByStateOrigin !== undefined &&
                          allCitiesByStateOrigin.map((item: string) => (
                            <option key={item} value={item}>
                              {item}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div
                      className="blockInput-createDocuments"
                      style={{ marginLeft: 0 }}
                    >
                      <label>Telefone</label>
                      <input
                        value={phone}
                        required
                        type="text"
                        placeholder="(XX) X XXXX-XXXX"
                        maxLength={14}
                        onInput={(e: any) => {
                          let doc = MaskPhone(e.target.value, e.target);
                          e.target.value = doc;
                          setPhone(doc);
                        }}
                      />
                    </div>
                    <div className="blockInput-createDocuments">
                      <label>CPF/CNPJ</label>
                      <input
                        value={cpfCnpj}
                        type="text"
                        placeholder="Informe o CPF ou CNPJ"
                        onInput={(e: any) => {
                          let doc = MaskCPFCNPJ(e.target.value, e.target);
                          e.target.value = doc;
                          setCpfCnpj(doc);
                        }}
                      />
                    </div>
                  </div>
                  <div className="blockInput-createDocuments">
                    <label>Endereço</label>
                    <input
                      value={address}
                      required
                      type="text"
                      placeholder="Rua/Avenida"
                      onChange={(event) => setAddress(event.target.value)}
                    />
                  </div>
                  <div className="blockInput-createDocuments-secondary">
                    <div className="blockInput-createDocuments">
                      <label>Data e hora de chegada</label>
                      <div className="inputTime" style={{ display: "flex" }}>
                        <input
                          value={arrival}
                          required
                          type="datetime-local"
                          onChange={(event) => setArrival(event.target.value)}
                        />
                      </div>
                    </div>
                    <div className="blockInput-createDocuments">
                      <label>Data e hora de saída</label>
                      <div className="inputTime" style={{ display: "flex" }}>
                        <input
                          value={exit}
                          required
                          type="datetime-local"
                          onChange={(event) => setExit(event.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <legend id="infoDestiny">Informação de destino</legend>
                <div className="input-form-createDocuments">
                  <div className="blockInput-createDocuments">
                    <label>Cliente</label>
                    <input
                      value={clientDestiny}
                      required
                      type="text"
                      placeholder="Informe o nome do cliente destinátario"
                      onChange={(event) => setClientDestiny(event.target.value)}
                    />
                  </div>
                  <div className="blockInput-createDocuments" style={{ maxWidth: '50%' }}>
                    <label>Pais</label>
                    <select
                      id="destinyCountry"
                      defaultValue={"DEFAULT"}
                      value={destinyCountry}
                      required
                      onChange={(event) => {
                        setDestinyCountry(event.target.value)
                        setStateDestiny('Selecione um estado')
                        setDestinyCitie('Selecione uma cidade')
                      }}
                    >
                      {Object.keys(countries).map((item) => (
                        <option key={item} value={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="blockInput-createDocuments-secondary">
                    <div className="blockInput-createDocuments">
                      <label>Estado</label>
                      <select
                        id="states"
                        defaultValue={"DEFAULT"}
                        required
                        value={stateDestiny}
                        onChange={(event) => {
                          setStateDestiny(event.target.value)
                          setDestinyCitie('Selecione uma cidade...')
                        }}
                      >
                        <option value="DEFAULT">
                          {stateDestiny}
                        </option>
                        {!!allStatesByCountryDestiny && allStatesByCountryDestiny.map((item: any) => (
                          <option key={item.id} value={item.iso2}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="blockInput-createDocuments">
                      <label>Cidade</label>
                      <select
                        id="citiesBR"
                        placeholder="Selecione uma cidade"
                        defaultValue={"DEFAULT"}
                        required
                        value={destinyCitie}
                        onChange={(event) => setDestinyCitie(event.target.value)}
                      >
                        <option value="DEFAULT">
                          {destinyCitie}
                        </option>
                        {allCitiesByStateDestiny !== undefined &&
                          allCitiesByStateDestiny.map((item: string) => (
                            <option key={item} value={item}>
                              {item}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                  <div className="blockInput-createDocuments">
                    <label>Endereço</label>
                    <input
                      value={addressDestiny}
                      required
                      type="text"
                      placeholder="Rua/Avenida, bairro, nº, cep"
                      onChange={(event) =>
                        setAddressDestiny(event.target.value)
                      }
                    />
                  </div>
                  <div className="blockInput-createDocuments-secondary">
                    <div className="blockInput-createDocuments">
                      <label>Data e hora de chegada</label>
                      <div className="inputTime" style={{ display: "flex" }}>
                        <input
                          required
                          value={arrivalDestiny}
                          type="datetime-local"
                          placeholder="formulário preenchido"
                          onChange={(event) =>
                            setArrivalDestiny(event.target.value)
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="button-form">
                  <button
                    type="button"
                    onClick={() => {
                      setOpen(!open);
                      setRefetch(prevState => !prevState)
                      if (!update) ClearFields();
                    }}
                  >
                    Cancelar
                  </button>
                  <button type="submit">
                    {update ? "Atualizar" : "Salvar"}
                  </button>
                </div>
              </form>
            </div>
          </StyledPopup>
        </>
      )}
    </div>
  );
}

export const CreateDocuments = memo(CreateDocumentsComponent, (prevProps, nextProps) => {
  const cardPropsPrev = JSON.stringify(prevProps.cardProps)
  const cardPropsNext = JSON.stringify(nextProps.cardProps)
  return cardPropsPrev === cardPropsNext;
});