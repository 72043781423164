const cpf = (input: string) => {
  return input
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1')
}

const phone = (input: string) => {
  return input
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '($1) $2')
    .replace(/(\d{4})(\d)/, '$1 $2')
    .replace(/(\d{4}) (\d)(\d{4})/, '$1$2 $3')
    .replace(/( \d{4})\d+?$/, '$1')
}

const removeMask = (input: string) => {
  return input.replace(/\D/g, '')
}

export { cpf, phone, removeMask }
