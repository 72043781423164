import { Images } from 'utils/template';
import './index.scss';

export function HeaderClient() {
    return (
        <div className="header">
            <div className="logo">
                <img src={Images.logo} alt='Logo da marca' />
            </div>
            <div className="infos">
                <span>sobre</span>
                <span>soluções</span>
                <span>blog</span>
                <span>parceiros</span>
                <span>contato</span>
                <span>trabalhe conosco</span>
            </div>
            <div className="zoneClient">
                <label>
                    <span>Área do Cliente</span>
                </label>
            </div>
        </div>
    )
}