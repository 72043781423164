import { useState } from 'react';
import firebase from '../database/FirebaseConfig';

const db = firebase.firestore();

export function createFinalization(data: any, companyId: string) {

  try {
    db
      .collection('companies')
      .doc(companyId)
      .collection('finalizations')
      .add(data)
  } catch (error) {
    console.log(error)
  }
}

export function updateFinalization(data: any, uid: string, companyId: string) {
  try {
    db
      .collection('companies')
      .doc(companyId)
      .collection('finalizations')
      .doc(uid)
      .update(data)
    console.log('Finalization Successfully Edited!');
  } catch (error) {
    console.log(error)
  }
}
export function deleteFinalization(uid: string, companyId: string) {
  try {
    db
      .collection('companies')
      .doc(companyId)
      .collection('finalizations')
      .doc(uid)
      .delete()
    console.log('Finalization Successfully Deleted!');
  } catch (error) {
    console.log(error)
  }
}

const useGetFinalizations = () => {
  const db = firebase.firestore();
  const controller = new AbortController();
  const [loading, setLoading] = useState(false);

  const getFinalizations = async ({ onComplete, company }: any) => {
    setLoading(true);
    db.collection("companies")
      .doc(company)
      .collection('finalizations')
      .onSnapshot((querySnapshot: any) => {
        const finalizations = querySnapshot.docs.map((doc: any) => {
          return { ...doc.data(), uid: doc.id };
        });
        onComplete(finalizations);
        setLoading(false);
      }, err => {
        setLoading(false);
        console.log('erro: ', err);
      });

    return () => {
      const timeout = setTimeout(() => controller.abort(), 5000);
      clearTimeout(timeout);
    };
  };


  return { loading, getFinalizations, setLoading };
}

export { useGetFinalizations }