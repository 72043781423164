import { useContext, useEffect, useRef, useState } from 'react';
import { BsPencilSquare, BsFillTrashFill } from 'react-icons/bs';
import { AiOutlineSend } from 'react-icons/ai';
import { useAlert } from 'react-alert';

// components
import checkbox from 'assets/icons/checkboxBlue.svg';

import { useUpdate } from 'ctx/UpdateContext';
import { useApi } from 'ctx/ApiContext';

import { removeRowOccurence, } from 'hooks/useDeleteOcurrence';

// styles
import './index.scss';
import { confirmAlert } from 'react-confirm-alert';
import { validateEmails } from 'utils/validateEmails';
import { deleteFinalization, updateFinalization } from 'hooks/createFinalization';
import { AuthContext } from 'ctx/AuthContext';

export type FinalizationProps = {
    uid: string;
    name: string;
    description: string;
}

type OccurrencesProps = {
    closeModal: () => void;
}

export function Finalizations({ closeModal }: OccurrencesProps) {
    const { finalizations } = useApi();


    return (
        <div className="allFinalizations">
            <div className="main-allFinalizations">
                <legend style={{ marginRight: 12 }}>Lista de finalizações</legend>
                <div className="titlesOccurrence">
                    {finalizations?.length === 0 && (
                        <>
                            <p>Finalização</p>
                            <p >Descrição </p>
                        </>
                    )}
                </div>
                {finalizations !== undefined && finalizations.map((item: FinalizationProps, index: number) => (
                    <Finalization item={item} index={index} key={index} closeModal={closeModal} />
                ))}
            </div>
        </div>
    )
}

type FinalizationItemProps = {
    item: FinalizationProps;
    index: number;
    closeModal: () => void;
}

export const Finalization = ({ item, index, closeModal }: FinalizationItemProps) => {
    const alert = useAlert();

    const [name, setName] = useState(item.name);
    const [description, setDescription] = useState(item.description);
    const [changeItem, setChangeItem] = useState(false);
    const fetchedName = useRef<string>()
    const fetchedDescription = useRef<string>()
    const { userInfo } = useContext(AuthContext);

    useEffect(() => {
        setName(item.name);
        setDescription(item.description);
        fetchedName.current = item.name
        fetchedDescription.current = item.description
    }, [item])


    const { refreshPage } = useUpdate();

    function handleUpdate(uid: string) {
        if (!name || name === '') {
            alert.error('Insira o nome da finalização!');
            setName(fetchedName.current!)
            return;
        }
        const data = {
            name,
            description
        }
        updateFinalization(data, uid, userInfo!.company);
        setChangeItem(false);
        refreshPage();
        alert.success('Finalização atualizada!');
    }

    async function handleDeleteFinalization(finalization: any) {
        deleteFinalization(finalization.uid, userInfo!.company)
    }

    function handleCancel() {
        setChangeItem(!changeItem)
        setName(fetchedName.current!)
        setDescription(fetchedDescription.current!)
    }

    return (
        <div className="blockAllFinalizations" key={index}>
            <div className="changeCard">
                {index === 0 && (
                    <legend>Nome</legend>
                )}
                <input
                    value={name}
                    hidden={!changeItem}
                    onChange={event => setName(event.target.value)}
                    type="text" />
                <span hidden={changeItem}>{name}</span>
            </div>

            <div className="changeCard">
                {index === 0 && (
                    <legend >Descrição</legend>
                )}

                {changeItem ? (
                    <input
                        value={description}
                        onChange={event => setDescription(event.target.value)}
                    />
                ) : (
                    <div style={description === '' ? { marginBottom: '36px' } : {}}>{description}</div>
                )}
            </div>
            <div  // New Buttons
                className="blockIconButtonsFinalizations"
                style={index === 0 ? { paddingTop: '1.3rem' } : { paddingTop: '0' }}
            >
                {!changeItem ?
                    <>
                        <BsPencilSquare
                            style={{ cursor: 'pointer', marginRight: '0.5rem', color: 'var(--secondary)' }}
                            onClick={() => setChangeItem(!changeItem)}
                        />
                        <BsFillTrashFill
                            style={{ cursor: 'pointer', color: 'var(--secondary)' }}
                            onClick={() => {
                                confirmAlert({
                                    message: 'Deseja remover esta finalização?',
                                    buttons: [
                                        {
                                            label: 'Cancelar',
                                            onClick: () => ('Cancelar')
                                        },
                                        {
                                            label: 'Sim',
                                            onClick: () => {
                                                handleDeleteFinalization(item);
                                            }
                                        }
                                    ],
                                    overlayClassName: 'confirmAlert'
                                });
                            }}
                        />
                    </>
                    :
                    <div>
                        <div className="sendButtonOccurences" onClick={() => handleUpdate(item.uid)}>
                            Salvar
                        </div>
                        <div className="cancelButtonOcurrences" onClick={handleCancel}>
                            Cancelar
                        </div>
                    </div>
                }
            </div>
        </div >
    )
}
