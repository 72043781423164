import Cookies from 'js-cookie';
import { useState } from 'react';
import { db } from '../database/FirebaseConfig';

const useGetDriversRM = () => {
    const [loading, setLoading] = useState(false);
    const controller = new AbortController();
    const company = Cookies.get("company");

    const getDrivers = ({ onComplete }: any) => {
        setLoading(true);

        db.collection('users').where('company', '==', company).where('role', '==', 'driver')
            .onSnapshot((querySnapshot: any) => {
                const data = querySnapshot.docs.map((item: any) => {
                    return { ...item.data(), uid: item.id };
                });
                onComplete(data);
                setLoading(false);
            }, err => {
                setLoading(false);
                console.log('error', err);
            });

        return () => {
            const timeout = setTimeout(() => controller.abort(), 5000);
            clearTimeout(timeout);
        }
    }

    return { getDrivers, setLoading, loading };
}

export default useGetDriversRM;