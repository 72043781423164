import { useState } from 'react';
import './index.scss';

type CheckboxProps = {
    item: number;
    uid: string;
    labels: any;
    allowOrBlockAccess?: (uid: string, value: number) => void;
    setUpAccount?: (type: string) => void;
}

const Checkbox = ({ item, uid, labels, allowOrBlockAccess, setUpAccount }: CheckboxProps) => {

    let value = item === 1 ? true : false;


    const [marker, setMaker] = useState(value);
    const [auxBtn, setAuxBtn] = useState(value);

    return (
        <div className="checkbox-switch">
            <span className={`${auxBtn ? "title-active" : "title-desactive"}`}>{auxBtn ? labels[0] : labels[1]}</span>
            <label className="switch">
                <input
                    className={marker ? 'active' : 'off'}
                    type="checkbox"
                    onClick={() => {
                        setAuxBtn(!auxBtn);
                        if (auxBtn) {
                            if (allowOrBlockAccess) {
                                allowOrBlockAccess(uid, 0);
                            }
                            if (setUpAccount) {
                                setUpAccount(labels[1]);
                            }
                        } else {
                            if (allowOrBlockAccess) {
                                allowOrBlockAccess(uid, 1);
                            }
                            if (setUpAccount) {
                                setUpAccount(labels[0]);
                            }
                        }
                    }}
                />
                <span className={value ? "slider-active round" : "slider-off round"}></span>
            </label>
        </div>
    )
}

export {
    Checkbox
}