import firebase from '../database/FirebaseConfig';

type useCreateRomaneiosProps = {
    vehicle: String;
    document: string;
    note: String;
    driver: String;
}

function useCreateRomaneios(data: useCreateRomaneiosProps) {
    const db = firebase.firestore();

    db
        .collection('waybills')
        .add(data)
}

function hiddenDocumentoIntoRomaneios(uids: any, nameRoamenio: string) {

    for (let i = 0; i < uids.length; i++) {
        uids[i].update({
            'currentRomaneio': nameRoamenio,
            'intoRomaneio': true
        }).then(() => {
            console.log('Profile Successfully Edited!');
        }).catch((error: any) => {
            console.log('Error updating the document:', error);
        })
    }
}

export { hiddenDocumentoIntoRomaneios, useCreateRomaneios as default };