import { useState } from 'react';
import { Helmet } from 'react-helmet'
import { useHistory } from 'react-router-dom';

// components
import { FooterClient } from 'components/FooterClient';
import { HeaderClient } from 'components/HeaderClient';
import MaskCPFCNPJ from 'utils/MaskCpfCnpj';

// styles
import './index.scss';

export default function Client() {
    const [cpfCnpj, setCpfCnpj] = useState<number>();

    const history = useHistory();

    function navigateToTracking() {
        history.push({
            pathname: 'tracking'
        })
    }

    return (
        <div className="client">
            <Helmet>
                <title>Área do Cliente</title>
            </Helmet>
            <header>
                <HeaderClient />
            </header>
            <main>
                <h1>Rastreie a sua coletas/entrega</h1>
                <div className="wrapper">
                    <input
                        value={cpfCnpj}
                        type="text"
                        placeholder="CPF ou CPNJ"
                        onInput={(e: any) => {
                            let doc = MaskCPFCNPJ(e.target.value, e.target)
                            e.target.value = doc
                            setCpfCnpj(doc);
                        }}
                    />
                    <input type="text" placeholder="Código de rastreio" />
                    <button onClick={navigateToTracking} type="button"> &gt;&gt;Acessar</button>
                </div>
            </main>
            <footer>
                <FooterClient />
            </footer>
        </div>
    );
}