import { Helmet } from 'react-helmet';
import { useEffect, useState } from 'react';

// components
import { FooterClient } from 'components/FooterClient';
import { HeaderClient } from 'components/HeaderClient';
import { Timeline } from 'components/Timeline';
import secondsToDate from 'utils/timestampToDate';
import { GetDocumentTotracking } from 'hooks/useGetDocuments';

// assets
import arrow from 'assets/icons/arrowTracking.svg';

// styles
import './index.scss';
import { Labels, Links } from 'utils/template';

export default function Tracking() {
    const [history, setHistory] = useState<any>([]);
    const [docs, setDocs] = useState<any>([]);

    const tracking = GetDocumentTotracking();

    const id = "99.654.965/0001-69";
    // for (let i = 0; i < allDocs.length; i++) {
    //     if (allDocs[i].client.cpf_cnpj === id) {
    //         setDocTracking(allDocs[i]);
    //         break;
    //     }
    // }
    // console.log(doc)

    useEffect(() => {
        tracking.getDocuments({
            id,
            onComplete: ((doc: any) => {
                let aux: any = [];
                setDocs(doc[0]);
                if (doc[0].history.length > 0) {
                    for (let i = doc[0].history.length - 1; i >= 0; i--) {
                        aux.push(doc[0].history[i]);
                    }
                }
                setHistory(aux)
            }),
        })
    }, []);

    if (docs.length === 0) {
        return <span>"carregando...";</span>
    }

    return (
        <div className="container-tracking">
            <Helmet>
                <title>Rastreio</title>
            </Helmet>
            <header>
                <HeaderClient />
            </header>
            <main>
                <div className="wrapperTimeline">
                    <h1>Rastreie sua entrega/coleta</h1>
                    <div className="block">
                        <div className="info-tracking">
                            <div className="block-info-tracking">
                                <legend>{docs.client.name}</legend>
                                <span>{docs.origin.address}</span>
                                <span>{docs.origin.citie}/{docs.origin.state}</span>
                            </div>
                            <div className="block-info-tracking">
                                <legend>Destino:</legend>
                                <span>{docs.destiny.addressDestiny}</span>
                                <span>{docs.destiny.citieDestiny}/{docs.destiny.stateDestiny}</span>
                            </div>
                            <div className="block-info-tracking">
                                <legend>Estimativa de chegada:</legend>
                                <label>
                                    <img src={arrow} alt="" />
                                    <span>{secondsToDate(docs.timeToArrival.seconds)}</span>
                                </label>
                            </div>
                            <div className="block-info-tracking">
                                <legend>Entregue por: </legend>
                                <span>{Labels.nameUppercase} GERENCIAMENTO DE RISCO</span>
                                <span>FONE {Labels.fone}</span>
                                <span>{Links.website}</span>
                            </div>
                        </div>
                        <div className="timeline">
                            <Timeline history={history} />
                        </div>
                    </div>
                </div>
            </main>
            <FooterClient />
        </div>
    );
}