import { useEffect, useState } from "react";
import firebase from "../database/FirebaseConfig";

const db = firebase.firestore();

const GetCompanie = async (uid: string, setCompanie: any) => {
  await db
    .collection("companies")
    .doc(uid)
    .get()
    .then((querySnapshot: any) => {
      const data = { ...querySnapshot.data(), uid: querySnapshot.id };
      setCompanie(data);
    })
    .catch((error: any) => {
      console.log("Error updating the document:", error);
    });
};

const useCompanie = () => {
  const [company, setCompanie] = useState<any>({ loading: true });

  useEffect(() => {
    const unsubscribe = db.collection("companies").onSnapshot(
      (querySnapshot: any) => {
        const company = querySnapshot.docs.map((doc: any) => {
          return { ...doc.data(), uid: doc.id };
        });
        setCompanie(company);
      },
      (err) => {
        console.log("erro: ", err);
      }
    );
    return unsubscribe;
  }, []);

  return { company };
};

export interface ICompanies {
  id: string;
  name: string;
}

export async function getCompanies() {
  const companieRef = await db.collection("companies");
  const snapshot = await companieRef.get();

  const companies = snapshot.docs.map((doc) => {
    return {
      id: doc.id,
      name: doc.data().name,
    };
  });
  return companies;
}

export { useCompanie, GetCompanie as default };
