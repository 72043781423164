import { FormEvent, ReactNode, useEffect, useState } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { Redirect, useHistory, Link } from "react-router-dom";
import MoonLoader from "react-spinners/MoonLoader";
import { css } from "@emotion/react";
import { Table, Switch } from "antd";
import { UsergroupAddOutlined } from '@ant-design/icons';

import styled from "styled-components";
import Popup from "reactjs-popup";

import firebase from "database/FirebaseConfig";

import { useAuth } from "ctx/AuthContext";
import { useUsers } from "hooks/useGetUsers";
import { useCompanie } from "hooks/useGetCompanie";
import MaskCPFCNPJ from "utils/MaskCpfCnpj";
import { Checkbox } from "components/Checkbox";
import { ManagerAccounts } from "components/ManagerAccounts";
import Cookies from 'js-cookie';

import "./index.scss";
import { ResetPassword } from "components/ResetPassword";
import { Helmet } from "react-helmet";
import { ColumnsType } from "antd/lib/table";
import { useAlert } from "react-alert";
import { Colors, Images } from "utils/template";

export type Companies = {
  access: Access;
  finalizationPhotos: FinalizationPhotos;
  name: string;
  users: any;
  uid: string;
  typesenseApiKey: string;
  whatsappNumber?: string;
};

interface FinalizationPhotos {
  stub: boolean;
  client: boolean;
  package: boolean;
}

interface Access {
  delivery: number;
  position: number;
  jor: number;
}

const StyledPopup = styled(Popup)`
  &-content {
    padding: 0;
    border-radius: 8px;
    width: 700px;
    border: none;
  }
`;
const StyledPopupMenu = styled(Popup)`
  &-content {
    width: 10px;
    border: none;
  }
`;

const StyledCheckBox = styled(Checkbox)`
  border-left: 1px solid var(--black);
  border-right: 1px solid var(--black);
`;

interface CompanyDataTable {
  key: string;
  name: string;
  users: ReactNode;
  status: ReactNode;
}

export default function PainelCompanieUsers() {
  const history = useHistory();

  const { setStateCurrentUserPainel, userInfoPainel, setStateCurrentUser, loadInfoUser } =
    useAuth();

  const { company: allCompanies } = useCompanie();

  const [companieSearch, setCompanieSearch] = useState<Companies[]>([]);

  const [openResetPassword, setOpenResetPassword] = useState(false);
  const handleOpenResetPassword = (value: boolean) =>
    setOpenResetPassword(value);

  const [open, setOpen] = useState(false);
  const closeModal = () => setOpen(false);

  const alert = useAlert();

  const [openMenu, setOpenMenu] = useState(false);

  const [name, setName] = useState<string>("");
  const [nameSocial, setNameSocial] = useState<string>("");
  const [cnpj, setCnpj] = useState<string>("");

  const style = { background: "var(--secondary)" };
  const user = Cookies.get("name");
  let color = Colors.secondary;



  const override = css`
    display: block;

    margin: 20% auto;
    border-color: red;
  `;

  useEffect(() => {
    if (allCompanies.loading === undefined) {
      allCompanies.sort((a: Companies, b: Companies) =>
        a.name > b.name ? 1 : -1
      );

      setCompanieSearch(allCompanies);
    }
  }, [allCompanies]);

  async function handleSubmit(event: FormEvent) {
    event.preventDefault();

    const db = firebase.firestore();

    const companySnapshot = await db.collection('companies').where('cnpj', '==', cnpj).get();
    const companyData = companySnapshot?.docs[0]?.data();

    if (!!companyData) {
      alert.error('Empresa ja cadastrada! CNPJ em uso!');
      return;
    }

    const data = {
      name,
      nameSocial,
      cnpj: cnpj,
      finalizationPhotos: {
        stub: false,
        package: false,
        client: false,
      },
      access: {
        delivery: 1,
        jor: 1,
        position: 1,
      },
      users: {},
      colors: {
        background: Images.background,
        color: Colors.secondary,
        logo: Images.logo,
      },
    };

    await db.collection("companies").add(data);
    closeModal();
    window.location.reload()
  }

  const clearFields = () => {
    setName("");
    setNameSocial("");
    setCnpj("");
  };

  function handleSearch(e: any) {
    var data: any = [];

    for (let i = 0; i < allCompanies.length; i++) {
      if (!allCompanies[i]?.name) continue;
      if (
        allCompanies[i].name
          .toLocaleUpperCase()
          .includes(e.target.value.toLocaleUpperCase())
      ) {
        data.push(allCompanies[i]);
      }
    }
    setCompanieSearch(data);
  }

  if (allCompanies.loading) {
    return (
      <div className="sweet-loading">
        <MoonLoader color={color} loading={true} css={override} size={75} />
      </div>
    );
  }

  async function allowOrBlockAccess(uid: string, value: number) {
    const db = firebase.firestore();
    await db
      .collection("companies")
      .doc(uid)
      .update({
        "access.delivery": value,
      })
      .then(() => {
        console.log("Profile Successfully Edited!");
      })
      .catch((error: any) => {
        console.log("Error updating the document:", error);
      });
  }

  const companyDataSource = companieSearch.map(companie => ({
    key: companie?.uid ?? '-',
    name: companie?.name ?? '-',
    users: (
      <ManagerAccounts
        // users={companie.users}
        item={companie}
        allCompanies={allCompanies}
      >
        <UsergroupAddOutlined style={{ display: 'flex' }} />
      </ManagerAccounts>
    ),
    status: (
      <StyledCheckBox
        labels={[["Ativo"], ["Desativado"]]}
        item={companie?.access?.delivery}
        uid={companie?.uid}
        allowOrBlockAccess={allowOrBlockAccess}
      />
    ),
  }))

  const columns: ColumnsType<CompanyDataTable> = [
    {
      title: 'Empresas cadastradas',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Usuários',
      dataIndex: 'users',
      key: 'users',
      width: 100,
      align: 'center',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
  ];


  return (
    <div className="content-painel-users">
      <header>
        <Helmet>
          <title>Painel Raster</title>
        </Helmet>
        <img
          src={Images.logo}
          alt=""
        />
        <StyledPopupMenu
          trigger={
            <button
              type="button"
              id="buttonOpenPopup"
              onClick={() => setOpenMenu(!openMenu)}
            >
              <label id="painel-users-label">R</label>
            </button>
          }
          className="mypopup-menu"
          position={["top center", "bottom right", "bottom left"]}
          open={openMenu}
          closeOnDocumentClick={openResetPassword}
          lockScroll
        >
          <div className="block-menu-painel">
            <span style={{ marginBottom: 10 }}>{user}</span>
            <ResetPassword
              uid={userInfoPainel.uid}
              style={style}
              collection="painel"
              handleOpenResetPassword={handleOpenResetPassword}
            >
              <>Alterar senha</>
            </ResetPassword>
            <Link
              to="/"
              onClick={() => {
                setStateCurrentUser();
                setStateCurrentUserPainel();
                firebase.auth().signOut();
                sessionStorage.removeItem("user-painel");
                sessionStorage.removeItem('userInfo');
                sessionStorage.removeItem('state-user');
                sessionStorage.removeItem('stateUser');
                Cookies.set("currentUser", "");
                Cookies.set("company", "");
                Cookies.set("name", "");
                Cookies.set("document", "");
                Cookies.set("email", "");
                loadInfoUser(null)
                history.push("/");
              }}
            >
              Sair
            </Link>
          </div>
        </StyledPopupMenu>
      </header>


      <main>
        <section className="section-buttons">
          <StyledPopup
            trigger={
              <div>
                <button onClick={() => setOpen(!open)}>
                  cadastrar empresa
                </button>
              </div>
            }
            open={open}
            onClose={closeModal}
            closeOnDocumentClick={false}
            modal
            position="top center"
            lockScroll
          >
            <div className="content-form">
              <div className="header-form">
                <legend>Cadastrar empresa</legend>
                <AiOutlineCloseCircle
                  size={22}
                  color="white"
                  onClick={() => {
                    closeModal();
                    clearFields();
                  }}
                  style={{
                    position: "absolute",
                    right: "4px",
                    top: "4px",
                    cursor: "pointer",
                  }}
                />
              </div>
              <form className="form-painel" onSubmit={handleSubmit}>
                <div className="content-block-form">
                  <div className="block-form-inputs">
                    <label>Nome: </label>
                    <input
                      required
                      type="text"
                      placeholder="Informe o nome"
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <div
                    style={{
                      marginTop: 12,
                      display: "grid",
                      gridTemplateColumns: "70% 30%",
                    }}
                  >
                    <div className="block-form-inputs">
                      <label>Razão social: </label>
                      <input
                        required
                        type="text"
                        placeholder="Informe a razão social"
                        onChange={(e) => setNameSocial(e.target.value)}
                      />
                    </div>
                    <div className="block-form-inputs">
                      <label>CNPJ: </label>
                      <input
                        required
                        type="text"
                        minLength={14}
                        placeholder="Informe o CPF/CNPJ"
                        onChange={(e: any) => {
                          let doc = MaskCPFCNPJ(e.target.value, e.target);
                          e.target.value = doc;
                          setCnpj(doc);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-button-painel">
                  <button onClick={closeModal}>Cancelar</button>
                  <button type="submit">Cadastrar</button>
                </div>
              </form>
            </div>
          </StyledPopup>
          <input
            type="search"
            placeholder="Pesquisar uma empresa..."
            onChange={(event) => handleSearch(event)}
          />
        </section>

        {!!companyDataSource && <Table dataSource={companyDataSource} columns={columns} pagination={false} style={{ maxWidth: 1200, margin: '0 auto' }} />}
      </main>
    </div>
  );
}
