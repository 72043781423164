// assets 
import box from '../assets/icons/box.svg';
import waiting from '../assets/icons/waiting.svg';
import drive from '../assets/icons/drive.svg';
import finish from '../assets/icons/finish.svg';
import warning from '../assets/icons/warning.svg';
import { useEffect, useState } from 'react';

type TpeIconCardProps = {
    typeImage: number;
}

// 1 - box
// 2 - waiting
// 3 - warning 
// 4 - finish
// 5 - traveling

export default function TypeIconCard({ typeImage }: TpeIconCardProps) {

    const [image, setImage] = useState<string>();
    const [msg, setMsg] = useState<string>();


    useEffect(() => {
        if (typeImage === 2) {
            setImage(box);
            setMsg('Em Coleta');

        } else if (typeImage === 1) {
            setImage(waiting);
            setMsg('Aguardando');

        } else if (typeImage === 3) {
            setImage(warning);
            setMsg('Ocorrência');

        } else if (typeImage === 4) {
            setImage(finish);
            setMsg('Finalizada');

        } else if (typeImage === 5) {
            setImage(drive);
            setMsg('Em trânsito');
        } else if (typeImage === 7) {
            setImage(warning);
            setMsg('Retornar Mercadoria');
        }
    }, [typeImage]);

    return (
        <div className="imageStatus">
            <img src={image} alt={msg} />
            <p>{msg}</p>
        </div>
    );
}