
import { FormsData } from 'ctx/useForms';
import { useState, useEffect, useRef } from 'react';
import styles from './styles.module.scss';

interface FormFieldProps {
  form: FormsData;
  index: number;
  onSelectForm: React.Dispatch<React.SetStateAction<string | number | undefined>>;
  formsFieldRef: React.RefObject<HTMLDivElement>;
}

export default function FormField({ form, index, onSelectForm, formsFieldRef }: FormFieldProps) {
  const [isSelected, setIsSelected] = useState(false);
  const fieldRef = useRef<HTMLDivElement>(null);

  useEffect(() => { //useEffect to change the class when the user clicked outside the field
    const popUpFormRef = formsFieldRef.current; //Selecting the popUp HTML element
    popUpFormRef?.addEventListener("click", () => {
      setIsSelected(false);
      onSelectForm(undefined);
    });
  }, [])


  function selectForm() {
    onSelectForm(index);
    setIsSelected(true);
  }

  return (
    <>
      <div ref={fieldRef} className={isSelected === true ? styles.formsInfoSelected : styles.formsInfo} onClick={() => selectForm()}>
        <p>{index + 1}</p>
        <p>{form.name}</p>
        <p>{form.description}</p>
        <p>{form.type}</p>
        <p>{form.size ? form.size : '-'}</p>
        <p>{form.min && form.max ? `${form.min} - ${form.max}` : '-'}</p>
      </div>
    </>
  );
}