export const adressMask = (adress: any) => {
  let adressFormatted = '';

  for (let props in adress) {
    switch (props) {
      case "xLgr":
        adressFormatted += (adress[props][0]).toUpperCase() + ', ';
        break;
      case "xnro":
        adressFormatted += (adress[props][0]).toUpperCase().replace('Nº', '') + ', ';
        break;
      case "xBairro":
        adressFormatted += (adress[props][0]).toUpperCase().replace('BAIRRO', '') + ', ';
        break;
      case "nro":
        adressFormatted += (adress[props][0]).toUpperCase().replace('N', '') + ', ';
        break;
      case "CEP":
        adressFormatted += (adress[props][0]).toUpperCase().replace('CEP', '') + ', ';
        break;
      default:
        // adressFormatted += (adress[props][0]).toUpperCase() + ', ';
        break;
    }
  }
  adressFormatted = adressFormatted.substr(0, adressFormatted.length - 2);
  return adressFormatted;
}