import { createContext, ReactNode, useContext, useState } from 'react';

export interface FormsData {
  uid?: string;
  dependencie?: string,
  dependencieOption?: string;
  order?: number,
  name: string,
  description: string,
  type: string,
  size: number,
  min?: number
  max?: number
}

export interface IForms {
  company: {
    name: string;
    id: string;
  };
  formDescription: string;
  formsFieldId: string[];
}

export interface IFormsFormatted {
  uid: string;
  company: {
    name: string;
    id: string;
  };
  active?: boolean;
  formDescription: string;
  formsFieldId: FormsData[];
}

export interface IFormsSnapshot extends IForms {
  active: boolean;
}
export interface IFormsFormattedSnapshot extends IFormsFormatted {
  active: boolean;
}

interface FormContextProps {
  objectData: FormsData[],
  setObjectData: React.Dispatch<React.SetStateAction<FormsData[]>>;
  refetch: boolean;
  setRefetch: React.Dispatch<React.SetStateAction<boolean>>;
  setFetchDataFlag: React.Dispatch<React.SetStateAction<boolean>>;
  allForms: IFormsFormatted[];
  fetchDataFlag: boolean;
  setAllForms: React.Dispatch<React.SetStateAction<IFormsFormatted[]>>;
  setAllFormsField: React.Dispatch<React.SetStateAction<FormsData[]>>;
  allFormsField: FormsData[];
  formsFields: FormsData[];
  setFormsFields: React.Dispatch<React.SetStateAction<FormsData[]>>; 
  formsField: FormsData;
  setFormsField: React.Dispatch<React.SetStateAction<FormsData>>; 
  isFormActive: boolean;
  setIsFormActive: React.Dispatch<React.SetStateAction<boolean>>;
}

interface FormsProviderProps {
  children: ReactNode;
}

const FormContext = createContext({} as FormContextProps);

export function FormsProvider({ children }: FormsProviderProps) {
  const [objectData, setObjectData] = useState<Array<FormsData>>([]);
  const [fetchDataFlag, setFetchDataFlag] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const [allForms, setAllForms] = useState<IFormsFormatted[]>([])
  const [allFormsField, setAllFormsField] = useState<FormsData[]>([])
  const [formsFields, setFormsFields] = useState<FormsData[]>([]);
  const [formsField, setFormsField] = useState<FormsData>({} as FormsData);
  const [isFormActive, setIsFormActive] = useState(true);

  
  return (
    <FormContext.Provider value={{
      objectData,
      setObjectData,
      refetch,
      setRefetch,
      allForms,
      setFetchDataFlag,
      fetchDataFlag,
      setAllForms,
      setAllFormsField,
      allFormsField,
      formsFields,
      setFormsFields,
      formsField,
      setFormsField,
      isFormActive,
      setIsFormActive,
    }}>
      {children}
    </FormContext.Provider>
  )
}

export const useForms = () => {
  return useContext(FormContext);
}