// styles
import './index.scss';

export type InputDocumentViewProps = {
    title: string;
    img: string;
    select?: boolean;
}

export function InputDocumentView({ title, img, select }: InputDocumentViewProps) {

    var stylesClass = '';

    if (select) {
        stylesClass = 'btnDocuments btnDocumentsSelect'
    } else {
        stylesClass = 'btnDocuments'

    }


    return (
        <div className={stylesClass}>
            <img src={img} alt='' />
            <span>{title}</span>
        </div>
    )

}