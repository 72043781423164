import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import TypesenseInstantSearchAdapter from "typesense-instantsearch-adapter";

// components
import { useCard } from "ctx/CardRasterContext";
import { CreateRomaneio } from "components/CreateRomaneio";
import { CreateDocuments } from "components/CreateDocuments";
import { ImportDocuments } from "components/ImportDocuments";
import { InputDocumentView } from "components/InputDocumentView";
import {
  InstantSearch,
  SearchBox,
  Configure,
  Pagination,
  RefinementList,
  Hits as HitsTypesense2
} from "react-instantsearch-dom";

// assets
import filter from "assets/icons/filter.svg";
import realocation from "assets/icons/arrow.svg";
import checkbox from "assets/icons/checkTrue.svg";
import searchIcon from "assets/icons/search.svg";

// styles
import "react-tabs/style/react-tabs.css";
// import "./index.scss";
import { CardRaster } from "components/CardRaster";
import { unflatObject } from "utils/unflatObject";
import { Labels } from "utils/template";
import { useAuth } from "ctx/AuthContext";


export function TypesenseDocs() {
  const apiKeyTypesense = Cookies.get("apiKeyTypesense");
  const company = Cookies.get("company");
  const { userInfo } = useAuth();

  const [myRefresh, setMyRefresh] = useState(false);

  const {
    selectIdsToCreateRomaneios,
    allDocs,
  } = useCard();

  const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
    server: {
      apiKey: String(apiKeyTypesense), // Be sure to use an API key that only allows search operations
      nodes: [
        {
          host: "lh01y9wqj82n4zdxp-1.a1.typesense.net",
          port: 443,
          protocol: "https",
        },
      ],
      cacheSearchResultsForSeconds: 2 * 60,
    },
    additionalSearchParameters: {
      query_by:
        "company, cod, nameDocument, client.cpf_cnpj, client.fone, client.name, destiny.addressDestiny, destiny.citieDestiny, destiny.stateDestiny, origin.address, origin.citie, origin.state",
    },
  });

  const searchClient = typesenseInstantsearchAdapter.searchClient;


  useEffect(() => {
    let interval = setInterval(
      () =>
        setMyRefresh((prevState) => !prevState),
      2000
    );
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <section>
      <InstantSearch
        refresh={myRefresh}
        indexName={Labels.typesenseDocs}
        searchClient={searchClient}
      >
        <div className="btnsDocument" id="btnsDocument">
          <div className="buttonContainer">
            <CreateDocuments
              Target={
                <InputDocumentView
                  title="criar documento"
                  img={realocation}
                  select={true}
                />
              }
              title="cadastrar documento"
              tam={allDocs.length}
            />
            <ImportDocuments
              company={company}
              tam={allDocs.length}
            />
            {selectIdsToCreateRomaneios.length > 0 && (
              <CreateRomaneio
                title="criar Romaneio"
                Target={
                  <InputDocumentView
                    title="criar romaneio"
                    img={realocation}
                  />
                }
              />
            )}
          </div>
          <div className="block-checkbox">
            <label className="filter">
              <img src={filter} alt="search" />
              <span>Filtrar:</span>
            </label>

            <div>
              <RefinementList
                attribute="type"
                defaultRefinement={["1", "2"]}
                transformItems={(items: any) => {
                  const colectedDocs = items.filter((document: any) => document.label === '1').map((document: any) => {
                    return {
                      ...document,
                      label: "Coleta"
                    }
                  });
                  const deliveryDocs = items.filter((document: any) => document.label === '2').map((document: any) => {
                    return {
                      ...document,
                      label: "Entrega"
                    }
                  });
                  const canceledDocs = items.filter((document: any) => document.label === '7').map((document: any) => {
                    return {
                      ...document,
                      label: "Cancelados"
                    }
                  });
                  const allDocs = [...colectedDocs, ...deliveryDocs]
                  return allDocs;
                }}
              />
            </div>

            <div>
              <RefinementList
                defaultRefinement={["1"]}
                attribute="status"
                transformItems={(items: any) => {
                  let itemRemake;
                  const finishedDocs = items.filter((document: any) => document.label === '4').map((document: any) => {
                    return {
                      ...document,
                      label: "Finalizado"
                    }
                  });
                  const waitingDocs = items.filter((document: any) => document.label === '1').map((document: any) => {
                    return {
                      ...document,
                      label: 'Aguardando'
                    }
                  });

                  const allDocs = [...finishedDocs, ...waitingDocs];
                  return allDocs;
                }
                }
              />
            </div>
          </div>
          <SearchBox
            submit={<img src={searchIcon} alt="" />}
            translations={{
              submitTitle: "Enviar busca",
              resetTitle: "Limpar busca",
              placeholder: "Buscar",
            }}
          />
        </div>
        <div>
          <HitsTypesense2 hitComponent={HitTypesense} />
        </div>
        <Configure
          filters={`intoRomaneio:false && companyGroupId:${userInfo?.selectedCompanyGroupId}`}
          hitsPerPage={30}
        />
        <Pagination />
      </InstantSearch>
    </section>
  );
}

function HitTypesense({ hit }: any) {
  const cardProps = {
    ...unflatObject(hit),
    isTypesense: true,
  };

  return (
    <CardRaster
      key={hit.id}
      checkBox={checkbox ? true : false}
      card={cardProps}
      isOnDocumentView
    />
  );
}