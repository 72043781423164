// get documents into Romaneio
import { useState } from 'react';
import firebase from '../database/FirebaseConfig';

function GetImageOccurrences() {
    const controller = new AbortController();
    const [loadingActivities, setLoginActivities] = useState(false);

    const getImages = async ({ onComplete, path, setImageTab, imageTab }: any) => {

        firebase.storage()
            .ref(`${path}`)
            .listAll()
            .then(function (result: any) {
                result.items.forEach(function (imageRef: any) {
                    imageRef.getDownloadURL().then(function (url: any) {
                        if (imageTab.indexOf(url) === -1) {
                            imageTab.push(url);
                            setImageTab(imageTab);
                            if (result.items.length === imageTab.length) {
                                onComplete(imageTab);
                            }
                        }
                    }).catch(function (error: any) {
                        // Handle any errors
                    });
                });
            })
            .catch((e: any) => console.log('Errors while downloading => ', e));
        return () => {
            const timeout = setTimeout(() => controller.abort(), 5000);
            clearTimeout(timeout);
        };
    }
    return { loadingActivities, getImages, setLoginActivities };
}


export { GetImageOccurrences as default };
