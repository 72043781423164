import { CameraOutlined, RightOutlined } from "@ant-design/icons";
import { useApi } from "ctx/ApiContext";
import { AuthContext } from "ctx/AuthContext";
import { db, messaging } from "database/FirebaseConfig";
import firebase from "firebase";
import { useContext, useEffect, useState } from "react";
import { secondsToTime } from "utils/timestampToDate";
import "./index.scss";

interface ChatProps {
  companyId: string;
  driverId: string;
  cardId: string;
  cardStatus: number;
}

type MessageI = {
  uid: string;
  message: string;
  createdAt: {
    seconds: number,
    nanoseconds: number,
  };
  user: {
    id: string;
    name: string;
  }
}

interface ChatI {
  companyId: string;
  lastMessage: MessageI,
  lastRead: {
    ['driverId']: number; // ID do Motorista
    ['comapnyId']: number; // ID da Company
  },
  messages: [
    {
      ['messageId']: MessageI
    }
  ]

}

export function Chat({ companyId, driverId, cardId, cardStatus }: ChatProps) {
  const [message, setMessage] = useState('')
  const { userInfo } = useContext(AuthContext);
  const [userId, setUserId] = useState('');
  const [messages, setMessages] = useState<MessageI[]>([]);
  const [fcmToken, setFcmToken] = useState(['']);

  const { setChatIsOpen, chatIsOpen } = useApi();

  useEffect(() => {
    if (!driverId || driverId.length <= 0) return;
    async function getFCMToken() {
      const driverSnapshot = await db.collection('users').doc(driverId).get();
      if (!driverSnapshot.data()) return;
      const driverFcmToken = driverSnapshot.data()!.fcmToken;
      setFcmToken(driverFcmToken)
    }
    getFCMToken();
  }, [driverId])

  useEffect(() => {
    setChatIsOpen(true);

    const unsubscribe = db.collection('chats').doc(cardId).collection('messages').orderBy('createdAt', 'asc').onSnapshot(async (snapShot) => {
      const messagesFetched = snapShot.docs.map(doc => ({
        uid: doc.id,
        ...doc.data()
      })) as any
      setMessages(messagesFetched);

      const timestamp = firebase.firestore.Timestamp.fromDate(new Date())


      if (messagesFetched.length === 0) return;

      if (userInfo?.selectedCompanyGroupId) await db.collection('companyGroups').doc(userInfo.selectedCompanyGroupId).update({
        "chat.lastRead": timestamp,
      });

      await db.collection('chats').doc(cardId).set({
        lastRead: {
          [companyId]: firebase.firestore.Timestamp.fromDate(new Date()),
        },
      }, { merge: true });
      scrollToBotton();
    });


    return () => {
      setChatIsOpen(false);
      unsubscribe();
    };
  }, [])

  function scrollToBotton() {
    if (!Document) return;
    const [chatContainer] = document.getElementsByClassName('mesagesContainer');
    if (!!chatContainer) {
      chatContainer.scrollTop = chatContainer.scrollHeight;
    };
  }

  async function sendMessage(event: any) {
    event.preventDefault();
    if (message === '') return;
    const timestamp = firebase.firestore.Timestamp.fromDate(new Date());

    const messageToSend = {
      createdAt: timestamp,
      message,
      user: {
        id: companyId,
        name: userInfo?.name,
      }
    }
    const { id: messageId } = await db.collection('chats').doc(cardId).collection('messages').add(messageToSend);

    const dataToChat = {
      companyId,
      lastMessage: {
        id: messageId,
        ...messageToSend
      },
      lastRead: {
        [companyId]: firebase.firestore.Timestamp.fromDate(new Date()),
      },
      companyGroupId: userInfo?.selectedCompanyGroupId ?? null
    }

    await db.collection('chats').doc(cardId).set(dataToChat, { merge: true });


    await db.collection('users').doc(driverId).update({
      "chat.lastReceived": firebase.firestore.Timestamp.fromDate(new Date()),
    })

    if (userInfo?.selectedCompanyGroupId) await db.collection('companyGroups').doc(userInfo?.selectedCompanyGroupId).update({ //A validar com Maikel se é assim mesmo
      "chat.lastReceived": firebase.firestore.Timestamp.fromDate(new Date()),
      "chat.lastRead": firebase.firestore.Timestamp.fromDate(new Date()),
    });

    if (userInfo?.company) await db.collection('companies').doc(userInfo?.company).update({ //A validar com Maikel se é assim mesmo
      "chat.lastReceived": firebase.firestore.Timestamp.fromDate(new Date()),
      "chat.lastRead": firebase.firestore.Timestamp.fromDate(new Date()),
    });

    await db.collection('companies').doc(userInfo?.company).update({ //A validar com Maikel se é assim mesmo
      "chat.lastReceived": firebase.firestore.Timestamp.fromDate(new Date()),
      "chat.lastRead": firebase.firestore.Timestamp.fromDate(new Date()),
    });

    const [chatContainer] = document.getElementsByClassName('mesagesContainer');
    chatContainer.scrollTop = chatContainer.scrollHeight;

    setMessage('');
  }



  return (
    <div className="chatMessenger">
      <>

        {messages.length > 0 ?
          <>
            <legend style={{ paddingLeft: 15, fontWeight: "bold", fontSize: 18 }}>
              Mensagens:{" "}
            </legend>
            <div className="mesagesContainer">
              {messages.map(message => {
                const timeConverted = secondsToTime(message.createdAt.seconds);
                const onlyFirstName = message.user.name.split(' ')[0].toLocaleLowerCase();
                const nameFormatted = onlyFirstName.charAt(0).toUpperCase() + onlyFirstName.slice(1)
                return (
                  <div
                    className={message.user.id === userInfo?.company ? 'containerSendMensages' : 'containerReceivedMensages'}
                  >
                    {message.user.id !== userId &&
                      <b>{nameFormatted}</b>
                    }
                    <span>{message.message}</span>
                    <p>{timeConverted}</p>
                  </div>
                )
              })
              }
            </div>
          </>
          :
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 'auto' }}>
            <span style={{ color: 'var(--secondary)' }}>
              Ainda não foram enviadas mensagens
            </span>
          </div>
        }

        {!(cardStatus === 1 || cardStatus === 4) &&
          <form onSubmit={sendMessage} id="sendMensageInput">
            <input placeholder="Digite uma mensagem" onChange={(event) => setMessage(event.target.value)} value={message} />
            <div className="inputMessageIconsContainer">
              {/* <CameraOutlined style={{ fontSize: '1.2rem' }} /> */}
              <RightOutlined
                type="submit"
                style={{
                  color: 'var(--secondary)',
                  backgroundColor: 'var(--primary)',
                  padding: '8px',
                  borderRadius: '100%',
                  cursor: 'pointer',
                }}
                onClick={sendMessage}
              />
            </div>
          </form>


        }
      </>
    </div>
  );
}