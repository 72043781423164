const MaskPhone = (value, target, type) => {
    if (value.match(RegExp("[a-zA-z]+"))) {
        target.placeholder = "Apenas números";
    }
    let input = value.replace(/\D/g, "");

    return input
        .replace(/\D/g, "")
        .replace(/(\d{2})/, "($1)")
        .replace(/(\d{5})(\d{4})/, "$1-$2")
        // .replace(/(-\d{2})\d+?$/, "$1");

        // .replace(/(\d{3})(\d{1,2})/, "$1$2")
        // .replace(/(-\d{2})\d+?$/, "$1");
}

export default MaskPhone;
