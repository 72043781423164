import { FormsData, IForms } from 'ctx/useForms';
import { dbForms } from '../database/FirebaseConfig';


export async function deleteForms(uid: string) {
  try {
    await dbForms.collection('forms').doc(uid).delete()
  } catch (error) {
    console.log("!!!! Something Gone Wrong !!!!\n", error)
  }
}

export async function deleteFormField(uid: string) {
  try {
    await dbForms.collection('form_field').doc(uid).delete()
  } catch (error) {
    console.log("!!!! Something Gone Wrong !!!!\n", error)
  }
}

