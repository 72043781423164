import { useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { BsFillTrashFill, BsPencilSquare } from 'react-icons/bs';
import styles from './styles.module.scss';


export default function InfoForms() {
  const [changeItem, setChangeItem] = useState(false);


  function handleRemove() {
    // var registerAccount = [];
    // var Format = {};

    // for (var data in company.users) {
    //   if (data !== oldCnpf_Cpf) {
    //     Format = {
    //       ...Format,
    //       [data]: company.users[data],
    //     }
    //     registerAccount.push(Format);
    //   }
  }

  function handleSubmit() {
    // var cpf_cnpjFormated = cpf_cnpj.replaceAll('.', '').replaceAll('/', '').replaceAll('-', '');
    // var Format = {};

    // for (var data in company.users) {
    //   if (data !== oldCnpf_Cpf) {
    //     Format = {
    //       ...Format,
    //       [data]: company.users[data],
    //     }
    //   } else {
    //     Format = {
    //       ...Format,
    //       [cpf_cnpjFormated]: Boolean(user.access),
    //     }
    //   }
  }
  return (
    <div className={styles.container}>
      <section>
        <p>09/09/2021</p>
        <p>Avaliação do veículo</p>
        <p>09/09/2021 - 13:45</p>
        <p>Ativo</p>
        <div
          className={styles.blockIconButtons}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            fontSize: '1.0rem',
            alignItems: "center",
            color: "var(--secondary)",
            width: 150
          }}>
          {!changeItem &&
            <BsPencilSquare
              style={{ cursor: 'pointer', marginRight: 10 }}
              onClick={() => setChangeItem(!changeItem)}
            />
          }


          {changeItem ? (
            <>
              <div className={styles.buttonsContainer} style={{
                display: "flex",
                alignItems: "center",
              }}>
                <div className={styles.cancelButtonAccounts} onClick={() => setChangeItem(!changeItem)}>
                  Cancelar
                </div>
                <div className={styles.sendButtonAccounts} onClick={() => handleSubmit()}>
                  Salvar
                </div>
              </div>

            </>
          ) : (
            <BsFillTrashFill
              style={{ cursor: 'pointer' }}
              onClick={() => {
                confirmAlert({
                  message: 'Deseja remover este formulário?',
                  buttons: [
                    {
                      label: 'Cancelar',
                      onClick: () => ('Cancelar')
                    },
                    {
                      label: 'Sim',
                      onClick: () => {
                        handleRemove();
                      }
                    }
                  ],
                  overlayClassName: 'confirmAlert'
                });
              }}
            />
          )}
        </div>

      </section>
    </div>

  );
}