import { FormsData } from 'ctx/useForms';
import React from 'react';
import { UseFormRegister } from 'react-hook-form';

export const SelectField = React.forwardRef<HTMLSelectElement, { label: string, } & ReturnType<UseFormRegister<FormsData>>>(({ onChange, onBlur, name, label }, ref) => {
  return (
    <>
      <label>{label}</label>
      <select name={name} ref={ref} onChange={onChange} onBlur={onBlur}>
        <option value="">
          Nenhuma
        </option>
        {/* {options.map(option => {
        <option value={option.name}>{option.name}</option>
      })} */}
      </select>
    </>
  )
});