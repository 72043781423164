import { FormEvent, useState } from "react";
import { useAlert } from "react-alert";
import { AiOutlineCloseCircle } from "react-icons/ai";
import Popup from "reactjs-popup";
import axios from 'axios'
import styled from "styled-components";

import "./index.scss";
import { Links } from "utils/template";

const StyledPopupPassword = styled(Popup)`
  &-content {
    padding: 0;
    border-radius: 8px;
    width: 250px;
    border: none;
  }
`;

type StylesProps = {
  background: string;
  width?: number;
};

type ResetPasswordProps = {
  uid: string;
  style: StylesProps;
  collection: string;
  handleOpenResetPassword?: (value: boolean) => void;
  children: React.ReactNode;
};

export function ResetPassword({
  style,
  uid,
  collection,
  handleOpenResetPassword,
  ...children
}: ResetPasswordProps) {
  const alert = useAlert();
  const [open, setOpen] = useState(false);

  const closeModal = () => {
    clearFields();
    setOpen(false);
    if (handleOpenResetPassword) handleOpenResetPassword(true);
  };

  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");

  async function handleSubmit(event: FormEvent) {
    event.preventDefault();
    if (password !== passwordConfirm) return;
    try {
      axios.put(
        // 'https://us-central1-raster-homolog-89931.cloudfunctions.net/updatePassword',
        Links.changePassword,
        { id: uid, newPassword: password }
      )
        .then(() => {
          console.log("Profile Successfully Edited!");
          alert.success("SENHA ATUALIZADA!");
        })
        .catch((error: any) => {
          console.log("Error updating the document:", error);
          alert.error("ERRO AO ALTERAR SENHA");
        });
      closeModal();
    } catch (e: any) {
      alert.error("ERRO AO ALTERAR SENHA", e);
    }
  }

  function clearFields() {
    setPassword("");
    setPasswordConfirm("");
  }

  return (
    <StyledPopupPassword
      trigger={
        <div>
          <button
            onClick={() => {
              setOpen(!open);
              if (handleOpenResetPassword) handleOpenResetPassword(false);
            }}
            id={style.background === "var(--secondary)" ? "buttonOpenPopup" : ""}
            style={style.background === "var(--secondary)" ? {} : style}
          >
            {children.children}
          </button>
        </div>
      }
      open={open}
      onClose={closeModal}
      closeOnDocumentClick={false}
      modal
      position="top center"
      lockScroll
    >
      <div className="content-resetPassword">
        <div
          className="header-form"
          style={{ background: `${style.background}` }}
        >
          <legend>Redefinir senha</legend>
          <AiOutlineCloseCircle
            size={22}
            color="white"
            onClick={closeModal}
            style={{
              position: "absolute",
              right: "4px",
              top: "4px",
              cursor: "pointer",
            }}
          />
        </div>
        <form onSubmit={handleSubmit}>
          <div className="block-resetPassword">
            <div className="block-form-inputs">
              <label>Senha: </label>
              <input
                required
                type="password"
                minLength={6}
                placeholder="Informe a senha"
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="block-form-inputs">
              <label>Confirme a senha: </label>
              <input
                className={
                  password !== passwordConfirm && passwordConfirm.length > 0
                    ? "warning"
                    : ""
                }
                required
                type="password"
                placeholder="confirme a senha"
                onChange={(e) => setPasswordConfirm(e.target.value)}
              />
            </div>
          </div>
          <div className="block-button">
            <button onClick={closeModal}> Cancelar</button>
            {password !== passwordConfirm ? (
              <button
                style={style}
                onClick={() => alert.error("SENHAS DIFERENTES!")}
              >
                Salvar
              </button>
            ) : (
              <button type="submit" style={style}>
                Salvar
              </button>
            )}
          </div>
        </form>
      </div>
    </StyledPopupPassword>
  );
}
