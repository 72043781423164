import styled from "styled-components";
import { Popup } from "reactjs-popup";
import { useRef, useState } from "react";
import { CreateDocumentsProps } from "components/CreateForms";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useAlert } from "react-alert";
import styles from "./styles.module.scss";
import IncludeFieldForm from "components/IncludeFieldForm";
import { useEffect } from "react";
import { IForms, useForms } from "ctx/useForms";
import { useForm, SubmitHandler } from "react-hook-form";
import FormField from "../FormField";
import { default as CreateForms } from "hooks/useCreateForms";
import { getCompanies, ICompanies } from "hooks/useGetCompanie";
import { default as CreateFormsField } from "hooks/useCreateFormsField";

const StyledPopup = styled(Popup)`
  &-content {
    padding: 0px;
    border: none;
    border-radius: 18px;
  }

  @media (max-width: 520px) {
    &-content {
      width: 95%;
    }
  }
`;

export default function CreateFormsToForms({
  Target,
  title,
}: CreateDocumentsProps) {
  const [open, setOpen] = useState(false);
  const [openModal2, setOpenModal2] = useState(false);
  const [description, setDescription] = useState("");
  const [Company, setCompany] = useState("");
  const [changeClass, setChangeClass] = useState(0);
  const [selectedForm, setSelectedForm] = useState<string | number | undefined>(
    ""
  );
  const [companies, setCompanies] = useState<ICompanies[]>([]);

  const alert = useAlert();
  const closeModal = () => setOpen(false);
  const closeModal2 = () => setOpenModal2(false);
  const stateDefault = "Selecione uma empresa...";
  const { setRefetch, formsFields, setFormsFields, formsField } = useForms();
  const { register, handleSubmit, watch, setFocus } = useForm();
  const ref = useRef<HTMLDivElement>(null);

  async function loadCompanies() {
    const dataCompanies = await getCompanies();
    setCompanies(dataCompanies);
  }

  function clearFields() {
    setDescription("");
    setCompany("");
  }

  function exitModal() {
    clearFields();
    setChangeClass(0);
    setFormsFields([]);
    closeModal();
  }

  useEffect(() => {
    loadCompanies();
  }, []);

  useEffect(() => {
    if (formsField.name !== undefined) {
      setFormsFields((prevFields) => [...prevFields, formsField]);
    }
  }, [formsField]);

  const onSubmit: SubmitHandler<IForms> = async (data) => {
    data.company = JSON.parse(data.company as any);

    if (data.company.name === "DEFAULT") {
      alert.error("Insira o nome da empresa!");
      setFocus("company");
      return;
    }

    if (formsFields.length === 0) {
      alert.error("Insira algum campo para o formulário");
      return;
    }
    let formsFieldId = [] as string[];

    for (const field of formsFields) {
      const uid = await CreateFormsField(field);
      formsFieldId = [...formsFieldId, uid];
    }

    try {
      const forms = { ...data, formsFieldId };
      await CreateForms(forms);
      alert.success("Campo de formulário salvo com sucesso");
      setRefetch((prev) => !prev);
    } catch {
      alert.success("Erro ao submeter campo no formulário");
    }
    exitModal();
  };

  async function handleDeleteField() {
    if (selectedForm !== undefined) {
      setFormsFields((prev) => {
        const newFields = prev.filter((field, index) => index !== selectedForm);
        return newFields;
      });
      setSelectedForm(undefined);
      return;
    } else {
      alert.error("Field ID Undefined!");
    }
  }

  return (
    <div id="popUpContainer">
      <StyledPopup
        trigger={
          <div>
            <button id="buttonOpenPopup" onClick={() => setOpen(!open)}>
              {Target}
            </button>
          </div>
        }
        open={open}
        closeOnDocumentClick={!open}
        onClose={closeModal}
        modal
        position="top center"
        nested={true}
        lockScroll
      >
        <div className={styles.popupCreateDocument}>
          <div className={styles.headerDocument}>
            <span>{title}</span>
            <AiOutlineCloseCircle
              color="white"
              style={{ cursor: "pointer" }}
              onClick={() => {
                exitModal();
              }}
              size={22}
            />
          </div>
          <form
            key={1}
            id="myForm"
            style={{ overflow: "scroll", paddingBottom: 20 }}
          >
            <legend>Informações do formulário</legend>
            <div className={styles.infoDocumentos}>
              <div className={styles.blockInfoDocuments}>
                <label>Descrição</label>
                <input {...register("formDescription", { required: true })} />
              </div>
              <div className={styles.blockInfoDocuments}>
                <label>Empresa</label>
                <select
                  className={
                    changeClass ? styles.selectSelected : styles.selectDefault
                  }
                  {...register("company", { required: true })}
                >
                  <option
                    // disabled={true}
                    value="DEFAULT"
                    style={{ fontStyle: "italic", color: "#888888" }}
                  >
                    {stateDefault}
                  </option>
                  {companies.map((company, index) => (
                    <option key={index} value={JSON.stringify(company)}>
                      {company.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className={styles.blockForms}>
              <span>Ordem</span>
              <span>Nome</span>
              <span>Descrição</span>
              <span>Tipo</span>
              <span>Tamanho</span>
              <span>Min/Max</span>
            </div>
            <section className={styles.blockFormsContainer} ref={ref}>
              {formsFields.map((form, index) => {
                return (
                  <FormField
                    key={index}
                    form={form}
                    index={index}
                    onSelectForm={setSelectedForm}
                    formsFieldRef={ref}
                  />
                );
              })}
            </section>

            <div className={styles.submitFormsContainer}>
              <button type="button" onClick={() => exitModal()}>
                Cancelar
              </button>
              <button type="submit">Salvar</button>
              {/* <input type="submit" /> */}
            </div>
          </form>
          <div className={styles.buttonFormsContainer}>
            <StyledPopup
              trigger={
                <div>
                  <button
                    type="button"
                    onClick={() => setOpenModal2(!openModal2)}
                  >
                    Incluir
                  </button>
                </div>
              }
              position="top center"
              modal
              open={openModal2}
              nested={true}
              onClose={closeModal2}
              closeOnDocumentClick={false}
              lockScroll
            >
              <IncludeFieldForm setOpenModal={setOpenModal2} />
            </StyledPopup>
            {/* <button type="button">Alterar</button> */}
            {selectedForm !== undefined && (
              <button
                type="button"
                onClick={() => {
                  handleDeleteField();
                }}
              >
                Excluir
              </button>
            )}
          </div>
        </div>
      </StyledPopup>
    </div>
  );
}
