import { storage } from '../database/FirebaseConfig';
import Cookies from 'js-cookie';
import { UseUpdateColorThemeBackground, UseUpdateColorThemeLogo } from './useUpdateColorTheme';
import { useState } from 'react';

const UseGetImages = async (type: string) => {
    var URL;

    const getURL = storage.ref().child(`images/${type}`);

    await getURL.getDownloadURL().then((url) => {
        URL = url;
    }).catch(function (error) {
        // Uh-oh, an error occurred!
    });;

    return URL;
}
const UseGetImagesOccurrennce = async (path: string) => {
    var URL;
    const [imageTab, setImageTab] = useState<any>([]);

    const getURL = storage.ref().child(`${path}`);

    await getURL.listAll().then((result) => {
        result.items.forEach(function (imageRef) {
            imageRef.getDownloadURL().then(function (url) {
                imageTab.push(url);
                setImageTab(imageTab);
                console.log(imageTab)
            }).catch(function (error) {
                // Handle any errors
            });
        });
    }).catch(function (error) {
        // Uh-oh, an error occurred!
    });;

    return URL;
}

const handleRemoveImages = async () => {
    var storageRef = storage.ref('images');
    var logoRef = storageRef.child('logo');

    // Delete the file
    logoRef.delete().then(function () {
        // File deleted successfully
    }).catch(function (error) {
        // Uh-oh, an error occurred!
    });

    var backgroundRef = storageRef.child('background');
    // Delete the file
    backgroundRef.delete().then(function () {
        // File deleted successfully
    }).catch(function (error) {
        // Uh-oh, an error occurred!
    });
}

const HandleUpload = (image: any, type: any, company: any, uid: any) => {

    const uploadTask = storage.ref(`${company}/themes/${type}`).put(image);
    uploadTask.on(
        "state_changed",
        snapshot => { },
        error => {
        },
        () => {
            storage
                .ref(`${company}/themes`)
                .child(type)
                .getDownloadURL()
                .then(url => {
                    Cookies.set(type, url);
                    if (type === 'background') {
                        UseUpdateColorThemeBackground(uid.current, url);
                    } else {
                        UseUpdateColorThemeLogo(uid.current, url);
                    }
                });
        }
    )
    return URL;
}

async function doSomething(type: any) {
    let result = await UseGetImages(type);
    if (result !== undefined) {
        return result + 1;
    } else {
        return undefined;
    }
}

async function HandleImageIntoCloudStorage(image: any, type: any, company: any, uid: any) {

    if (type === 1) {
        HandleUpload(image[0], 'logo', company, uid);
    }
    if (type === 2) {
        HandleUpload(image[0], 'background', company, uid);
    }
}

export { HandleImageIntoCloudStorage as default, doSomething, UseGetImages as useGetImages, handleRemoveImages, UseGetImagesOccurrennce }