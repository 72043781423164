import { Link } from "react-router-dom";

export default function NotFound() {
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh'
    }}>
      <h1 style={{marginBottom: '2rem'}}>404 - Not Found!</h1>
      <Link to="/" style={{fontSize: '1.2rem', textDecoration: 'none',}}>
        Go Home
      </Link>
    </div>
  )
}
