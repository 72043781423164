import { useApi } from "ctx/ApiContext";
import { useState } from "react";
import alertSVG from "assets/icons/notification.svg";
import Popup from "reactjs-popup";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useCard } from "ctx/CardRasterContext";
import { useAlert } from "react-alert";
import { UpdateAllOccurrencesToCheck } from "hooks/useUpdateNotifications";
import styled from "styled-components";
import { GiPlainCircle } from "react-icons/gi";
import { InfoCards } from "components/InfoCards";

export const StyledPopupNotification = styled(Popup)`
  &-content {
    width: 1200px;
    padding: 0px;
    border: none;
    border-radius: 16px;
    @media (max-width: 560px) {
      width: 75%;
    }
  }
  &-overlay {
    background: rgba(0, 0, 0, 0.2);
  }
`;

export const Notification = () => {
  const [isOpenMenu, setIsOpenMenu] = useState<boolean>(false);
  const closeModal = () => setIsOpenMenu(false);

  const [isOpenNotification, setIsOpenNotification] = useState<boolean>(false);
  const closeModalNotificationItem = () => {
    closeModal();
    setIsOpenNotification(false);
  };
  const { allNotifications } = useApi();

  const totalNotification = allNotifications.length

  return (
    <>
      {totalNotification === 0 ? (
        <div>
          <button
            type="button"
            className="button"
            id="buttonOpenPopup"
          >
            <img src={alertSVG} alt="Counter of notifications" />
            <span className=""></span>
          </button>
        </div>
      ) : (
        <Popup
          trigger={
            <div>
              <button
                type="button"
                className="button"
                id="buttonOpenPopup"
                onClick={() => setIsOpenMenu((o) => !o)}
              >
                <img src={alertSVG} alt="Counter of notifications" />
                {totalNotification !== 0 ? (
                  <span className="Alert">{totalNotification}</span>
                ) : (
                  <span className=""></span>
                )}
              </button>
            </div>
          }
          className="mypopupNotificattion"
          onClose={closeModal}
          open={isOpenMenu}
          closeOnDocumentClick={!isOpenNotification}
          keepTooltipInside=".tooltipBoundaryNotification"
          lockScroll
        >
          <div className="alertPopup">
            <span>Notificações</span>
            <AiOutlineCloseCircle
              size={14}
              style={{
                position: "absolute",
                right: "4px",
                top: "4px",
                cursor: "pointer",
              }}
              onClick={closeModal}
            />
            {allNotifications.map((item: any, index: number) => {
              if (item.check === true) {
                return <div key={index}></div>;
              }
              return (
                <OccurenceNotification
                  key={index}
                  item={item}
                  closeModalNotificationItem={closeModalNotificationItem}
                  setIsOpenNotification={setIsOpenNotification}
                />
              );
            })}
          </div>
        </Popup>
      )}
    </>
  );
};

function OccurenceNotification({
  item,
  index,
  closeModalNotificationItem,
  setIsOpenNotification,
}: any) {
  const { getOneDoc } = useCard();
  const alert = useAlert();
  const [oneSelectPopup, setOneSelectPopup] = useState(false);

  const closeModal = () => {
    if (item.occurrences === '') {
      UpdateAllOccurrencesToCheck(item.uid);
    }
    setOneSelectPopup(false);
    closeModalNotificationItem();
  };

  return (
    <>
      <StyledPopupNotification
        trigger={
          <div>
            <button
              type="button"
              className="button"
              id="buttonOpenPopupNotification"
              onClick={() => {
                setIsOpenNotification((o: any) => !o);
                setOneSelectPopup((o: any) => !o);
              }}
            >
              <div
                className={"blockMsg blockMsg-focus"}
                key={index}
              >
                <GiPlainCircle
                  color={"red"}
                  style={{ paddingTop: "6px" }}
                  size={22}
                />
                <div className="msgAlert" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  <span style={{ fontWeight: 'bold' }}>Nova ocorrência</span>
                  <span >
                    {item.type === 1 ? "Coleta" : "Entrega"} {item.cod}
                  </span>
                </div>
              </div>
            </button>
          </div>
        }
        modal
        key={index}
        open={oneSelectPopup}
        className="bodynotification"
        closeOnDocumentClick={false}
        onClose={() => {
          closeModal();
        }}
        lockScroll
      >
        <div className="alloccurrence">
          <AiOutlineCloseCircle
            size={18}
            color="white"
            style={{
              position: "absolute",
              right: "4px",
              top: "4px",
              cursor: "pointer",
              marginBottom: 10,
            }}
            onClick={closeModal}
          />
          {item && (
            <div className="mainInformation">
              <InfoCards
                handleClick={() => { }}
                cardProps={item}
                closeIcon={false}
                isOpenOccurence={false}
              />
            </div>
          )}
        </div>
      </StyledPopupNotification>
    </>
  );
}