import { FormEvent, useContext, useEffect, useState } from "react";
import Styled from "styled-components";
import { Popup } from "reactjs-popup";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useAlert } from "react-alert";
import Select from "react-select";
import Cookies from "js-cookie";

// components
import { useApi } from "ctx/ApiContext";
import { CardRaster } from "../CardRaster";
import { useUpdate } from "ctx/UpdateContext";
import { useCard } from "ctx/CardRasterContext";
import { CardRasterProps } from "types/InterfaceCard";
import { SectionRomaneiosProps } from "types/InterfaceRomaneio";

//hooks

import { UpdateAllFields } from "hooks/useUpdateRomaneio";
import {
  UpdateCardsToIntoRomaneio,
  UpdateConcatHistory,
  UpdateHistory,
} from "hooks/useUpdateCard";
import {
  default as UseCreateRomaneio,
  hiddenDocumentoIntoRomaneios,
} from "hooks/useCreateRomaneios";
import { useUpdateDriversRasterMB as AllowAcccessToDelivery } from "hooks/useUpdateDriversRasterMB";
import { useAuth } from "ctx/AuthContext";

//database
import FirebaseConfig, { db } from "database/FirebaseConfig";

// styles
import "./index.scss";
import "reactjs-popup/dist/index.css";
import { usePagination } from "ctx/PaginationContext";

const StyledPopupRomaneio = Styled(Popup)`
&-content {
    width: 850px;
    padding: 0px;
    border: none;
    border-radius: 18px;
  }
`;

type SelectDriverProps = {
  value: string;
  label: string;
};

type CreateRomaneioProps = {
  Target: any;
  romaneio?: SectionRomaneiosProps;
  update?: boolean;
  title: string;
  driverUID?: any;
  totalFinishDemands?: number;
  dataRomaneiosCard?: CardRasterProps[];
  firstTooltip?: () => void;
  updateRomaneio?: () => void;
  handlePopup?: () => void;
};

export function CreateRomaneio({
  handlePopup,
  Target,
  romaneio,
  title,
  update = false,
  dataRomaneiosCard,
  totalFinishDemands,
  firstTooltip,
  updateRomaneio,
}: CreateRomaneioProps) {
  const theme = Cookies.get("colorTheme");
  document.documentElement.style.setProperty("--secondary", String(theme));

  const alert = useAlert();
  const { driversRM } = useApi();
  const { refreshPage, switchTabs } = useUpdate();
  const {
    selectIdsToCreateRomaneios,
    clearIds,
    selectIdsToCreateRomaneiosAux,
    allDocsNotFinish,
    loadDemandsNotFinished
  } = useCard();

  const company = Cookies.get("company");

  // Fields of romaneios
  const [vehicle, setVehicle] = useState<string>(romaneio?.vehicle || "");
  const [userDocument, setUserDocument] = useState<string>(
    romaneio?.document || ""
  );
  const [note, setNote] = useState<string>(romaneio?.note || "");
  const [driver, setDriver] = useState<string>(romaneio?.driver || "");
  const [nameRomaneio, setNameRomaneios] = useState<string>(
    romaneio?.general_data || ""
  );
  const { userInfo } = useAuth();
  // controller open or close in popup
  const [open, setOpen] = useState(false);
  const closeModal = () => {
    setOpen(false);
    setWillGetDrivers(false);
  };
  const { unsubscribeGetWaybills } = usePagination()

  const [formartDriverToSelectRM, setFormartDriverToSelectRM] = useState<SelectDriverProps[]>([]);

  const driverFormat = {
    label: romaneio?.driver,
    value: romaneio?.document,
  };
  const [value, setValue] = useState<any>(
    driverFormat.label === undefined ? "" : driverFormat
  );
  const [cardToBeAdd, setCardToBeAdd] = useState<CardRasterProps[]>([]);
  const [willGetDrivers, setWillGetDrivers] = useState(false);

  var options: any = [];
  var optionsRM: any = [];
  const totalF = totalFinishDemands || 0;

  function handleSubmit(event: FormEvent) {
    event.preventDefault();

    if (driver === "") {
      alert.error("INFORME UM MOTORISTA!");
      return;
    }


    if (update) {
      handleUpdate();
    } else {
      for (let i = 0; i < selectIdsToCreateRomaneiosAux.length; i++) {
        if (selectIdsToCreateRomaneiosAux[i]?.history?.length > 0) {
          const createdAt = new Date();

          var aux = selectIdsToCreateRomaneiosAux[i].history;

          const formart = {
            nameRomaneio,
            createdAt,
            driver,
            document: userDocument,
            intoRomaneio: true,
          };

          aux.push(formart);

          UpdateConcatHistory(selectIdsToCreateRomaneiosAux[i].uid!, aux);
        } else {
          if (company)
            UpdateHistory(
              selectIdsToCreateRomaneiosAux[i],
              driver,
              userDocument,
              nameRomaneio
            );
        }
      }

      const data = {
        company,
        vehicle,
        document: userDocument,
        note,
        driver,
        finished_demands: 0,
        total_demands: selectIdsToCreateRomaneios.length,
        general_data: nameRomaneio,
        status: 0,
        numbers: selectIdsToCreateRomaneios.length,
        cardsRomaneios: selectIdsToCreateRomaneios,
        finishedAt: 0,
        intoRomaneio: true,
        companyGroupId: userInfo?.selectedCompanyGroupId
      };
      UseCreateRomaneio(data);

      for (let i = 0; i < driversRM.length; i++) {
        if (driversRM[i].document === userDocument) {
          AllowAcccessToDelivery(driversRM[i].uid, 1);
          break;
        }
      }

      hiddenDocumentoIntoRomaneios(selectIdsToCreateRomaneios, nameRomaneio);
      clearFields();
      alert.success("Romaneio salvo!");
      switchTabs(2);
    }
    refreshPage();
    setOpen(false);
    unsubscribeGetWaybills.current()
    if (firstTooltip) firstTooltip();
    if (handlePopup) handlePopup();
  }

  function clearFields() {
    clearIds();
    setVehicle("");
    setNote("");
    setUserDocument("");
    setDriver("");
    setNameRomaneios("");
    setCardToBeAdd([]);
    closeModal();
  }

  function handleUpdate() {
    const db = FirebaseConfig.firestore();
    if (romaneio !== undefined && dataRomaneiosCard) {
      var references: any = [];

      for (let i = 0; i < cardToBeAdd.length; i++) {
        const formart = db.doc("documents/" + cardToBeAdd[i].uid);
        references.push(formart);

        if (cardToBeAdd[i].history.length > 0) {
          const createdAt = new Date();

          var aux = cardToBeAdd[i].history;

          const formart = {
            nameRomaneio,
            createdAt,
            driver,
            userDocument,
          };

          aux.push(formart);

          UpdateConcatHistory(cardToBeAdd[i].uid!, aux);
        } else {
          if (company)
            UpdateHistory(cardToBeAdd[i], driver, userDocument, nameRomaneio);
        }
      }

      for (let i = 0; i < romaneio.cardsRomaneios.length; i++) {
        references.push(romaneio.cardsRomaneios[i]);
      }

      var valueStatus = null;
      if (romaneio.status === 3) {
        valueStatus = 0;
      } else {
        valueStatus = romaneio.status;
      }

      const uid = romaneio.uid;
      const data = {
        company,
        document: userDocument,
        status: valueStatus,
        note,
        vehicle,
        driver,
        finished_demands: totalF,
        total_demands: references.length,
        general_data: nameRomaneio,
        numbers: references.length,
        cardsRomaneios: references,
        finishedAt: 0,
        companyGroupId: userInfo?.selectedCompanyGroupId
      };
      if (uid !== undefined) {
        UpdateAllFields(data, uid);
        for (let i = 0; i < cardToBeAdd.length; i++) {
          UpdateCardsToIntoRomaneio(cardToBeAdd[i], nameRomaneio);
        }
      }
    }

    if (updateRomaneio) updateRomaneio();
    alert.success("Romaneio atualizado!");
  }

  function handleUpdateCardsIntoRomaneioAdd(
    card: CardRasterProps,
    addOrRemove: boolean
  ) {
    if (addOrRemove) {
      if (!cardToBeAdd.includes(card)) {
        setCardToBeAdd(prevState => [...prevState, card]);
      }
    } else {
      setCardToBeAdd(prevState => {
        const aux: any = []
        prevState.forEach(item => {
          if (item !== card) {
            aux.push(item);
          }
        })
        return aux
      });
    }
  }
  useEffect(() => {
    const unsubscribe = db
      .collection('documents')
      .where('company', '==', company)
      .where('status', '==', 1)
      .where('intoRomaneio', '==', false)
      .onSnapshot(snap => {
        const openDocuments = snap.docs.map(doc => ({
          uid: doc.id,
          ...doc.data()
        })) as any
        loadDemandsNotFinished(openDocuments)
      })
    return unsubscribe
  }, [])

  useEffect(() => {
    if (!willGetDrivers) return;
    async function loadWaybillsDrivers() {
      const waybillsSnapshot = await db.collection('waybills').where('company', '==', company).get();
      const waybillsByCompanyData = waybillsSnapshot.docs.map(doc => doc.data())

      for (let i = 0; i < driversRM.length; i++) {
        if (waybillsByCompanyData.find(element => element.driver === driversRM[i].name && element.status !== 4) === undefined
        ) {
          let formart = {
            label: driversRM[i].name,
            value: driversRM[i].document,
          };
          optionsRM.push(formart);

          if (driversRM[i].name === romaneio?.driver) {
            optionsRM.pop();
          }
        }
      }
      setFormartDriverToSelectRM(optionsRM);
    }
    loadWaybillsDrivers();


    return () => {
      setFormartDriverToSelectRM([]);
    };
  }, [willGetDrivers]);

  useEffect(() => {
    open ? document.body.style.overflow = 'hidden' : document.body.style.overflow = 'unset';
  }, [open]);

  return (
    <div>
      <StyledPopupRomaneio
        trigger={
          <div>
            <button
              onClick={() => {
                setOpen((o) => !o);
                if (handlePopup) handlePopup();
              }}
              id="buttonOpenPopup"
            >
              {Target}
            </button>
          </div>
        }
        open={open}
        closeOnDocumentClick={false}
        onOpen={() => setWillGetDrivers(true)}
        onClose={closeModal}
        modal
        position="top center"
      >
        <div className="popupCreateRomaneio">
          <div className="headerRomaneio">
            <span>{title}</span>
            <AiOutlineCloseCircle
              color="white"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setOpen(false);
                setVehicle("");
                setNote("");
                setUserDocument("");
                setDriver("");
                setNameRomaneios("");
                if (handlePopup) handlePopup();
                if (firstTooltip) firstTooltip();
              }}
              size={22}
            />
          </div>
          <form onSubmit={handleSubmit} className={update ? "formClass" : ""}>
            <legend>Vínculo do motorista e veículo</legend>
            <div className="input-formRomaneio">
              <div className="blockInputRomaneio">
                <label>Motorista</label>
                <Select
                  value={value}
                  maxMenuHeight={update ? 140 : 190}
                  isSearchable={true}
                  placeholder={"Selecione..."}
                  options={formartDriverToSelectRM}
                  onChange={(selectedOption: any) => {
                    setDriver(selectedOption.label);
                    setValue(selectedOption);
                    setUserDocument(selectedOption?.value);
                    const date = new Date();
                    const nameDocument =
                      selectedOption?.value.substr(0, 3) +
                      date.getDate() +
                      String(date.getMonth() + 1).padStart(2, "0") +
                      date.getHours() +
                      date.getMinutes();
                    setNameRomaneios(nameDocument);
                  }}
                />
              </div>

              <div className="blockInputRomaneio">
                <label>CPF</label>
                <label id="CPFlabel">
                  {userDocument.length === 0 ? "Informe o cpf" : userDocument}
                </label>
              </div>

              <div className="blockInputRomaneio">
                <label>Documento</label>
                <label id="CPFlabel">
                  {nameRomaneio.length === 0 ? "código..." : nameRomaneio}
                </label>
              </div>

              <div className="blockInputRomaneio">
                <label>Veículo</label>
                <input
                  value={vehicle}
                  type="text"
                  maxLength={20}
                  placeholder="Informe o veículo"
                  onChange={(event) => setVehicle(event.target.value)}
                />
              </div>
            </div>

            <div className="input-formRomaneio">
              <span>Observação</span>
              <textarea
                placeholder="informações adicionais"
                onChange={(event) => setNote(event.target.value)}
                value={note}
                maxLength={500}
              ></textarea>
            </div>
            {dataRomaneiosCard !== undefined && (
              <div className="allCards">
                <div className="gridIntoRomaneios">
                  <legend>
                    Documentos do romaneio {romaneio?.general_data}{" "}
                  </legend>
                  <div className="cards">
                    {dataRomaneiosCard.map((item: CardRasterProps) => (
                      <div key={item.id}>
                        <CardRaster
                          key={item.id}
                          checkBox={true}
                          isIntoRomaneio={true}
                          card={item}
                        />
                      </div>
                    ))}
                  </div>
                </div>
                <div className="gridIntoDocuments" style={{ display: romaneio?.status === 4 ? 'none' : 'flex' }} >
                  <legend>Todos documentos: </legend>
                  <div className="cards">
                    {allDocsNotFinish.map((item: CardRasterProps) => (
                      <div key={item.uid}>
                        <CardRaster
                          key={item.uid}
                          checkBox={true}
                          card={item}
                          handleUpdateCardsIntoRomaneio={
                            handleUpdateCardsIntoRomaneioAdd
                          }
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}

            <div className="button-form" style={{ display: romaneio?.status === 4 ? 'none' : 'flex' }}>
              <button
                type="button"
                onClick={() => {
                  setOpen(false);
                  setVehicle("");
                  setNote("");
                  setUserDocument("");
                  setDriver("");
                  setNameRomaneios("");
                  closeModal();
                  if (handlePopup) handlePopup();
                  if (firstTooltip) firstTooltip();
                }}
              >
                Cancelar
              </button>
              <button type="submit">{update ? "Atualizar" : "Salvar"} </button>
            </div>
          </form>
        </div>
      </StyledPopupRomaneio>
    </div>
  );
}
