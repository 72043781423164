// eslint-disable-next-line
import { useEffect, useState, memo } from 'react';

import { useUpdate } from 'ctx/UpdateContext';
import { useApi } from 'ctx/ApiContext';

import { SectionRomaneiosProps } from 'types/InterfaceRomaneio';
import Romaneios from 'components/Romaneios';

import filter from "assets/icons/filter.svg";
import checkbox from 'assets/icons/checkTrue.svg';
import { FiChevronLeft, FiChevronRight, FiChevronsLeft, FiChevronsRight } from "react-icons/fi";
import { Select } from 'antd';

// styles
import './index.scss';
import { usePagination } from 'ctx/PaginationContext';
import useGetRomaneios from 'hooks/useGetRomaneios';
import { useAuth } from 'ctx/AuthContext';

const MainRomaneioComponent = () => {
  const { dataRomaneios, driversRM } = useApi();
  const { pagination, setPagination, lastVisibleDoc, setLastVisibleDoc, unsubscribeGetWaybills, setStatus } = usePagination();
  const [param, setParam] = useState<number>(0);
  const [allDrivers, setAllDrivers] = useState<any>([]);
  const [selectDriverOption, setSelectDriverOption] = useState<string | null>(null);
  const { userInfo } = useAuth();

  const waybills = useGetRomaneios();

  useEffect(() => {

    const drivers = driversRM.map((driver: any) => ({
      value: driver.document,
      label: driver.name,
    })).sort((a: any, b: any) => a.label < b.label ? -1 : 1);

    setAllDrivers([{
      label: 'Todos',
      value: null,
    }, ...drivers])
  }, [driversRM])

  useEffect(() => {
    console.log('SectionROmaneio Change');

    if (param === -1) {
      // const aux = dataRomaneios;
      // setData([...aux]);
      setStatus(param)
      unsubscribeGetWaybills.current()
      unsubscribeGetWaybills.current = waybills.getWaybills(pagination, setLastVisibleDoc, param, selectDriverOption)
      return;
    }
    if (param === 0) {
      // const aux = dataRomaneios.filter((romaneio: any) => romaneio.status === 0);
      // setData([...aux]);
      setStatus(param)
      unsubscribeGetWaybills.current()
      unsubscribeGetWaybills.current = waybills.getWaybills(pagination, setLastVisibleDoc, param, selectDriverOption)
      return;
    }
    if (param === 1) {
      // const aux = dataRomaneios.filter((romaneio: any) => romaneio.status === 1);
      // setData([...aux]);
      setStatus(param)
      unsubscribeGetWaybills.current()
      unsubscribeGetWaybills.current = waybills.getWaybills(pagination, setLastVisibleDoc, param, selectDriverOption)
      return;
    }
    if (param === 2) {
      // const aux = dataRomaneios.filter((romaneio: any) => romaneio.status === 2);
      // setData([...aux]);
      setStatus(param)
      unsubscribeGetWaybills.current()
      unsubscribeGetWaybills.current = waybills.getWaybills(pagination, setLastVisibleDoc, param, selectDriverOption)
      return;
    }
    if (param === 3) {
      // const aux = dataRomaneios.filter((romaneio: any) => romaneio.status === 3);
      // setData([...aux]);
      setStatus(param)
      unsubscribeGetWaybills.current()
      unsubscribeGetWaybills.current = waybills.getWaybills(pagination, setLastVisibleDoc, param, selectDriverOption)
      return;
    }
    if (param === 4) {
      // setFinalizedRomaneios([...dataRomaneios.filter((romaneio: any) => romaneio.status === 4)]);
      // const aux = dataRomaneios.filter((romaneio: any) => romaneio.status === 4);
      // setData([...aux]);
      setStatus(param)
      unsubscribeGetWaybills.current()
      unsubscribeGetWaybills.current = waybills.getWaybills(pagination, setLastVisibleDoc, param, selectDriverOption)
      return;
    }
    return unsubscribeGetWaybills.current;
  }, [param, userInfo?.selectedCompanyGroupId]);

  // useEffect(() => {
  //   return unsubscribeGetWaybills.current
  // }, [unsubscribeGetWaybills.current])


  function fetchNextPage() {
    unsubscribeGetWaybills.current();
    unsubscribeGetWaybills.current = waybills.getNextPageWaybills(pagination, setLastVisibleDoc, lastVisibleDoc, param, selectDriverOption);
  }
  function fetchPreviousPage() {
    unsubscribeGetWaybills.current();
    unsubscribeGetWaybills.current = waybills.getPreviousPageWaybills(pagination, setLastVisibleDoc, param, selectDriverOption);
  }

  function handleSelectDriver(driver: string) {
    setSelectDriverOption(driver);
    unsubscribeGetWaybills.current()
    unsubscribeGetWaybills.current = waybills.getWaybills(pagination, setLastVisibleDoc, param, driver)
  }


  return (
    <div className="container-romaneios">
      <div className='filter-container'>
        <div className="block-select-driver">
          <label className="filter">
            <img src={filter} alt="search" />
            <span>Filtrar por motorista:</span>
          </label>
          <div className="select-driver">
            <Select
              showSearch
              size='large'
              defaultValue="Todos"
              style={{ width: 250, borderRadius: '8px' }}
              onChange={handleSelectDriver}
              filterOption={(input: any, option: any) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={allDrivers}
            />
          </div>
        </div>
        <div className="filter-box">
          <div className="block-checkbox">
            <div className="input-checkbox">
              <input type="checkbox" onClick={() => {
                setParam(-1);
              }} />
              <label>Ver todos</label>
              {param === -1 && (
                <img src={checkbox} alt="checkbox true" />
              )}
            </div>
            <div className="input-checkbox">
              <input type="checkbox" onClick={() => {
                setParam(0);
              }} />
              <label>Aguardando</label>
              {param === 0 && (
                <img src={checkbox} alt="checkbox true" />
              )}
            </div>
            <div className="input-checkbox">
              <input type="checkbox" onClick={() => {
                setParam(1);
              }} />
              <label>Em trânsito</label>
              {param === 1 && (
                <img src={checkbox} alt="checkbox true" />
              )}
            </div>
            <div className="input-checkbox">
              <input type="checkbox" onClick={() => {
                setParam(2);
              }} />
              <label>Ocorrência</label>
              {param === 2 && (
                <img src={checkbox} alt="checkbox true" />
              )}
            </div>
            <div className="input-checkbox">
              <input type="checkbox" onClick={() => {
                setParam(3);
              }} />
              <label>A finalizar</label>
              {param === 3 && (
                <img src={checkbox} alt="checkbox true" />
              )}
            </div>
            <div className="input-checkbox">
              <input type="checkbox" onClick={() => {
                setParam(4);
              }} />
              <label>Finalizados</label>
              {param === 4 && (
                <img src={checkbox} alt="checkbox true" />
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="titles" style={{ paddingBottom: 25 }}>
        <div className="blockTitles">
          <span>Dados Gerais</span>
          <span>Motorista</span>
        </div>
        <div className="blockTitles">
          <span style={{ paddingRight: "50px" }}></span>
        </div>
        <div className="blockTitles">
          <span>Status</span>
        </div>
        <div className="blockTitles">
          <span>Finalizadas / total de demandas</span>
        </div>
      </div>

      {dataRomaneios.map((r: SectionRomaneiosProps) => {
        return (
          <Romaneios
            key={r.uid}
            romaneio={r}
            reference={r.cardsRomaneios}
            setParam={setParam}
          />
        )
      })}
      <div className="paginationContainer">
        <button onClick={fetchPreviousPage}><FiChevronLeft /></button>
        <button onClick={fetchNextPage}><FiChevronRight /></button>
        <select
          name="pagination"
          onChange={(event) => {
            setPagination(Number(event.target.value))
          }}
          defaultValue={pagination}
        >
          <option value="30">30</option>
          <option value="50">50</option>
        </select>
      </div>
    </div>
  )
}

export const MainRomaneio = memo(MainRomaneioComponent);