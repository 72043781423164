import { storage } from "../database/FirebaseConfig";

async function UseGetXMLFile(path: string, name: string, setUrlFile: any) {
  const newName = name + ".xml";
  return new Promise((resolve, reject) => {
    storage
      .ref(`${path}`)
      .child(newName)
      .getDownloadURL()
      .then((url) => {
        setUrlFile(url);
        resolve(url);
      })
      .catch((error) => {
        console.error(error);
      });
  });
}
export { UseGetXMLFile };
