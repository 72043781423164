import { AiOutlineCloseCircle } from 'react-icons/ai'
import { FormEvent, useContext, useState } from 'react';
import { useAlert } from 'react-alert';
import Cookies from 'js-cookie';

// components

// styles
import './index.scss';
import { createFinalization } from 'hooks/createFinalization';
import { Finalizations } from 'components/Finalizations';
import { AuthContext } from 'ctx/AuthContext';

type FinalizationProps = {
    Target?: any;
    closeModal: any;
}

export function CreateFinalization({ closeModal }: FinalizationProps) {
    const alert = useAlert();

    const [finish, setFinish] = useState(false);
    const [images, setImages] = useState(false);

    const [description, setDescription] = useState('');
    const [nameFinalization, setNameFInalization] = useState('');
    const { userInfo } = useContext(AuthContext);


    const theme = Cookies.get("colorTheme");
    document.documentElement.style.setProperty('--secondary', String(theme));

    function handleSubmit(event: FormEvent) {
        event.preventDefault();
        if (!nameFinalization || nameFinalization === '') {
            alert.error('Insira o nome da finalização!');
            return;
        }
        const data = {
            name: nameFinalization,
            description,
        }

        createFinalization(data, userInfo!.company);
        setNameFInalization('');
        setDescription('');
        setFinish(false);
        alert.success('Finalização salva!');
    }
    return (
        <div className="finalization" >
            <div className="header-finalization">
                <span>Cadastrar finalização </span>
                <AiOutlineCloseCircle onClick={() => closeModal()} size={22} style={{ cursor: 'pointer' }} />
            </div>
            <form className="form-finalization" onSubmit={handleSubmit}>
                <div className="nameOccurence">
                    <span>Nome</span>
                    <input
                        value={nameFinalization}
                        required
                        type="text"
                        maxLength={20}
                        placeholder="Informe o nome da foto de finalização"
                        onChange={event => setNameFInalization(event.target.value)}
                    />
                </div>
                <div className="block-finalization">
                    <span>Descrição (Opcional)</span>
                    <textarea placeholder="informações adicionais"
                        onChange={event => setDescription(event.target.value)}
                        value={description}
                        maxLength={100}
                    >
                    </textarea>
                </div>
                <div className="button-form">
                    <button type="button" onClick={() => closeModal()}>Cancelar</button>
                    <button type="submit">Salvar</button>
                </div>
            </form>
            <div className="allOccurence">
                <Finalizations closeModal={closeModal} />
            </div>
        </div>
    )
}