
import firebase from '../database/FirebaseConfig';

const db = firebase.firestore();

type OccurrenteProps = {
    finish: boolean;
    images: boolean;
    info: string;
    name: string;
}

function UpdateAllFieldsOccurence(occurrence: OccurrenteProps, uid: string) {

    db.collection('occurrences').doc(uid).update(occurrence).then(() => {
        console.log('Profile Successfully Edited!');
    }).catch((error: any) => {
        console.log('Error updating the document:', error);
    });
}

export { UpdateAllFieldsOccurence };