import { AiOutlineCloseCircle } from 'react-icons/ai'
import { FormEvent, useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import Cookies from 'js-cookie';

// components
import { default as UseCreateOcurrence } from 'hooks/useCreateOcurrence';
import { Occurences } from '../Occurences';

import checkbox from 'assets/icons/checkboxBlue.svg';

// styles
import './index.scss';
import { useAuth } from 'ctx/AuthContext';
import { db } from 'database/FirebaseConfig';
import { phone, removeMask } from 'utils/MaskPhone';

type OccurrenteProps = {
    Target?: any;
    closeModal: any;
}

export function Configurations({ closeModal }: OccurrenteProps) {
    const alert = useAlert();

    const { companieInfo } = useAuth();
    const [stubPhoto, setStubPhoto] = useState<boolean>(companieInfo?.finalizationPhotos?.stub ?? false);
    const [clientPhoto, setClientPhoto] = useState<boolean>(companieInfo?.finalizationPhotos?.client ?? false);
    const [packagePhoto, setPackagePhoto] = useState<boolean>(companieInfo?.finalizationPhotos?.package ?? false);
    const [whatsAppCompanyNumber, setWhatsAppCompanyNumber] = useState(companieInfo.whatsappNumber ?? '');


    const theme = Cookies.get("colorTheme");
    document.documentElement.style.setProperty('--secondary', String(theme));
    const company = Cookies.get("company");

    async function updateFinalizationPhotoOnCompany() {
        try {
            await db.collection('companies').doc(company).update({
                'finalizationPhotos': {
                    stub: stubPhoto,
                    client: clientPhoto,
                    package: packagePhoto,
                },
            })
        } catch (error) {
            console.log(error);
        }
    }

    async function updateCompanyWhatssAppNumber() {
        const wppNumberWIthouMask = removeMask(whatsAppCompanyNumber);
        try {
            await db.collection('companies').doc(company).update({
                'whatsappNumber': wppNumberWIthouMask,
            })
            companieInfo.whatsappNumber = whatsAppCompanyNumber;
        } catch (error) {
            console.log(error);
        }
    }

    function handleSubmit(event: FormEvent) {
        event.preventDefault();
        const numberLength = removeMask(whatsAppCompanyNumber).length;
        if (numberLength < 11 && numberLength !== 0) {
            alert.error('Número Inválido!');
            return;
        } else {
            updateFinalizationPhotoOnCompany();
            updateCompanyWhatssAppNumber();
            alert.success('Configurações salvas!');
            closeModal();
        }
    }
    return (
        <div className="occurrence" >
            <div className="header-occurrence">
                <span>Configurações</span>
                <AiOutlineCloseCircle onClick={() => closeModal()} size={22} style={{ cursor: 'pointer' }} />
            </div>
            <form className="form-occurrence" onSubmit={handleSubmit}>
                <div className="block-occurrence">
                    <span>Fotos obrigatórias para finalização de demandas</span>
                </div>
                <div className="checkbox-occurrence">
                    <label className={stubPhoto ? 'label-active' : ''}>
                        <input type="checkbox" onClick={() => {
                            setStubPhoto(prevState => !prevState);
                            companieInfo.finalizationPhotos.stub = !stubPhoto;
                        }} />
                        Foto de canhoto
                        {stubPhoto === true && (
                            <img src={checkbox} alt="checkbox true" onClick={() => {
                            }} />
                        )}
                    </label>
                    <label className={clientPhoto ? 'label-active' : ''}>
                        <input type="checkbox" onClick={() => {
                            setClientPhoto(prevState => !prevState);
                            companieInfo.finalizationPhotos.client = !clientPhoto;
                        }} />
                        Foto do cliente
                        {clientPhoto === true && (
                            <img src={checkbox} alt="checkbox true" onClick={() => {
                            }} />
                        )}
                    </label>
                    <label className={packagePhoto ? 'label-active' : ''}>
                        <input type="checkbox" onClick={() => {
                            setPackagePhoto(prevState => !prevState);
                            companieInfo.finalizationPhotos.package = !packagePhoto;
                        }} />
                        Foto da mercadoria
                        {packagePhoto === true && (
                            <img src={checkbox} alt="checkbox true" onClick={() => {
                            }} />
                        )}
                    </label>
                </div>
                <div className='wppPhoneInputContainer'>
                    <span>WhatsApp da empresa</span>
                    <input type="text" placeholder='(XX) XXXXX-XXXX' value={phone(whatsAppCompanyNumber)} onChange={(e) => setWhatsAppCompanyNumber(e.target.value)} />
                </div>

                <div className="button-form">
                    <button type="button" onClick={() => closeModal()}>Cancelar</button>
                    <button type="submit">Salvar</button>
                </div>
            </form>
        </div>
    )
}