export function unflatObject(objectToUnflat: any) {
  "use strict";
  if (Object(objectToUnflat) !== objectToUnflat || Array.isArray(objectToUnflat))
    return objectToUnflat;
  var regex = /\.?([^.\[\]]+)|\[(\d+)\]/g,
    resultholder = {} as any;
  for (var p in objectToUnflat) {
    var cur = resultholder,
      prop = "",
      m;
    while (m = regex.exec(p)) {
      cur = cur[prop] || (cur[prop] = (m[2] ? [] : {}));
      prop = m[2] || m[1];
    }
    cur[prop] = objectToUnflat[p];
  }
  return resultholder[""] || resultholder;
};