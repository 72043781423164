import React, { useContext, useEffect, useState } from 'react';
import { Select } from 'antd';
import { AuthContext, useAuth } from 'ctx/AuthContext';
import { db } from 'database/FirebaseConfig';
import Cookies from 'js-cookie';

export function SelectCompanyGroup() {
  const [companies, setCompanies] = useState<any>([]);
  const [selectedCompany, setSelectedCompany] = useState<any>();
  const company = Cookies.get("company");
  const { userInfo, loadInfoUser } = useAuth();

  function handleSelectCompany(companyId: string) {
    const companySelected = companies.find((company: any) => company.id === companyId);
    setSelectedCompany(companySelected);
  }

  const handleChange = async (selectedCompanyGroupId: string) => {
    handleSelectCompany(selectedCompanyGroupId)
    await db.collection('users').doc(userInfo?.uid).update({
      selectedCompanyGroupId
    })
    if (userInfo) loadInfoUser({
      ...userInfo,
      selectedCompanyGroupId
    })
  };

  useEffect(() => {
    async function loadCompanyInfo() {
      if (userInfo?.selectedCompanyGroupId && companies.length > 0) {
        handleSelectCompany(userInfo.selectedCompanyGroupId);
      } else {
        // const snapshot = await db.collection('companies').doc(company).get();
        const data = {
          // id: snapshot.id,
          // ...snapshot.data(),
          label: `Sem vínculo`,
          value: null,
        }
        setSelectedCompany(data)
      }
    }
    loadCompanyInfo();
  }, [userInfo, companies])

  useEffect(() => {
    const unsubscribe = db.collection('companyGroups').where('companyId', '==', company).onSnapshot(snap => {
      const companiesData = snap.docs.map(doc => ({
        ...doc.data(),
        id: doc.id,
        label: doc.data().name,
        value: doc.id,
      }) as any).filter(company => company.users[userInfo!.uid])
      setCompanies(companiesData)
    })

    return unsubscribe;
  }, [])

  return (
    <div className="companyGroupContainer">
      <Select
        value={selectedCompany}
        onChange={handleChange}
        style={{ width: 200 }}
        options={companies}
      />
    </div>
  );
}