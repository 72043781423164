// styles
import { InputDocumentViewProps } from 'components/InputDocumentView';
import './index.scss';

export function InputFormsView({ title, img, select }: InputDocumentViewProps) {

    var stylesClass = '';

    if (select) {
        stylesClass = 'btnForms btnFormsSelect'
    } else {
        stylesClass = 'btnForms'

    }

    return (
        <div className={stylesClass}>
            <img src={img} alt='' />
            <span>{title}</span>
        </div>
    )

}