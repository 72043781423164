// eslint-disable-next-line
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { AiOutlineCloseCircle } from "react-icons/ai";
import Cookies from "js-cookie";
import styled from "styled-components";
import Popup from "reactjs-popup";
import firebase from "../../database/FirebaseConfig";

// components
import { ManagerAccounts } from "components/ManagerAccounts";
import { Occurrence } from "../CreateOccurence";

// ctx
import { useAuth } from "ctx/AuthContext";
import { useUpdate } from "ctx/UpdateContext";
import { useCard } from "ctx/CardRasterContext";

// hooks
import useGetTheme from "hooks/useGetTheme";

// assets
// import checkbox from 'assets/icons/checkboxBlue.svg';
import dropSelect from "assets/icons/dropmenu.svg";

// styles
import "./index.scss";
import "react-color-palette/lib/css/styles.css";
import { Configurations } from "components/Configurations";
import { Colors, Images } from "utils/template";
import { CreateFinalization } from "components/CreateFinalization";
import { CompanyGroups } from "components/CompanyGroups/indext";
import { ChatNotification } from "./ChatNotification";
import { Notification } from "./Notification";
import { SelectCompanyGroup } from "./SelectCompanyGroup";

export type OccurrenteProps = {
  finish: boolean;
  images: boolean;
  info: string;
  name: string;
  uid: string;
};

const StyledPopup = styled(Popup)`
  &-content {
    padding: 0px;
    border: none;
    border-radius: 18px;
    width: 1200px;

    @media (max-width: 560px) {
      width: 75%;
    }
  }
`;
const PopupThree = styled(Popup)`
  &-content {
    width: 30px;
    padding: 0px;
    border: none;
    border-radius: 18px;
  }
`;

export function Header() {
  // Controller open or close area of custom page
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [isOpenMenu, setIsOpenMenu] = useState<boolean>(false);
  const closeModal = () => {
    setIsOpen(false);
    setIsOpenMenu(false);
  };
  const [isOpenUsers, setIsOpenUsers] = useState<boolean>(false);
  const closeModalUsers = () => setIsOpenUsers(false);

  const [isOpenOccurences, setIsOpenOccurences] = useState<boolean>(false);
  const [isOpenFinalization, setIsOpenFinalization] = useState<boolean>(false);
  const [isOpenCompanyGroups, setIsOpenCompanyGroups] = useState<boolean>(false);
  const [isOpenConfigurations, setIsOpenConfigurations] = useState<boolean>(false);
  const closeModalCompanyGroups = () => setIsOpenCompanyGroups(false);
  const closeModalOccurence = () => setIsOpenOccurences(false);
  const closeModalFinalization = () => setIsOpenFinalization(false);
  const closeModalConfigurations = () => setIsOpenConfigurations(false);

  const [openInfoUser, setOpenInfoUser] = useState<boolean>(false);
  const closeModalInfoUser = () => setOpenInfoUser(false);

  const { update } = useUpdate();

  const { loadInfoUser, setStateCurrentUser, companieInfo, userInfo } =
    useAuth();

  const logo = useRef<any>(null);
  const { clearIds } = useCard();
  const theme = useGetTheme();
  const themeHex = Cookies.get("colorTheme");
  document.documentElement.style.setProperty("--secondary", String(themeHex));
  const name = Cookies.get("name");
  const company = Cookies.get("company");

  useEffect(() => {
    if (company !== undefined) {
      theme.getTheme({
        company,
        onComplete: (theme: any) => {
          if (theme === undefined) {
            Cookies.set("colorTheme", Colors.secondary);
            Cookies.set(
              "logo",
              Images.logo
            );
            Cookies.set(
              "background",
              Images.background,
            );
            logo.current = undefined;
            document.documentElement.style.setProperty(
              "--secondary",
              "--secondary"
            );
          } else {
            Cookies.set("background", theme.background);
            Cookies.set("colorTheme", theme.color);
            Cookies.set("logo", theme.logo);
            logo.current = theme.logo;
            document.documentElement.style.setProperty("--secondary", theme);
          }
        },
      });
    }

    return () => {
      theme.setLoading(false);
    };
  }, [update]);


  return (
    <div className="headerPage">
      <div className="firstBlock">
        <img
          src={
            logo.current ||
            Images.logo
          }
          alt="Logo of brand"
        />
      </div>


      <div className="secondBlock">
        <div className="Notification tooltipBoundaryNotification">
          <SelectCompanyGroup />
        </div>
        <div className="Notification tooltipBoundaryNotification">
          <ChatNotification />
        </div>
        <div className="Notification tooltipBoundaryNotification">
          <Notification />
        </div>

        <div className="menuRight tooltipBoundary">
          <span className="circle">
            {name !== undefined ? name.substr(0, 2).toLocaleUpperCase() : ""}
          </span>
          <PopupThree
            trigger={
              <div>
                <button
                  type="button"
                  className="button"
                  id="buttonOpenPopup"
                  onClick={() => setIsOpenMenu(true)}
                >
                  <img src={dropSelect} alt="imagem select menu" />
                </button>
              </div>
            }
            className="mypopupHeader"
            onClose={closeModal}
            open={isOpenMenu}
            arrow={false}
            closeOnDocumentClick={!(isOpenUsers || isOpenOccurences || isOpenConfigurations || isOpenFinalization || isOpenCompanyGroups)}
            position={"bottom right"}
            lockScroll
          >
            <div className="content-menu-topRight">
              <AiOutlineCloseCircle
                size={14}
                style={{
                  position: "absolute",
                  right: "4px",
                  top: "4px",
                  cursor: "pointer",
                  marginBottom: 10,
                }}
                onClick={closeModal}
              />
              {!!userInfo?.role &&
                (
                  <>
                    <div className="blockRightMenu">
                      <p>{name}</p>
                      <ManagerAccounts
                        // users={companieInfo.users}
                        item={companieInfo}
                        closeModalUsersFromHeader={closeModalUsers}
                        themeHex={themeHex}
                      >
                        <span
                          style={{ textAlign: "start" }}
                          onClick={() => {
                            setIsOpenUsers((o) => !o);
                          }}
                        >
                          Usuários
                        </span>
                      </ManagerAccounts>
                    </div>

                    <div className="blockRightMenu">
                      <StyledPopup
                        trigger={
                          <div>
                            <button
                              type="button"
                              className="button"
                              id="buttonOpenPopup"
                              onClick={() => {
                                setIsOpenCompanyGroups(true);
                              }}
                            >
                              <span>Empresas</span>
                            </button>
                          </div>
                        }
                        lockScroll
                        className="mypopupHeader"
                        open={isOpenCompanyGroups}
                        onClose={closeModalCompanyGroups}
                        modal
                        closeOnDocumentClick={false}
                      >
                        <CompanyGroups closeModal={closeModalCompanyGroups} />
                      </StyledPopup>
                    </div>

                    <div className="blockRightMenu">
                      <StyledPopup
                        trigger={
                          <div>
                            <button
                              type="button"
                              className="button"
                              id="buttonOpenPopup"
                              onClick={() => {
                                setIsOpenOccurences((o) => !o);
                              }}
                            >
                              <span>Ocorrências</span>
                            </button>
                          </div>
                        }
                        lockScroll
                        className="mypopupHeader"
                        open={isOpenOccurences}
                        modal
                        onClose={closeModalOccurence}
                        closeOnDocumentClick={false}
                      >
                        <Occurrence closeModal={closeModalOccurence} />
                      </StyledPopup>
                    </div>

                    <div className="blockRightMenu">
                      <StyledPopup
                        trigger={
                          <div>
                            <button
                              type="button"
                              className="button"
                              id="buttonOpenPopup"
                              onClick={() => {
                                setIsOpenFinalization(true);
                              }}
                            >
                              <span>Finalizações</span>
                            </button>
                          </div>
                        }
                        lockScroll
                        className="mypopupHeader"
                        open={isOpenFinalization}
                        onClose={closeModalFinalization}
                        modal
                        closeOnDocumentClick={false}
                      >
                        <CreateFinalization closeModal={closeModalFinalization} />
                      </StyledPopup>
                    </div>

                    <div className="blockRightMenu">
                      <StyledPopup
                        trigger={
                          <div>
                            <button
                              type="button"
                              className="button"
                              id="buttonOpenPopup"
                              onClick={() => {
                                setIsOpenConfigurations((o) => !o);
                              }}
                            >
                              <span>Configurações</span>
                            </button>
                          </div>
                        }
                        lockScroll
                        className="mypopupHeader"
                        open={isOpenConfigurations}
                        onClose={closeModalConfigurations}
                        modal
                        closeOnDocumentClick={false}
                      >
                        <Configurations closeModal={closeModalConfigurations} />
                      </StyledPopup>
                    </div>
                  </>
                )}
              <div className="blockRightMenu">
                <Link
                  to="/"
                  onClick={() => {
                    clearIds();
                    loadInfoUser(null);
                    setStateCurrentUser();
                    firebase.auth().signOut();
                    Cookies.remove("name");
                    Cookies.remove("email");
                    Cookies.remove("document");
                    Cookies.remove("company");
                    localStorage.removeItem("companieInfo");
                    localStorage.removeItem("userInfo");
                    sessionStorage.removeItem("user-painel");
                    sessionStorage.removeItem('state-user');
                    sessionStorage.removeItem('stateUser');
                    sessionStorage.removeItem('userInfo');
                    setStateCurrentUser();
                    Cookies.set("currentUser", "");
                    Cookies.set("name", "");
                  }}
                >
                  Sair
                </Link>
              </div>
            </div>
          </PopupThree>
        </div>
      </div>
    </div>
  );
}








