import { MessageOutlined } from "@ant-design/icons";
import { InfoCards } from "components/InfoCards";
import { LastMessagesI, useApi } from "ctx/ApiContext";
import { db } from "database/FirebaseConfig";
import firebase from "firebase";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { GiPlainCircle } from "react-icons/gi";
import { secondsToTime } from "utils/timestampToDate";
import { StyledPopupNotification } from "./Notification";
import { useAuth } from "ctx/AuthContext";
import { Button, Drawer, List, Select, Space } from "antd";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";

export const ChatNotification = () => {
  const [isOpenMenu, setIsOpenMenu] = useState<boolean>(false);
  const closeModal = () => setIsOpenMenu(false);
  const { userInfo } = useAuth();
  const { driversRM } = useApi();
  const selectCompanyGroupId = userInfo?.selectedCompanyGroupId;
  const [hasNewMessage, setHasNewMessage] = useState(false);
  let unsubscribe: () => void | null;

  const [isOpenNotification, setIsOpenNotification] = useState<boolean>(false);
  const closeModalNotificationItem = () => {
    closeModal();
    setIsOpenMenu(false);
  };
  const { allLastMessages, fetchNextPageMessages, fetchPreviousPageMessages } = useApi();
  const [allMessages, setAllMessages] = useState(allLastMessages);
  const [selectedDriverToFilter, setSelectedDriverToFilter] = useState('all');



  const drivers = driversRM.map((driver: any) => ({
    value: driver.uid,
    label: driver.name
  }))

  useEffect(() => {
    setAllMessages(allLastMessages.filter(message => {
      if (selectedDriverToFilter === 'all') return true
      return message?.lastRead[selectedDriverToFilter]
    }));
  }, [allLastMessages, selectedDriverToFilter])

  useEffect(() => {
    if (!selectCompanyGroupId) return;
    if (unsubscribe) unsubscribe();
    unsubscribe = db.collection('companyGroups').doc(selectCompanyGroupId).onSnapshot(snapshot => {
      const chat = snapshot.data()?.chat;

      if (chat === undefined) {
        setHasNewMessage(false);
        return
      };

      if (chat?.lastRead?.seconds < chat?.lastReceived?.seconds) {
        setHasNewMessage(true);
      } else {
        setHasNewMessage(false);
      };
    })
    return () => {
      unsubscribe();
    }
  }, [selectCompanyGroupId]);

  async function updateChatOnCompany() {
    const timestamp = firebase.firestore.Timestamp.fromDate(new Date());
    try {
      await db.collection('companyGroups').doc(selectCompanyGroupId).set({
        chat: {
          lastRead: timestamp
        }
      }, { merge: true })
      setHasNewMessage(false);
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      {allMessages.length === 0 ? (
        <div>
          <button
            type="button"
            className="button"
            id="buttonOpenPopup"
          >
            <MessageOutlined className="messageIcon" />
            <span className=""></span>
          </button>
        </div>
      ) : (
        <div >
          <button
            type="button"
            className="button"
            id="buttonOpenPopup"
            onClick={() => setIsOpenMenu((o) => !o)}
          >
            <MessageOutlined className="messageIcon" />
            {hasNewMessage ? (
              <span className="Alert"></span>
            ) : (
              <span className=""></span>
            )}
          </button>
        </div >


      )}
      <Drawer
        title="Mensagens"
        placement={'right'}
        width={500}
        onClose={() => setIsOpenMenu(false)}
        visible={isOpenMenu}
        extra={
          <Space>
            <Select
              showSearch
              style={{ width: 200 }}
              options={[{ value: 'all', label: 'Todos' }, ...drivers]}
              onChange={(value) => setSelectedDriverToFilter(value)}
              filterOption={(input: any, option: any) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
            />
          </Space>
        }
      >
        <List
          itemLayout="horizontal"
          size="large"
          dataSource={allMessages}
          style={{ overflow: 'auto', height: '91%' }}
          renderItem={(message) =>
            <div className="chatAlertPopup">
              <LastMessageNotification
                key={message.id}
                item={message}
                setIsOpenNotification={setIsOpenNotification}
                closeChatNotificationModal={() => setIsOpenMenu(false)}
              />
            </div>
          }
        />
        <div style={{
          display: "flex",
          gap: 10,
          padding: "20px",
          borderTop: '1px solid #ebebeb',
          position: 'absolute',
          width: '100%',

        }}
          className="paginationContainer"
        >
          <button onClick={fetchPreviousPageMessages}><FiChevronLeft /></button>
          <button onClick={fetchNextPageMessages}><FiChevronRight /></button>
        </div>

      </Drawer>
    </>
  );
};

function LastMessageNotification({
  item,
  index,
  setIsOpenNotification,
  closeChatNotificationModal,
}: any) {
  const [oneSelectPopup, setOneSelectPopup] = useState(false);
  const [doc, setDoc] = useState<any>()
  const company = Cookies.get("company");
  const [messageReaded, setMessagedReaded] = useState(false);
  const messageTime = secondsToTime(item.createdAt);

  useEffect(() => {
    const unsubscribeChat = db.collection('chats').doc(item.docId).onSnapshot(snapshot => {
      if (snapshot.data()?.lastRead === undefined) return;
      const chatLastRead = snapshot.data()!.lastRead[company!];
      const messageCreated = item?.createdAt
      const messageWasReaded = chatLastRead?.seconds >= messageCreated?.seconds;
      setMessagedReaded(messageWasReaded);
    })
    const unsubscribeDoc = db.collection('documents').doc(item.docId).onSnapshot(snapshot => {
      const docData = {
        uid: snapshot.id,
        ...snapshot.data()
      }
      setDoc(docData);
    })
    return () => {
      unsubscribeChat();
      unsubscribeDoc();
    }
  }, [])

  const closeModal = () => {
    setOneSelectPopup(false);
    closeChatNotificationModal();
  };

  async function updateLastReadOnChat() {
    const timestamp = firebase.firestore.Timestamp.fromDate(new Date());
    if (company === undefined) return;
    await db.collection('chats').doc(doc.uid).set({
      lastRead: {
        [company]: timestamp
      }
    }, { merge: true })
  }

  return (
    <>
      {!!doc &&
        <StyledPopupNotification
          trigger={
            <div>
              <button
                type="button"
                className="button"
                id="buttonOpenPopupNotification"
                onClick={() => {
                  setIsOpenNotification((o: any) => true);
                  setOneSelectPopup((o: any) => true);
                  closeChatNotificationModal();
                }}
              >
                <div
                  className={
                    !messageReaded
                      ? "blockMsg blockMsg-focus"
                      : "blockMsg"
                  }
                  key={index}
                >
                  <GiPlainCircle
                    color={!messageReaded ? "red" : "green"}
                    style={{ paddingTop: "6px" }}
                    size={22}
                  />
                  <div className="msgAlert" style={{ position: 'relative', width: '100%' }}>
                    <b style={{ whiteSpace: 'nowrap', width: '320px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                      {doc.nameDocument}
                    </b>
                    <span style={{ width: 330, display: '-webkit-box', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical', overflow: 'hidden', textOverflow: 'ellipsis', color: 'var(--textGrayCard)', }}>
                      <b style={{ whiteSpace: 'nowrap', width: '200px', overflow: 'hidden', textOverflow: 'ellipsis', color: 'var(--textGrayCard)' }}>{item.user.name}:</b>
                      {item.message}
                    </span>
                    <span style={{ alignSelf: 'flex-end', fontSize: '0.8rem', color: 'var(--textGray)', position: 'absolute', bottom: 0, right: 0 }}>
                      {messageTime}
                    </span>
                  </div>
                </div>
              </button>
            </div>
          }
          modal
          key={index}
          open={oneSelectPopup}
          onOpen={updateLastReadOnChat}
          className="bodynotification"
          closeOnDocumentClick={false}
          onClose={() => {
            closeModal();
          }}
          lockScroll
        >
          <div className="alloccurrence">
            <AiOutlineCloseCircle
              size={18}
              color="white"
              style={{
                position: "absolute",
                right: "4px",
                top: "4px",
                cursor: "pointer",
                marginBottom: 10,
              }}
              onClick={closeModal}
            />
            {doc && (
              <div className="mainInformation">
                <InfoCards
                  handleClick={() => { }}
                  cardProps={doc}
                  closeIcon={false}
                  isOpenOccurence={false}
                />
              </div>
            )}
          </div>
        </StyledPopupNotification>
      }
    </>
  );
}