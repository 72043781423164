import { useContext, useEffect, useRef, useState } from 'react';
import { BsPencilSquare, BsFillTrashFill } from 'react-icons/bs';
import { useAlert } from 'react-alert';

// components
import checkbox from 'assets/icons/checkboxBlue.svg';

import { useUpdate } from 'ctx/UpdateContext';

// styles
import './index.scss';
import { confirmAlert } from 'react-confirm-alert';
import { deleteFinalization, updateFinalization } from 'hooks/createFinalization';
import { AuthContext } from 'ctx/AuthContext';
import { db } from 'database/FirebaseConfig';
import { Input, Select, Space, Tag } from 'antd';
import { ManagerT } from 'components/CompanyGroups/indext';

export type ICompany = {
    id: string;
    name: string;
    integrationCode: string;
    companyId: string;
    users: any;
}

type OccurrencesProps = {
    closeModal: () => void;
    managers: ManagerT[];
}

export function CompanyItem({ closeModal, managers }: OccurrencesProps) {
    const [companies, setCompanies] = useState<any>([]);
    const { userInfo } = useContext(AuthContext);

    useEffect(() => {
        const unsubscribe = db.collection('companyGroups').where('companyId', '==', userInfo?.company).onSnapshot(snap => {
            const data = snap.docs.map(doc => ({
                ...doc.data(),
                id: doc.id
            } as any))
            setCompanies(data)
        })

        return unsubscribe;
    }, [])

    return (
        <div className="allFinalizations">
            <div className="main-allFinalizations">
                <legend style={{ marginRight: 12 }}>Lista de empresas</legend>
                <div className="titlesOccurrence">
                    {companies?.length === 0 && (
                        <>
                            <p>Nome</p>
                            <p >Código</p>
                            <p >Usuários</p>
                        </>
                    )}
                </div>
                {companies !== undefined && companies.map((item: ICompany, index: number) => (
                    <Company item={item} index={index} key={index} closeModal={closeModal} managers={managers} />
                ))}
            </div>
        </div>
    )
}

type CompanyItemProps = {
    item: ICompany;
    index: number;
    managers: ManagerT[];
    closeModal: () => void;
}

export const Company = ({ item, index, closeModal, managers }: CompanyItemProps) => {
    const alert = useAlert();
    const [name, setName] = useState(item.name);
    const [integrationCode, setIntegrationCode] = useState(item.integrationCode);
    const [companyUsers, setCompanyUsers] = useState<any[]>([]);
    const [changeItem, setChangeItem] = useState(false);
    const fetchedName = useRef<string>()
    const fetchedIntegrationCode = useRef<string>()
    const { userInfo, loadInfoUser } = useContext(AuthContext);
    const { refreshPage } = useUpdate();

    async function getUserInfo() {
        let usersAux = [];
        for (const id of Object.keys(item.users)) {
            try {
                const userSnap = await db.collection('users').doc(id).get();
                if (userSnap.data()?.deletedAt !== null) continue;
                usersAux.push({
                    ...userSnap.data(),
                    id: userSnap.id,
                    label: userSnap.data()?.name,
                    value: userSnap.data()?.name,
                })
            } catch (error) {
                alert.error(error)
            }
        }
        setCompanyUsers(usersAux);
    }

    async function handleUpdate(uid: string) {
        if (!name || name === '') {
            alert.error('Insira o nome da finalização!');
            setName(fetchedName.current!)
            return;
        }
        const data = {
            name,
            integrationCode,
            users: companyUsers.reduce((acc, manager) => ({
                ...acc,
                [manager.id]: true,
            }), {})
        }

        if (!!userInfo && !companyUsers.find(user => user.selectedCompanyGroupId === uid) && userInfo.selectedCompanyGroupId === item.id) {
            console.log('UPDATE:', userInfo.selectedCompanyGroupId, item.id);

            try {
                await db.collection('users').doc(userInfo?.uid).update({
                    selectedCompanyGroupId: null
                })
                loadInfoUser({
                    ...userInfo,
                    selectedCompanyGroupId: null
                })
            } catch (error) {
                console.log(error)
            }
        }

        try {
            await db.collection('companyGroups').doc(uid).update(data)
            alert.success('Empresa atualizada!');
        } catch (error) {
            alert.error(error);
        }
        setChangeItem(false);
        refreshPage();
    }

    async function handleDeleteCompany(company: any) {
        try {
            await db.collection('companyGroups').doc(company.id).delete();
            alert.success('Empresa deletada com sucesso!')
        } catch (error) {
            alert.error(error)
        }
    }

    function handleCancel() {
        setChangeItem(!changeItem)
        setName(fetchedName.current!)
        setIntegrationCode(fetchedIntegrationCode.current!)
    }

    useEffect(() => {
        setName(item.name);
        setIntegrationCode(item.integrationCode);
        fetchedName.current = item.name;
        fetchedIntegrationCode.current = item.integrationCode;
        getUserInfo();
    }, [item])

    return (
        <div className="blockAllCompanies" key={index}>
            <div className="changeCard">
                {index === 0 && (
                    <legend>Nome</legend>
                )}
                <Input
                    value={name}
                    hidden={!changeItem}
                    onChange={event => setName(event.target.value)}
                    type="text" />
                <span hidden={changeItem}>{name}</span>
            </div>

            <div className="changeCard">
                {index === 0 && (
                    <legend >Código</legend>
                )}

                {changeItem ? (
                    <Input
                        value={integrationCode}
                        onChange={event => setIntegrationCode(event.target.value)}
                    />
                ) : (
                    <div style={integrationCode === '' ? { marginBottom: '36px' } : {}}>{integrationCode}</div>
                )}
            </div>
            <div className="changeCard">
                {index === 0 && (
                    <legend >Usuários</legend>
                )}

                {changeItem ? (
                    <Select
                        mode="multiple"
                        allowClear
                        value={companyUsers}
                        labelInValue
                        style={{ width: '100%' }}
                        placeholder="Selecione os usuários da empresa"
                        optionFilterProp="label"
                        onChange={(value) => setCompanyUsers(value.map((option: any) => managers.find((manager: any) => manager.id === option.key)))}
                    >
                        {managers.length > 0 && managers.map((manager: any) => (
                            <Select.Option key={manager.id} value={manager.name}>
                                {manager.name}
                            </Select.Option>
                        ))}
                    </Select>
                ) : (
                    <Space style={companyUsers.length === 0 ? { marginBottom: '36px' } : {}} size={[0, 8]} wrap>
                        {companyUsers.length > 0 && companyUsers.map((user: any) =>
                            <Tag>{user.name}</Tag>
                        )}
                    </Space>
                )}
            </div>
            <div  // New Buttons
                className="blockIconButtonsFinalizations"
                style={index === 0 ? { paddingTop: '1.3rem' } : { paddingTop: '0' }}
            >
                {!changeItem ?
                    <>
                        <BsPencilSquare
                            style={{ cursor: 'pointer', marginRight: '0.5rem', color: 'var(--secondary)' }}
                            onClick={() => setChangeItem(!changeItem)}
                        />
                        {/* <BsFillTrashFill
                            style={{ cursor: 'pointer', color: 'var(--secondary)' }}
                            onClick={() => {
                                confirmAlert({
                                    message: 'Deseja remover esta empresa?',
                                    buttons: [
                                        {
                                            label: 'Cancelar',
                                            onClick: () => ('Cancelar')
                                        },
                                        {
                                            label: 'Sim',
                                            onClick: () => {
                                                handleDeleteCompany(item);
                                            }
                                        }
                                    ],
                                    overlayClassName: 'confirmAlert'
                                });
                            }}
                        /> */}
                    </>
                    :
                    <div>
                        <div className="sendButtonOccurences" onClick={() => handleUpdate(item.id)}>
                            Salvar
                        </div>
                        <div className="cancelButtonOcurrences" onClick={handleCancel}>
                            Cancelar
                        </div>
                    </div>
                }
            </div>
        </div >
    )
}
