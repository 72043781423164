import { AiOutlineCloseCircle } from 'react-icons/ai'
import { FormEvent, useState } from 'react';
import { useAlert } from 'react-alert';
import Cookies from 'js-cookie';

// components
import { default as UseCreateOcurrence } from 'hooks/useCreateOcurrence';
import { Occurences } from '../Occurences';

import checkbox from 'assets/icons/checkboxBlue.svg';

// styles
import './index.scss';
import { validateEmails } from 'utils/validateEmails';
import { useApi } from 'ctx/ApiContext';
import { Input, Select, Space, Switch } from 'antd';
const { TextArea } = Input;

type OccurrenteProps = {
  Target?: any;
  closeModal: any;
}

export function Occurrence({ closeModal }: OccurrenteProps) {
  const alert = useAlert();

  const [finish, setFinish] = useState(false);
  const [images, setImages] = useState(false);

  const [isOpenRequestedPhotos, setIsOpenRequestedPhotos] = useState(false);
  const [info, setInfo] = useState('');
  const [situation, setSituation] = useState<number>();
  const [nameOcurrence, setNameOcurrence] = useState('');
  const [codSisClient, setCodSisClient] = useState('');
  const [emails, setEmails] = useState('');

  const theme = Cookies.get("colorTheme");
  document.documentElement.style.setProperty('--secondary', String(theme));

  function handleSubmit(event: FormEvent) {
    event.preventDefault();
    const company = Cookies.get("company");

    const invalidsEmail = validateEmails(emails);

    if (invalidsEmail.length !== 0) {
      invalidsEmail.forEach((email: any) => {
        alert.error(`Email invalido!\n${email}`);
      })
      return;
    }

    const data = {
      company,
      name: nameOcurrence,
      info,
      finish,
      images,
      notificationEmails: emails,
      codSisClient,
      situation,
    }
    UseCreateOcurrence(data);
    setNameOcurrence('');
    setInfo('');
    setEmails('');
    setSituation(0);
    setFinish(false);
    setCodSisClient('')
    alert.success('Ocorrência salva!');
  }
  return (
    <div className="occurrence" >
      <div className="header-occurrence">
        <span>Cadastrar ocorrências</span>
        <AiOutlineCloseCircle onClick={() => closeModal()} size={22} style={{ cursor: 'pointer' }} />
      </div>
      <form className="form-occurrence" onSubmit={handleSubmit}>
        <div style={{ margin: '10px' }}>
          <div style={{ width: '100%', display: 'flex', gap: 20 }}>
            <div>
              <legend>Tipo de ocorrência</legend>
              <Select
                placeholder="Selecione a situação"
                style={{ width: 200, }}
                size='large'
                onChange={(value) => setSituation(value)}
                options={[
                  { value: 0, label: '0 - Pendência' },
                  { value: 1, label: '1 - Finalização' },
                ]}
              />
            </div>
            <div style={{ width: '100%' }}>
              <legend>Nome da ocorrência</legend>
              <Input
                value={nameOcurrence}
                placeholder="Informe o nome da ocorrência"
                style={{ width: '100%', height: 40 }}
                required
                onChange={event => setNameOcurrence(event.target.value)}
              />
            </div>
            <div>
              <legend>Foto</legend>
              <Select
                placeholder="Selecione a situação"
                defaultValue={false}
                size='large'
                style={{
                  width: 200,
                }}
                onChange={(value) => setImages(value)}
                options={[
                  { value: false, label: 'Não obrigar foto' },
                  { value: true, label: 'Obrigar foto' },
                ]}
              />
            </div>
          </div>

        </div>

        <div style={{ width: '100%', display: 'flex', gap: 20, paddingLeft: 10, paddingRight: 10 }}>
          <div style={{ width: '100%' }}>
            <legend>Informações adicionais</legend>
            <Input
              style={{
                height: 40,
                width: '100%',
              }}
              onChange={event => setInfo(event.target.value)}
              placeholder="Informações adicionais"
            />
          </div>
          <div >
            <legend>Código do sistema (opcional)</legend>
            <Input
              value={codSisClient}
              placeholder="Informe o codigo do sistema"
              style={{ height: 40, width: 250 }}
              maxLength={20}
              onChange={event => setCodSisClient(event.target.value)}
            />
          </div>
        </div>

        <div style={{ margin: '10px 10px' }}>
          <legend>E-mails de notificação (separados por vírgula se mais de um)</legend>
          <Input
            value={emails}
            placeholder="Emails de notificação"
            style={{ height: 40 }}
            required
            onChange={event => setEmails(event.target.value)}
          />
        </div>

        <div className="button-form">
          <button type="button" onClick={() => closeModal()}>Cancelar</button>
          <button type="submit">Salvar</button>
        </div>
      </form>
      <div className="allOccurence">
        <Occurences closeModal={closeModal} />
      </div>
    </div>
  )
}