// styles
import axios from "axios";
import { Popup } from "reactjs-popup";
import Styled from "styled-components";
import React, { useState, ChangeEvent, useEffect } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useAlert } from "react-alert";
import { parse } from "papaparse";
import Cookies from "js-cookie";

// components
import { InputDocumentView } from "../InputDocumentView";
import { handleUpload } from "hooks/useHandleXML";
import { CreateDocuments } from "../CreateDocuments";

// utils
import MaskCPFCNPJ from "utils/MaskCpfCnpj";
import MaskPhone from "utils/Phone";

// assets
import arrow from "assets/icons/arrow.svg";
import pencil from "assets/icons/pencil.svg";
import trash from "assets/icons/trash.svg";
import anexoGreen from "assets/icons/anexoGreen.svg";

// styles
import styles from "./styles.module.scss";
import { NFeCatch } from "types/InterfaceNFeCatch";
import { copyFileSync } from "fs";
import { CardsProps } from "types/InterfaceCard";
import { adressMask } from "utils/adressMask";
import { CreateMultiplesDocuments } from "components/CreateMultiplesDocuments";
import { useAuth } from "ctx/AuthContext";

const StyledPopup = Styled(Popup)`
&-content {
    padding: 0px;
    border: none;
    border-radius: 18px;
  }


  @media(max-width:520px) {
    &-content  {
        width: 90%;
    }
}  
`;

export function ImportDocuments({ company, tam }: any) {
  const alert = useAlert();
  const [file, setFile] = useState<any>([]);
  const [documents, setDocuments] = useState<any>([]);
  const [nameFile, setNameFile] = useState("arquivo.xml");
  const [cardProps, setCardProps] = useState<any>(undefined);
  const [cardsProps, setCardsProps] = useState<CardsProps[]>([]);
  const [edit, setEdit] = useState(false);
  const { userInfo } = useAuth();

  const companie2 = Cookies.get("company");
  const theme = Cookies.get("colorTheme");
  document.documentElement.style.setProperty("--secondary", String(theme));

  const [open, setOpen] = useState(false);
  const closeModal = () => {
    setDocuments([]);
    setNameFile("arquivo");
    setCardProps(undefined);
    setEdit(false);
    setOpen(false);
  };
  function handleSubmit(uid: string) {
    try {
      handleUpload(file, uid, company, () => { });
    } catch (e) {
      console.log(e);
      alert.error("ERRO INESPERADO!");
    }
    clearFields();
  }

  function clearFields() {
    setDocuments([]);
    setNameFile("arquivo");
    setCardProps(undefined);
    setCardsProps([]);
    closeModal();
  }

  async function handleSelectCSV(event: ChangeEvent<HTMLInputElement>) {
    if (!event.target.files) {
      alert.error("Upload CSV Error!");
      return;
    }
    const files = Array.from(event.target.files);
    alert.success("CSV carregado com sucesso!");
    const csv = Array.from(event.target.files).toString();
    setDocuments(csv);
    setNameFile(event.target.files[0].name);
    setFile(event.target.files[0])

    files.filter((file) => file.type === "text/csv")
      .forEach(async (file) => {
        const text = await file.text();
        const result = parse(text, { header: true });
        let document = result.data
        const id_card = tam ? tam + 1 : 0;

        document.forEach((doc: any, index: number) => {
          const data = {
            id: id_card,
            cod: doc.cod_documento,
            nameDocument: doc.nome_documento,
            client: {
              name: doc.nome_documento,
              fone: doc.fone_origem,
              cpf_cnpj: doc.documento_origem,
            },
            origin: {
              address: doc.endereco_origem,
              state: doc.uf_origem,
              citie: doc.cidade_origem,
            },
            destiny: {
              addressDestiny: doc.endereco_destino,
              stateDestiny: doc.uf_destino,
              citieDestiny:
                doc.cidade_destino,
            },
            clientDestiny: doc.cliente_destino,
            timeToExitSender: {
              seconds: 0,
            },
            timeToArrivalSender: {
              seconds: 0,
            },
            timeToArrival: {
              seconds: 0,
            },
            status: 1,
            type: 2,
            intoRomaneio: false,
            company: String(companie2),
            history: [],
            finish: {
              date: "",
              image: "",
            },
            companyGroupId: userInfo?.selectedCompanyGroupId
          };
          setCardsProps((prevCards) => {
            const existInTheCards = prevCards.find(
              (card) => card.cod === data.cod
            );
            if (!existInTheCards) {
              return [...prevCards, data];
            }
            return [...prevCards];
          });
        })
      });
  }

  async function handleSelectXML(event: ChangeEvent<HTMLInputElement>) {
    if (!event.target.files) {
      alert.error("Upload XML Error!");
      return;
    }
    const files = Array.from(event.target.files);
    // const files = event.target.files;
    alert.success("XML carregado com sucesso!");


    const xml2js = require("xml2js");
    // const JsonToTS = require('json-to-ts');

    var enderEmit = "";
    var enderDest = "";

    const xml = Array.from(event.target.files).toString();
    setDocuments(xml);
    setNameFile(event.target.files[0].name);
    setFile(event.target.files[0])

    // var file = event.target.files[0];

    await files.forEach((file, index) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async () => {
        const path = reader.result?.toString();

        if (path === undefined) return;

        await axios
          .get(path, {
            headers: { "Content-Type": "application/xml; charset=utf-8" },
          })
          .then((response: any) => {
            xml2js.parseString(
              response.data.toString().replace("\ufeff", ""),
              {
                mergeAttrs: true,
                preserveWhitespace: true,
              },
              (err: any, result: any) => {
                if (err) {
                  throw err;
                }
                try {
                  var xmlToJson = result;

                  const path = xmlToJson.nfeProc
                    ? xmlToJson.nfeProc.NFe[0].infNFe[0]
                    : xmlToJson.NFe.infNFe[0];

                  const emitAdress = path.emit[0].enderEmit[0];
                  const destAdress = path.dest[0].enderDest[0];

                  enderEmit = adressMask(emitAdress);
                  enderDest = adressMask(destAdress);

                  if (xmlToJson === undefined) return;

                  const pathCNPJ = path.emit[0].CNPJ[0];
                  const pathPhone = path.emit[0].enderEmit[0].fone[0];

                  const cpfOrCnpj = MaskCPFCNPJ(pathCNPJ);
                  const phone = MaskPhone(pathPhone);

                  const id_card = tam ? tam + 1 : 0;

                  const data = {
                    id: id_card,
                    cod: path.Id[0],
                    nameDocument: path.emit[0].xNome[0],
                    client: {
                      name: path.emit[0].xNome[0],
                      fone: phone,
                      cpf_cnpj: cpfOrCnpj,
                    },
                    origin: {
                      address: enderEmit,
                      state: path.emit[0].enderEmit[0].UF[0],
                      citie: path.emit[0].enderEmit[0].xMun[0].toUpperCase(),
                    },
                    destiny: {
                      addressDestiny: enderDest,
                      stateDestiny: path.dest[0].enderDest[0].UF[0],
                      citieDestiny:
                        path.dest[0].enderDest[0].xMun[0].toUpperCase(),
                    },
                    clientDestiny: path.dest[0].xNome[0],
                    timeToExitSender: {
                      seconds: 0,
                    },
                    timeToArrivalSender: {
                      seconds: 0,
                    },
                    timeToArrival: {
                      seconds: 0,
                    },
                    status: 1,
                    type: 2,
                    intoRomaneio: false,
                    company: String(companie2),
                    history: [],
                    finish: {
                      date: "",
                      image: "",
                    },
                    companyGroupId: userInfo?.selectedCompanyGroupId
                  };

                  setCardsProps((prevCards) => {
                    const existInTheCards = prevCards.find(
                      (card) => card.cod === data.cod
                    );
                    if (!existInTheCards) {
                      return [...prevCards, data];
                    }
                    return [...prevCards];
                  });
                } catch (err) {
                  alert.error("FORMATO INVALIDO!");
                }
              }
            );
          });
      };
      reader.onerror = function (error) {
        console.log("Error: ", error);
      };
    });
  }

  return (
    <div>
      <StyledPopup
        trigger={
          <div>
            <button onClick={() => setOpen((o) => !o)} id="buttonOpenPopup">
              <InputDocumentView title="importar documento" img={arrow} />
            </button>
          </div>
        }
        closeOnDocumentClick={false}
        open={open}
        onClose={closeModal}
        modal
        className="styledPopup"
        position="top center"
        lockScroll
      >
        <div className={styles.popupCreateRomaneio}>
          <div className={styles.header}>
            <span>Importar documentos </span>
            <AiOutlineCloseCircle
              color="white"
              style={{ cursor: "pointer" }}
              size={22}
              onClick={() => {
                clearFields();
              }}
            />
          </div>
          <div className={styles.myFormClass}>
            <div className={styles.documentsXML}>
              <span>Documentos</span>
              <div className={styles.document}>
                <span
                  style={nameFile.length > 80 ? { overflowX: "scroll" } : {}}
                  hidden={edit}
                >
                  {nameFile === "" ? cardProps?.cod : nameFile}
                </span>
                <input
                  value={nameFile.replace(".xml", "")}
                  onBlur={() => {
                    setEdit(false);
                  }}
                  hidden={!edit}
                  type="text"
                  onChange={(e) => {
                    setNameFile(e.target.value.trim());
                  }}
                />
                <div className={styles.blockEdit}>
                  <img
                    hidden={documents.length === 0}
                    src={pencil}
                    alt="adicionar anexo"
                    onClick={() => {
                      setEdit(!edit);
                    }}
                  />
                  <img
                    hidden={documents.length === 0}
                    src={trash}
                    alt="excluir anexo"
                    onClick={() => {
                      setNameFile("arquivo");
                      setCardProps(undefined);
                      setCardsProps([]);
                    }}
                  />
                </div>
              </div>
              {!cardsProps.length && (
                <div className={styles.importXMLButtonContainer}>
                  <label htmlFor="inputCSV">
                    <img src={anexoGreen} alt="adicionar anexo" />
                    importar csv
                    <input
                      id="inputCSV"
                      accept=".csv"
                      type="file"
                      onChange={(e) => {
                        handleSelectCSV(e);
                      }}
                    />
                  </label>
                  <label htmlFor="inputXML">
                    <img src={anexoGreen} alt="adicionar anexo" />
                    importar xml
                    <input
                      id="inputXML"
                      accept=".xml"
                      type="file"
                      onChange={(e) => {
                        handleSelectXML(e);
                      }}
                    />
                  </label>
                  <label htmlFor="inputMultiplesXML" style={{ fontSize: 13 }}>
                    <img src={anexoGreen} alt="adicionar anexo" />
                    importar xml em lote
                    <input
                      id="inputMultiplesXML"
                      accept=".xml"
                      type="file"
                      onChange={(e) => {
                        handleSelectXML(e);
                      }}
                      multiple
                    />
                  </label>
                </div>
              )}
            </div>

            {cardsProps.length === 1 && (
              <>
                <CreateDocuments
                  Target={null}
                  title={"teste"}
                  cardProps={cardsProps[0]}
                  xml={true}
                  handleSubmitF={handleSubmit}
                  close={clearFields}
                />
              </>
            )}
            {cardsProps.length > 1 && (
              <>
                <CreateMultiplesDocuments
                  Target={null}
                  title={"teste"}
                  cardProps={cardsProps}
                  xml={true}
                  handleSubmitF={handleSubmit}
                  close={clearFields}
                />
              </>
            )}
          </div>
        </div>
      </StyledPopup>
    </div>
  );
}
