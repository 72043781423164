import React, { FormEvent, useContext, useEffect, useState } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { cities } from "estados-cidades";
import { useAlert } from "react-alert";
import { Popup } from "reactjs-popup";
import Styled from "styled-components";

import firebase from "database/FirebaseConfig";

// components
import { default as CreateDocument } from "hooks/useCreateDocument";
import { UpdateAllFields } from "hooks/useUpdateCard";

import MaskCPFCNPJ from "utils/MaskCpfCnpj";
import MaskPhone from "utils/Phone";
import FormartDate from "utils/FormartDate";

// context
import { useUpdate } from "ctx/UpdateContext";
import { AuthContext } from "ctx/AuthContext";
import { useCard } from "ctx/CardRasterContext";

// assets
import checkbox from "assets/icons/checkboxBlue.svg";
import estados from "utils/estados.json";

// interface
import { CardRasterProps } from "types/InterfaceCard";

// styles
import styles from "./styles.module.scss";
import "reactjs-popup/dist/index.css";
import { useApi } from "ctx/ApiContext";

const StyledPopup = Styled(Popup)`  
&-content {
    padding: 0px;
    border: none;
    border-radius: 18px;
}
  
    @media(max-width:520px) {
        &-content  {
            width: 95%;
        }
    }
      
`;

type CreateDocumentsProps = {
  Target: any;
  title: string;
  update?: boolean;
  handleSubmitF?: any;
  cardProps?: CardRasterProps[];
  tam?: number;
  xml?: boolean;
  close: () => void;
  closeModalInfoCard?: () => void;
};

export function CreateMultiplesDocuments({
  Target,
  title,
  cardProps,
  update,
  tam,
  closeModalInfoCard,
  xml,
  handleSubmitF,
  close,
}: CreateDocumentsProps) {
  const { refreshPage } = useUpdate();

  const { userInfo } = useContext(AuthContext);

  const alert = useAlert();

  const { allDocs } = useCard();

  const { setRefreshDocuments } = useApi();

  const collectOrDilivery = false;

  // controller switch-box
  const [isCollect, setIsCollect] = useState(collectOrDilivery);
  const [isDelivery, setIsDelivery] = useState(!collectOrDilivery);

  // fields 'Documento' and 'Código'

  const company = userInfo?.company;

  // Convert number to type datelocal-timme
  var originExit;
  var originArrival;
  var destinyArrival;

  // Origin Fields, set with database data or default values
  const [exit, setExit] = useState<any>(originExit || "");
  const [arrival, setArrival] = useState<any>(originArrival || "");
  const [arrivalDestiny, setArrivalDestiny] = useState<any>(
    destinyArrival || ""
  );

  const [open, setOpen] = useState(false);
  const closeModal = () => setOpen(false);
  const db = firebase.firestore();

  // Create or Update Values
  function handleSubmit(event: FormEvent) {
    event.preventDefault();
    if (update) {
      // handleUpdateFields();
    } else {
      const type = isDelivery ? 2 : 1;

      var dateExitSender = new Date(exit);
      var dateArrivalSender = new Date(arrival);
      var dateArrival = new Date(arrivalDestiny);
      let id_card = tam ? tam + 1 : 1;

      const dataFormatted = cardProps?.map((card) => {
        id_card++;

        return {
          ...card,
          id: id_card,
          timeToExitSender: dateExitSender,
          timeToArrivalSender: dateArrivalSender,
          timeToArrival: dateArrival,
          status: 1,
          type,
          intoRomaneio: false,
          currentRomaneio: "",
          company,
          history: [],
        };
      });

      dataFormatted?.forEach((data) => {
        CreateDocument(data);
      });

      ClearFields();
      alert.success("Documento salvo");
      refreshPage();
      close();
      setRefreshDocuments(true);
    }
    // refreshPage();
  }

  // Clear all fields after submit or close popup
  function ClearFields() {
    setExit("");
    setArrival("");
    setArrivalDestiny("");
  }

  function handleSubmitFile(event: FormEvent) {
    event.preventDefault();

    const type = isDelivery ? 2 : 1;

    var dateExitSender = new Date(exit);
    var dateArrivalSender = new Date(arrival);
    var dateArrival = new Date(arrivalDestiny);
    const tam = allDocs.length;
    let id_card = tam;

    const dataFormatted = cardProps?.map((card) => {
      id_card++;

      return {
        ...card,
        id: id_card,
        timeToExitSender: dateExitSender,
        timeToArrivalSender: dateArrivalSender,
        timeToArrival: dateArrival,
        status: 1,
        type,
        intoRomaneio: false,
        currentRomaneio: "",
        company,
        history: [],
      };
    });

    dataFormatted?.forEach((data) => {
      db.collection("documents")
        .add(data)
        .then((ref: any) => {
          handleSubmitF(ref.id);
        });
    });

    ClearFields();
    alert.success("Documento importado");
    close();
  }

  return (
    <div>
      {Target === null ? (
        <form
          onSubmit={handleSubmitFile}
          id="myForm"
          style={{ overflow: "scroll", height: 600 }}
        >
          <div className="collectOrDelivery">
            <label className={isCollect ? "label-active" : ""}>
              <input
                type="checkbox"
                onClick={() => {
                  setIsDelivery(false);
                  setIsCollect(true);
                }}
              />
              Coleta
              {isCollect === true && (
                <img
                  src={checkbox}
                  alt="checkbox true"
                  onClick={() => {
                    setIsDelivery(false);
                    setIsCollect(!isCollect);
                  }}
                />
              )}
            </label>
            <label className={isDelivery ? "label-active" : ""}>
              <input
                type="checkbox"
                onClick={() => {
                  setIsDelivery(true);
                  setIsCollect(false);
                }}
              />
              Entrega
              {isDelivery === true && (
                <img
                  src={checkbox}
                  alt="checkbox true"
                  onClick={() => {
                    setIsDelivery(!isDelivery);
                    setIsCollect(false);
                  }}
                />
              )}
            </label>
          </div>

          <div className={styles.labelContainer}>
            <legend>Insira data para todos os arquivos</legend>
          </div>

          <legend>Informação de origem</legend>
          <div className="input-form-createDocuments">
            <div className="blockInput-createDocuments-secondary">
              <div className="blockInput-createDocuments">
                <label>Data e hora de chegada</label>
                <div className="inputTime" style={{ display: "flex" }}>
                  <input
                    value={arrival}
                    required
                    type="datetime-local"
                    onChange={(event) => setArrival(event.target.value)}
                  />
                </div>
              </div>
              <div className="blockInput-createDocuments">
                <label>Data e hora de saída</label>
                <div className="inputTime" style={{ display: "flex" }}>
                  <input
                    value={exit}
                    required
                    type="datetime-local"
                    onChange={(event) => setExit(event.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
          <legend id="infoDestiny">Informação de destino</legend>
          <div className="input-form-createDocuments">
            <div className="blockInput-createDocuments-secondary">
              <div className="blockInput-createDocuments">
                <label>Data e hora de chegada</label>
                <div className="inputTime" style={{ display: "flex" }}>
                  <input
                    required
                    value={arrivalDestiny}
                    type="datetime-local"
                    placeholder="formulário preenchido"
                    onChange={(event) => setArrivalDestiny(event.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="button-form">
            <button
              type="button"
              onClick={() => {
                if (close) close();
                ClearFields();
              }}
            >
              Cancelar
            </button>
            <button type="submit">Importar</button>
          </div>
        </form>
      ) : (
        <>
          <StyledPopup
            trigger={
              <div>
                <button onClick={() => setOpen((o) => !o)} id="buttonOpenPopup">
                  {Target}
                </button>
              </div>
            }
            open={open}
            closeOnDocumentClick={false}
            onClose={closeModal}
            modal
            position="top center"
            lockScroll
          >
            <div className="popupCreateDocument">
              <div className="headerDocument">
                <span>{title}</span>
                <AiOutlineCloseCircle
                  color="white"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    closeModal();
                    ClearFields();
                  }}
                  size={22}
                />
              </div>
              <form
                onSubmit={handleSubmit}
                id="myForm"
                style={{ overflow: "scroll", height: 600 }}
              >
                <div className="collectOrDelivery">
                  <label className={isCollect ? "label-active" : ""}>
                    <input
                      type="checkbox"
                      onClick={() => {
                        setIsDelivery(false);
                        setIsCollect(true);
                      }}
                    />
                    Coleta
                    {isCollect === true && (
                      <img
                        src={checkbox}
                        alt="checkbox true"
                        onClick={() => {
                          setIsDelivery(false);
                          setIsCollect(!isCollect);
                        }}
                      />
                    )}
                  </label>
                  <label className={isDelivery ? "label-active" : ""}>
                    <input
                      type="checkbox"
                      onClick={() => {
                        setIsDelivery(true);
                        setIsCollect(false);
                      }}
                    />
                    Entrega
                    {isDelivery === true && (
                      <img
                        src={checkbox}
                        alt="checkbox true"
                        onClick={() => {
                          setIsDelivery(!isDelivery);
                          setIsCollect(false);
                        }}
                      />
                    )}
                  </label>
                </div>
                <legend>Informações do documentos</legend>
                <legend>Informação de origem</legend>
                <div className="input-form-createDocuments">
                  <div className="blockInput-createDocuments-secondary">
                    <div className="blockInput-createDocuments">
                      <label>Data e hora de chegada</label>
                      <div className="inputTime" style={{ display: "flex" }}>
                        <input
                          value={arrival}
                          required
                          type="datetime-local"
                          onChange={(event) => setArrival(event.target.value)}
                        />
                      </div>
                    </div>
                    <div className="blockInput-createDocuments">
                      <label>Data e hora de saída</label>
                      <div className="inputTime" style={{ display: "flex" }}>
                        <input
                          value={exit}
                          required
                          type="datetime-local"
                          onChange={(event) => setExit(event.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <legend id="infoDestiny">Informação de destino</legend>
                <div className="input-form-createDocuments">
                  <div className="blockInput-createDocuments-secondary">
                    <div className="blockInput-createDocuments">
                      <label>Data e hora de chegada</label>
                      <div className="inputTime" style={{ display: "flex" }}>
                        <input
                          required
                          value={arrivalDestiny}
                          type="datetime-local"
                          placeholder="formulário preenchido"
                          onChange={(event) =>
                            setArrivalDestiny(event.target.value)
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="button-form">
                  <button
                    type="button"
                    onClick={() => {
                      setOpen(!open);
                      ClearFields();
                    }}
                  >
                    Cancelar
                  </button>
                  <button type="submit">
                    {update ? "Atualizar" : "Salvar"}
                  </button>
                </div>
              </form>
            </div>
          </StyledPopup>
        </>
      )}
    </div>
  );
}
