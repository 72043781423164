import { useState } from 'react';
import { AiFillFacebook, AiFillLinkedin, AiFillYoutube, AiOutlineInstagram } from 'react-icons/ai';
import { FaRegHandshake } from 'react-icons/fa';
import { VscSearch } from 'react-icons/vsc';
import { IoMdContact } from 'react-icons/io';

import { doSomething } from 'hooks/useGetImages';

// styles
import './index.scss';
import { Images } from 'utils/template';

export function FooterClient() {
    const [urlLogo, setUrlLogo] = useState<any>('');
    const [inputSearch, setInputSearch] = useState<boolean>(false);

    async function handlePromise(type: string) {

        var result = await doSomething(type);

        if (result !== undefined) {
            if (type === 'logo') {
                setUrlLogo(result + 1);
            }
            return result + 1;
        }
    }

    handlePromise('logo');
    return (
        <div className="footer">
            <section>

                <img src={urlLogo || Images.logo} alt='Logo da marca' id="imgLogoFooter" />
                <div className="moreInfos">
                    <div className="address">
                        <div className="blockAddress">
                            <legend>MATRIZ</legend>
                            <span>Rua Anita Garibaldi,</span>
                            <span>270 | Concórdia - SC |</span>
                            <span>CEP 89.700-126</span>
                        </div>
                        <div className="blockAddress">
                            <legend>FILIAIS</legend>
                            <span>Rua Anita Garibaldi,</span>
                            <span>270 | Concórdia - SC |</span>
                            <span>CEP 89.700-126</span>
                        </div>
                    </div>
                    <div style={{ borderLeft: '1px solid var(--primary' }} />
                    <div className="contact">
                        <div className="blockContact">
                            <legend>CONTATOS</legend>
                            <span>Matriz Concórdia</span>
                            <span>(49) 3441-3400</span>
                        </div>
                        <div className="blockContact">
                            <label>
                                <IoMdContact size={18} color='var(--primary)' style={{ marginRight: '4px' }} />
                                Contato
                            </label>
                            <label>
                                <FaRegHandshake size={18} color='var(--primary)' style={{ marginRight: '4px' }} />
                                Trabalhe Conosco
                            </label>
                        </div>
                    </div>
                    <div style={{ borderLeft: '1px solid var(--primary' }} />
                    <div className="midia">
                        <div className="blockMidia">
                            <legend>REDES SOCIAIS</legend>
                            <label>
                                <AiOutlineInstagram size={18} color='var(--primary)' style={{ cursor: 'pointer', marginRight: '4px' }} />
                                Instagram
                            </label>
                            <label>
                                <AiFillFacebook size={18} color='var(--primary)' style={{ cursor: 'pointer', marginRight: '4px' }} />
                                Facebook
                            </label>
                            <label>
                                <AiFillLinkedin size={18} color='var(--primary)' style={{ cursor: 'pointer', marginRight: '4px' }} />
                                Linkedin
                            </label>
                            <label>
                                <AiFillYoutube size={18} color='var(--primary)' style={{ cursor: 'pointer', marginRight: '4px' }} />
                                Youtube
                            </label>
                        </div>
                        <div className="blockMidia">
                            <legend>PESQUISA</legend>
                            <label>
                                <VscSearch size={22} style={{ position: 'absolute', right: 95 }} onClick={() => { setInputSearch(!inputSearch) }} />
                                <input
                                    type="text"
                                    placeholder="tire suas dúvidas"
                                />
                            </label>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}