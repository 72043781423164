import { ReactNode, useState, createContext, useContext, useRef } from "react";
import firebase from "../database/FirebaseConfig";
import { CardRasterProps } from "../types/InterfaceCard";

type CardRasterContextProps = {
    setIds: (newIds: any, AddOrRemove: boolean) => void;
    clearIds: () => void;
    changeOpenInfo: () => void;
    getOneDoc: (uid: string) => any;
    loadDemands: (docs: CardRasterProps[]) => void;
    loadDemandsNotFinished: (docs: CardRasterProps[]) => void;
    openInfo: boolean;
    allDocs: CardRasterProps[];
    cardIdWillNotChange: any;
    allDocsNotFinish: CardRasterProps[];
    selectIdsToCreateRomaneios: any[];
    selectIdsToCreateRomaneiosAux: any[];
}

type CardRasterProviderProps = {
    children: ReactNode;
}

export const CardRasterContext = createContext({} as CardRasterContextProps);

export function CardRasterProvider({ children }: CardRasterProviderProps) {
    const [selectIdsToCreateRomaneios, setSelectIdsToCreateRomaneios] = useState<any>([]);
    const [selectIdsToCreateRomaneiosAux, setSelectIdsToCreateRomaneiosAux] = useState<any>([]);

    const [openInfo, setOpenInfo] = useState<boolean>(false);

    const cardIdWillNotChange = useRef<CardRasterProps[]>([]);

    const db = firebase.firestore();

    // const allDocs = useRef<CardRasterProps[]>([]);
    const [allDocs, setAllDocs] = useState<CardRasterProps[]>([]);

    // const allDocs = useRef<CardRasterProps[]>([]);
    const [allDocsNotFinish, setAllDocsNotFinish] = useState<CardRasterProps[]>([]);

    function changeOpenInfo() {
        setOpenInfo(prevState => !prevState);
    }

    function setIds(newId: CardRasterProps, AddOrRemove: boolean) {
        const cardId = !!newId.uid ? newId.uid : newId.id;
        const formart = db.doc('documents/' + cardId);

        if (AddOrRemove) {
            setSelectIdsToCreateRomaneios([...selectIdsToCreateRomaneios, formart]);
            setSelectIdsToCreateRomaneiosAux([...selectIdsToCreateRomaneiosAux, newId]);
        } else {

            let indexRemoverAux = selectIdsToCreateRomaneiosAux.indexOf(newId);

            let handleArrayAux = selectIdsToCreateRomaneiosAux;

            handleArrayAux.splice(indexRemoverAux, 1)

            var arrayClear: any = [];

            for (let i = 0; i < handleArrayAux.length; i++) {
                const ref = db.doc('documents/' + handleArrayAux[i].uid);
                arrayClear.push(ref);
            }
            // setSelectIdsToCreateRomaneiosAux(arrayClear);
            setSelectIdsToCreateRomaneios(arrayClear);
        }
    }

    function clearIds() {
        setSelectIdsToCreateRomaneios([]);
        setSelectIdsToCreateRomaneiosAux([]);
    }

    function loadDemands(docs: CardRasterProps[]) {
        setAllDocs(docs);
    }
    function loadDemandsNotFinished(docs: CardRasterProps[]) {
        setAllDocsNotFinish(docs);
    }

    function getOneDoc(uid: string) {
        var doc: any = [];
        for (let i = 0; i < allDocs.length; i++) {
            if (allDocs[i].uid === uid) {
                doc = allDocs[i];
                break;
            }
        }

        return doc;
    }

    return (
        <CardRasterContext.Provider
            value={{
                cardIdWillNotChange,
                selectIdsToCreateRomaneios,
                selectIdsToCreateRomaneiosAux,
                clearIds,
                setIds,
                allDocs,
                allDocsNotFinish,
                loadDemands,
                getOneDoc,
                openInfo,
                changeOpenInfo,
                loadDemandsNotFinished,
            }}
        >
            {children}
        </CardRasterContext.Provider>
    );
}

export const useCard = () => {
    return useContext(CardRasterContext);
}
