import { BrowserRouter, Route, Switch } from 'react-router-dom';
// import PageShell from './components/PageShell';
import Client from './Page/Client';

// screen's
import DocumentsView from './Page/DocumentsView';
import Companies from './Page/Companies';
import Home from './Page/Home';
import Index from './Page/Index';
import PainelCompanieUsers from './Page/PainelCompanieUsers';
import Tracking from './Page/Tracking';
import NotFound from 'Page/NotFound';
import Forms from 'Page/Forms';
import PainelForms from 'Page/PainelForms';
import { useAuth } from 'ctx/AuthContext';

export default function Routes() {
    const { userInfo } = useAuth();

    return (
        <BrowserRouter>
            <Switch>
                <Route path='/' exact component={Home} />
                <Route path='/client' exact component={Client} />
                <Route path='/tracking' exact component={Tracking} />
                <Route path='/forms' exact component={Forms} />
                {userInfo?.role === 'superadmin' &&
                    <Route path='/painelCompanieUsers' exact component={PainelCompanieUsers} />
                }
                {userInfo?.role === 'manager' &&
                    <Route path='/index' exact component={Index} />
                }
                <Route path='/painelForms' exact component={PainelForms} />
                <Route path='/documentsView' exact component={DocumentsView} />
                <Route component={NotFound} />
            </Switch>
        </BrowserRouter>
    );
}