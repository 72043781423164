import { makeStyles } from '@material-ui/core/styles';
import { memo, useState } from 'react';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { BsFillTrashFill, BsPencilSquare } from 'react-icons/bs';
import { confirmAlert } from 'react-confirm-alert';
import { FormsData, IFormsFormatted, useForms } from 'ctx/useForms';
import { deleteFormField, deleteForms } from 'hooks/useDeleteForms';
import { AlertManager, useAlert } from 'react-alert';
import { UpdateForm } from 'components/UpdateForm';
import Switch from '@mui/material/Switch';
import { UpdateOneForm } from 'hooks/useGetForms';

const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderTop: '2px solid var(--secondary)',
        },
    },
    disabled: {
        '& > *': {
            backgroundColor: 'var(--textGrayCard)',
        }
    },
});

interface CollapsibleTableProps {
    forms: IFormsFormatted[];
}

function createData(
    code: string,
    description: string,
    company: {
        id: string;
        name: string;
    },
    active: boolean,
    uid: string,
    formFieldId: FormsData[],
) {
    return {
        code,
        description,
        company,
        active,
        uid,
        history: formFieldId.map(field => {
            return ({
                uid: field.uid,
                name: field.name,
                description: field.description,
                type: field.type,
                size: field.size,
                maxMin: field.min ? `${field.min} - ${field.max}` : '-'
            })
        }
        )
    };
}

async function handleRemoveForm(
    uid: string,
    alert: AlertManager,
    setRefetch: React.Dispatch<React.SetStateAction<boolean>>,
    fields: any,
) {
    try {
        await deleteForms(uid);
        alert.success('Formulário deletado com sucesso');
        for (const field of fields) {
            await deleteFormField(field.uid);
        }
        setRefetch(prev => !prev)
    } catch (error) {
        alert.error('!Erro ao remover formulário!');
    }
}

function RowComponent(props: { row: ReturnType<typeof createData> }) {
    const { row } = props;
    const [open, setOpen] = useState(false);
    const classes = useRowStyles();
    const alert = useAlert();
    const { setRefetch } = useForms();
    const label = { inputProps: { 'aria-label': 'Switch Form' } };
    const [toggleStatus, setToggleStatus] = useState(false);

    async function updateFormStatus(id: string) {
        await UpdateOneForm(id, toggleStatus);
        setToggleStatus(!toggleStatus);
    }

    return (
        <>
            <TableRow className={classes.root}>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    {row.code}
                </TableCell>
                <TableCell >{row.description}</TableCell>
                <TableCell >{row.company.name}</TableCell>
                <TableCell align="right">
                    <Switch {...label}
                        onChange={() => { updateFormStatus(row.uid) }}
                        color="primary"
                        value={row.active}
                        checked={row.active}
                    />
                </TableCell>
                <TableCell align="center">{
                    <div
                        className="blockIconButtons"
                        style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            fontSize: '1.0rem',
                            alignItems: "center",
                            color: "var(--secondary)"
                        }}>

                        <UpdateForm
                            Target={
                                <BsPencilSquare
                                    style={{ cursor: 'pointer', marginRight: '0.2rem', marginTop: '0.3rem' }}
                                />}
                            title="atualizar formulário"
                            formData={row}
                        />

                        <BsFillTrashFill
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                                confirmAlert({
                                    message: 'Deseja remover este formulário?',
                                    buttons: [
                                        {
                                            label: 'Cancelar',
                                            onClick: () => ('Cancelar')
                                        },
                                        {
                                            label: 'Sim',
                                            onClick: () => {
                                                handleRemoveForm(row.uid, alert, setRefetch, row.history);
                                            }
                                        }
                                    ],
                                    overlayClassName: 'confirmAlert'
                                });
                            }}
                        />
                    </div>
                }
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={3}>
                            <Typography variant="h6" gutterBottom component="div">
                                Campos do formulário
                            </Typography>
                            <Table size="small" aria-label="forms">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Ordem</TableCell>
                                        <TableCell>Nome</TableCell>
                                        <TableCell >Descrição</TableCell>
                                        <TableCell align="left">Tipo</TableCell>
                                        <TableCell align="center">Tamanho</TableCell>
                                        <TableCell align="center">Valor min / Valor Max</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row.history.map((historyRow, index) => (
                                        <TableRow key={historyRow.uid}>
                                            <TableCell component="th" scope="row">{index + 1}</TableCell>
                                            <TableCell>{historyRow.name}</TableCell>
                                            <TableCell >{historyRow.description}</TableCell>
                                            <TableCell align="left">{historyRow.type}</TableCell>
                                            <TableCell align="center">{historyRow.size}</TableCell>
                                            <TableCell align="center">{historyRow.maxMin ? historyRow.maxMin : '-'}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}

const Row = memo(RowComponent, (prevProps, nextProps) => {
    return JSON.stringify(prevProps.row.history) === JSON.stringify(nextProps.row.history);
})

export function CollapsibleTableComponent({ forms }: CollapsibleTableProps) {
    const rows = forms.map((form, index) => {
        return createData(String(index), form.formDescription, form.company, form.active!, form.uid, form.formsFieldId,)
    })

    return (
        <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
                <TableHead>
                    <TableRow>
                        <TableCell size="small" />
                        <TableCell>Código</TableCell>
                        <TableCell >Descrição</TableCell>
                        <TableCell>Empresa</TableCell>
                        <TableCell align="right">Status</TableCell>
                        <TableCell align="right">Ações</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {forms.map((form, index) => (
                        <Row key={form.uid} row={rows[index]} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export const CollapsibleTable = memo(CollapsibleTableComponent, (prevProps, nextProps) => {
    return JSON.stringify(prevProps.forms) === JSON.stringify(nextProps.forms);
})