/* eslint-disable no-undef */
import { useState } from 'react';
import firebase from '../database/FirebaseConfig';

const UseGetHistoric = () => {
    const db = firebase.firestore();
    const controller = new AbortController();
    const [loading, setLoading] = useState(false);

    const getHistoric = async ({ onComplete, reference }: any) => {
        setLoading(true);
        db.collection('historic')
            .where('document', '==', reference)
            .get()
            .then((querySnapshot: any) => {
                const historic = querySnapshot.docs.map((doc: any) => {
                    return { ...doc.data(), uid: doc.id };
                });
                onComplete(historic);
                setLoading(false);
            })
            .catch((error: any) => {
                setLoading(false);
                console.log('erro: ', error);
            });

        return () => {
            const timeout = setTimeout(() => controller.abort(), 5000);
            clearTimeout(timeout);
        };
    };


    return { loading, getHistoric };
};
const UseGetNotification = () => {
    const db = firebase.firestore();
    const controller = new AbortController();
    const [loading, setLoading] = useState(false);

    const getNotifications = async ({ onComplete, reference, company }: any) => {
        setLoading(true);
        db.collection('notifications')
            .where('company', '==', company)
            .onSnapshot((querySnapshot: any) => {
                const notifications = querySnapshot.docs.map((doc: any) => {
                    return { ...doc.data(), uid: doc.id };
                });
                onComplete(notifications);
                setLoading(false);
            }, err => {
                setLoading(false);
                console.log('erro: ', err);

            });

        return () => {
            const timeout = setTimeout(() => controller.abort(), 5000);
            clearTimeout(timeout);
        };
    };


    return { loading, getNotifications, setLoading };
};
const CheckWheterrCardHasOccurrence = () => {
    const db = firebase.firestore();
    const controller = new AbortController();
    const [loading, setLoading] = useState(false);

    const getInfo = async ({ onComplete, reference }: any) => {
        setLoading(true);
        db.collection('notifications')
            .where('check', '==', false)
            .where('document', '==', reference)
            .get()
            .then((querySnapshot: any) => {
                const notifications = querySnapshot.docs.map((doc: any) => {
                    return { ...doc.data(), uid: doc.id };
                });
                onComplete(notifications);
                setLoading(false);
            })
            .catch((error: any) => {
                setLoading(false);
                console.log('erro: ', error);
            });

        return () => {
            const timeout = setTimeout(() => controller.abort(), 5000);
            clearTimeout(timeout);
        };
    };


    return { loading, getInfo, setLoading };
};
export { CheckWheterrCardHasOccurrence, UseGetNotification, UseGetHistoric as default };
