import { useCallback, useRef, useState } from "react";
import { useAlert } from "react-alert";
import Cookies from "js-cookie";
// assets
import arrowUp from "assets/icons/arrowUp.svg";
import arrowDown from "assets/icons/arrowDown.svg";
// components
import { useUpdate } from "ctx/UpdateContext";
import { useApi } from "ctx/ApiContext";
import { useCard } from "ctx/CardRasterContext";
import Paper from "@material-ui/core/Paper";
import Grow from "@material-ui/core/Grow";
import { CardRasterProps } from "types/InterfaceCard";
import { SectionRomaneiosProps } from "types/InterfaceRomaneio";
// styles
import styles from "./styles.module.scss";
import InfoForms from "components/infoForms";

type RomaneiosProps = {
  romaneio: SectionRomaneiosProps;
  reference: any;
  setParam: (value: number) => void;
};

// controler open or close in popup
export default function UserForms() {
  const alert = useAlert();
  const company = Cookies.get("company");
  const [isExpand, setIsExpand] = useState(false);
  const { refreshPage } = useUpdate();
  const [totalFinishDemands, setTotalFinishDemands] = useState(0);
  // controller open or close in popup
  const [open, setOpen] = useState(false);
  const closeModal = useCallback(() => {
    setOpen(false);
  }, [open]);
  const [openRomaneio, setOpenRomaneio] = useState(true);
  const [dataRomaneiosCard, setDataRomaneiosCard] = useState<CardRasterProps[]>(
    []
  );
  const [cardIdWillNotChange, setCardIdWillNotChange] = useState<
    CardRasterProps[]
  >([]);
  const updateRomaneioToStatusToAWainting = useRef<any>(true);
  const allowFinish = useRef<any>(0);
  const uid = useRef<any>(null);
  const { driversRM } = useApi();
  const { allDocs } = useCard();
  const drivers = driversRM;
  var totalF = 0;

  // const useStyles = makeStyles((theme: Theme) =>
  //     createStyles({
  //         root: {
  //             height: 180,
  //         },
  //         container: {
  //             display: 'flex',
  //         },
  //         paper: {
  //             margin: theme.spacing(1),
  //         },
  //         svg: {
  //             width: 100,
  //             height: 100,
  //         },
  //     }),
  // );

  // const classes = useStyles();

  // useEffect(() => {
  //     if (romaneio.uid === undefined) return;

  //     for (let i = 0; i < drivers.length; i++) {
  //         if (drivers[i].document === romaneio.cpf) {
  //             uid.current = drivers[i].uid || 'empty';
  //         }
  //     }
  //     var references: any = [];
  //     var docs: any = [];

  //     for (let i = 0; i < reference.length; i++) {
  //         references.push(reference[i]._delegate.path.substr(10));
  //         for (let j = 0; j < allDocs.length; j++) {
  //             if (allDocs[j].uid === references[i]) {
  //                 docs.push(allDocs[j]);
  //                 break;
  //             }
  //         }
  //     }

  //     if (romaneio.status === 2) {
  //         updateRomaneioToStatusToAWainting.current = true;
  //         for (var i = 0; i < docs.length; i++) {
  //             if (docs[i].status === 3) {
  //                 updateRomaneioToStatusToAWainting.current = false;
  //             }
  //         }
  //         if (updateRomaneioToStatusToAWainting.current) {
  //             UpdateStatusRomaneioToWainting(romaneio.uid);
  //             refreshPage();
  //         }
  //     }

  //     if (romaneio.status === 0 && updateRomaneioToStatusToAWainting.current) {
  //         for (var i = 0; i < docs.length; i++) {
  //             if (docs[i].status === 5) {
  //                 UpdateStatusRomaneioToWainting(romaneio.uid);
  //                 refreshPage();
  //                 break;
  //             }
  //         }
  //     }

  //     for (let i = 0; i < docs.length; i++) {
  //         if (docs[i].status === 4) totalF = totalF + 1;
  //         if (docs[i].status === 4 || docs[i].status === 7) {
  //             allowFinish.current = allowFinish.current + 1;
  //         }
  //     }

  //     // whether finished_demand in romaneio it is outdated, is set new value to finished_demands
  //     if (romaneio.finished_demands < totalF) {
  //         if (romaneio.uid && romaneio.status !== 3) {
  //             UpdateStatusTotalFinishRomaneio(romaneio.uid, totalF);
  //             refreshPage();
  //         }
  //     }

  //     loadData(docs);

  //     if ((allowFinish.current === romaneio.cardsRomaneios.length) && romaneio.status !== 4 && romaneio.status !== 3) {
  //         UpdateStatusRomaneioToDemandsDone(romaneio?.uid)
  //     }

  //     return () => {
  //         docs = [];
  //     }
  // }, [reference]);

  // function loadData(cards: CardRasterProps[]) {
  //     var allDocumentsIntoRomaneio = cards.sort((a: any, b: any) => a.id > b.id ? 1 : -1);
  //     setDataRomaneiosCard(allDocumentsIntoRomaneio);
  //     setCardIdWillNotChange(allDocumentsIntoRomaneio);
  //     setTotalFinishDemands(totalF);
  // }

  // async function removeDocumentIntoRomaneio(card: CardRasterProps) {
  //     if (romaneio.uid !== undefined) {
  //         const db = FirebaseConfig.firestore();

  //         var CardToBeUpdatedIntoRomaneio: any = [];
  //         var references: any = [];

  //         for (let i = 0; i < dataRomaneiosCard.length; i++) {
  //             if (dataRomaneiosCard[i] !== card) {
  //                 CardToBeUpdatedIntoRomaneio.push(dataRomaneiosCard[i]);
  //             }
  //         }

  //         for (let i = 0; i < CardToBeUpdatedIntoRomaneio.length; i++) {
  //             const formart = db.doc('documents/' + CardToBeUpdatedIntoRomaneio[i].uid);
  //             references.push(formart);
  //         }
  //         const number = references.length;
  //         await UpdateCardsToOutRomaneio(card);
  //         await UpdateCardRomaneiosField(number, references, romaneio.uid);
  //         refreshPage();
  //     }
  // }

  // function handlePopup() {
  //     setOpenRomaneio(!openRomaneio);
  // }

  // function handlePositionOfCards() {
  //     if (dataRomaneiosCard === undefined) return;

  //     if (cardIdWillNotChange.length > 0) {
  //         for (let i = 0; i < cardIdWillNotChange.length; i++) {
  //             if (dataRomaneiosCard[i].id !== cardIdWillNotChange[i].id) {
  //                 UpdatePositionIdCard(dataRomaneiosCard[i].uid, cardIdWillNotChange[i].id);
  //             }
  //         }
  //     }
  //     refreshPage();
  // }

  // async function handleFinishRomaneio() {
  //     for (let i = 0; i < drivers.length; i++) {
  //         if (drivers[i].document === romaneio.cpf) {
  //             uid.current = drivers[i].uid;
  //             break;
  //         }
  //     }
  //     if (romaneio.uid !== undefined) UpdateStatusRomaneioToDone(romaneio.uid);

  //     alert.success(romaneio.general_data + " FINALIZADO!");
  //     for (let i = 0; i < dataRomaneiosCard.length; i++) {
  //         if (dataRomaneiosCard[i].status === 7) {
  //             // await UpdateCardsToOutRomaneioAndRemoveStatusReturn(dataRomaneiosCard[i]);
  //             const data = {
  //                 id: allDocs.length + 1,
  //                 cod: dataRomaneiosCard[i].cod,
  //                 nameDocument: dataRomaneiosCard[i].nameDocument,
  //                 client: {
  //                     name: dataRomaneiosCard[i].client.name,
  //                     fone: dataRomaneiosCard[i].client.fone,
  //                     cpf_cnpj: dataRomaneiosCard[i].client.cpf_cnpj
  //                 },
  //                 origin: {
  //                     address: dataRomaneiosCard[i].origin.address,
  //                     state: dataRomaneiosCard[i].origin.state,
  //                     citie: dataRomaneiosCard[i].origin.citie,
  //                 },
  //                 clientDestiny: dataRomaneiosCard[i].clientDestiny,
  //                 destiny: {
  //                     addressDestiny: dataRomaneiosCard[i].destiny.addressDestiny,
  //                     stateDestiny: dataRomaneiosCard[i].destiny.stateDestiny,
  //                     citieDestiny: dataRomaneiosCard[i].destiny.citieDestiny,
  //                 },
  //                 timeToExitSender: dataRomaneiosCard[i].timeToExitSender,
  //                 timeToArrivalSender: dataRomaneiosCard[i].timeToArrivalSender,
  //                 timeToArrival: dataRomaneiosCard[i].timeToArrival,
  //                 status: 1,
  //                 intoRomaneio: false,
  //                 type: dataRomaneiosCard[i].type,
  //                 currentRomaneio: '',
  //                 company: company,
  //                 history: dataRomaneiosCard[i].history,
  //                 oldDocumentLocation: 'Romaneios/' + romaneio.general_data
  //             }
  //             await CreateDocument(data);
  //         } else {
  //             await UpdateCardsToOutRomaneio(dataRomaneiosCard[i]);
  //         }
  //     }
  //     setParam(2);
  //     try {
  //         UseUpdateDriversRasterMB(uid.current, 0);
  //     } catch (e) {
  //         alert.error(romaneio.driver + " ERRO AO LIBERAR MOTORISTA");
  //         console.log(e);
  //     }
  // }
  return (
    <>
      <div
        className={
          isExpand ? `${styles.main} ${styles.mainFocus}` : styles.main
        }
      >
        <section>
          <div className={styles.blockInfo}>
            <img
              src={isExpand ? arrowUp : arrowDown}
              style={{ zIndex: 1 }}
              onClick={() => {
                setIsExpand(!isExpand);
              }}
              alt="close and open form"
            />
          </div>
          <div className={styles.blockFistData}>
            <span>Data: 09/09/2021</span>
          </div>
          <div className={styles.blockFistData}>
            <span>Formulário</span>
          </div>
          <div className={styles.blockFistData}>
            <span>Data/Hora Conclusão</span>
          </div>
          <div className={styles.blockInfo}>
            <span id="vehicle">Usuários</span>
          </div>
          <div className={styles.blockInfo}>
            <span>Ações</span>
          </div>
        </section>
      </div>
      {isExpand && (
        <>
          <Grow in={isExpand}>
            <Paper elevation={1}>
              <InfoForms />
            </Paper>
          </Grow>
          <Grow
            in={isExpand}
            style={{ transformOrigin: "0 0 0" }}
            {...(isExpand ? { timeout: 1000 } : {})}
          >
            <Paper elevation={1}>
              <InfoForms />
            </Paper>
          </Grow>
          <Grow
            in={isExpand}
            style={{ transformOrigin: "0 0 0" }}
            {...(isExpand ? { timeout: 2000 } : {})}
          >
            <Paper elevation={1}>
              <InfoForms />
            </Paper>
          </Grow>
        </>
      )}
    </>
  );
}

// export default React.memo(UserForms, function areEqual(
//     prevProps,
//     nextProps
// ) {
//     if (prevProps.romaneio !== nextProps.romaneio) {
//         return false
//     }

//     return true
// })
