import { useEffect } from 'react'
import Cookies from 'js-cookie';

import DocumentsView from '../DocumentsView';
import { OccurrenteProps } from 'components/Occurences';

import { DriverProps } from 'types/interfaceDriver';

import { useApi } from 'ctx/ApiContext';
import { useAuth } from 'ctx/AuthContext';
import { useUpdate } from 'ctx/UpdateContext';

import useGetDrivers from 'hooks/useGetDrivers';
import useGetRomaneios from 'hooks/useGetRomaneios';
import useGetDriversRM from 'hooks/useGetDriversRM';
import { UseGetNotification } from 'hooks/useGetNotification';
import { useGetAllOcurrences } from 'hooks/useCreateOcurrence';
import { getAllLastMessages } from 'hooks/useMessages';
import { usePagination } from 'ctx/PaginationContext';
import { db } from 'database/FirebaseConfig';
import { useGetFinalizations } from 'hooks/createFinalization';
import { FinalizationProps } from 'components/Finalizations';

function Index() {

    const company = Cookies.get('company');

    const {
        loadDrivers,
        loadFinish,
        loadOccurrences,
        loadOccurrencesDescriptions,
        loadTotalNotifications,
        loadDriversRM,
        loadNotifications,
        setAllNotifications,
        loadFinalizations,
    } = useApi();
    const { unsubscribeGetWaybills, pagination, setLastVisibleDoc, setPreviousLastVisibleDoc } = usePagination();
    const { loadCurrentUser, userInfo } = useAuth();

    const driverRasterMob = useGetDriversRM();
    const drivers = useGetDrivers();
    const romaneios = useGetRomaneios();
    const allNotification = UseGetNotification();
    const occurrence = useGetAllOcurrences();
    const finalizationHook = useGetFinalizations();

    useEffect(() => {
        if (!company) return;

        const currentUser = Cookies.get('currentUser');
        loadCurrentUser(Boolean(currentUser));




        var allNotificationFinish: any = [];

        occurrence.getOcurrences({
            company,
            onComplete: (ocurrences: OccurrenteProps[]) => {
                loadOccurrencesDescriptions(ocurrences);
            }
        });

        finalizationHook.getFinalizations({
            company,
            onComplete: (finalizations: FinalizationProps[]) => {
                loadFinalizations(finalizations);
            }
        })

        allNotification.getNotifications({
            company,
            onComplete: ((notifications: any) => {
                let tam = 0;
                const allNotificationOccurence = notifications.filter((notification: any) => {
                    if (notification.finish.length > 0) allNotificationFinish.push(notification);
                    if (notification.check === false) tam++;
                    return !(notification.finish.length > 0);
                })
                loadNotifications(notifications);
                loadTotalNotifications(tam);
                loadFinish(allNotificationFinish);
                loadOccurrences(allNotificationOccurence);
            }),
        })

        const unsubscribeWaybillsSnapshot = romaneios.getWaybills(pagination, setLastVisibleDoc, -1);

        unsubscribeGetWaybills.current = unsubscribeWaybillsSnapshot;


        if (!userInfo?.selectedCompanyGroupId) return;
        const usubscribeDocumentsSnapshot = db.collection('documents').where('companyGroupId', '==', userInfo?.selectedCompanyGroupId).where('status', '==', 3).onSnapshot(snap => {
            const ocurrenceDocsData = snap.docs.map(doc => ({
                uid: doc.id,
                ...doc.data(),
            }));
            setAllNotifications(ocurrenceDocsData)
        })

        return () => { // ComponentWillUnmount in Class Component
            drivers.setLoginActivities(false);
            romaneios.setLoading(false);
            allNotification.setLoading(false);
            occurrence.setLoading(false);
            unsubscribeWaybillsSnapshot();
            if (usubscribeDocumentsSnapshot) usubscribeDocumentsSnapshot();
        }
    }, []);

    useEffect(() => {
        driverRasterMob.getDrivers({
            onComplete: (data: any) => {
                loadDriversRM(data);
            }
        })
        return () => {
            driverRasterMob.setLoading(false);
        }
    }, [company])

    return (
        <DocumentsView />
    );
}

export default Index;