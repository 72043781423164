export const Colors = {
  primary: '#00ff00',
  secondary: "#020039"
}

export const Images = {
  background: 'https://firebasestorage.googleapis.com/v0/b/raster-entrega.appspot.com/o/background-default.png?alt=media&token=f6f184bc-cf98-4a76-9efc-e7643cb5f90c',
  logo: 'https://firebasestorage.googleapis.com/v0/b/raster-entrega.appspot.com/o/logo-default.png?alt=media&token=7955bf98-cc47-4150-837c-a1e4c55e57d8',
}
export const Labels = {
  name: 'Raster',
  nameUppercase: 'RASTER',
  fone: '(49) 99988-7766',
  // typesenseDocs: "documents_raster_homolog_maikel",    //HOMOLOG
  typesenseDocs: "prod_raster_documents",     //PRODUCTION
}

export const Links = {
  website: 'rastergr.com.br',
  changePassword: 'https://us-central1-raster-position.cloudfunctions.net/updatePassword',
  changeEmail: 'https://us-central1-raster-position.cloudfunctions.net/updateEmail',
  downloadURLPreFix: 'https://firebasestorage.googleapis.com/v0/b/raster-position.appspot.com/o/',
}