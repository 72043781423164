import { useApi } from "ctx/ApiContext";
import { usePagination } from "ctx/PaginationContext";
import Cookies from "js-cookie";
import { useState } from "react";
import firebase from "../database/FirebaseConfig";
import { useAuth } from "ctx/AuthContext";

const useGetRomaneios = () => {
  const db = firebase.firestore();
  const controller = new AbortController();
  const [loading, setLoading] = useState(false);
  const { unsubscribeGetWaybills, setPreviousLastVisibleDoc, previousLastVisibleDoc } = usePagination();
  const { loadRomaneio } = useApi();
  const company = Cookies.get('company');
  const { userInfo } = useAuth();

  const getWaybillsByStatus = (pagination: number, setLastVisibleDoc: any, status: number) => {
    const unsubscribe = db.collection("waybills")
      .where("company", "==", company)
      .where("status", "==", status)
      .orderBy("updatedAt", "desc")
      .limit(pagination!)
      .onSnapshot(
        (querySnapshot: any) => {
          const waybills = querySnapshot.docs.map((doc: any) => {
            return { ...doc.data(), uid: doc.id };
          });
          loadRomaneio(waybills);
          const lastVisibleSnap = querySnapshot.docs[querySnapshot.docs.length - 1];

          if (!setLastVisibleDoc) return;
          setLastVisibleDoc(lastVisibleSnap);
        },
        (err) => {
          console.log("erro: ", err);
        }
      )
    return unsubscribe
  }

  const getWaybills = (pagination: number, setLastVisibleDoc: any, param: number, driver?: string | null) => {
    if (!userInfo?.selectedCompanyGroupId) {
      loadRomaneio([]);
      return () => { };
    }

    const unsubscribe = param === -1 ?
      !driver ?
        db.collection('waybills')
          .where('company', '==', company)
          .where('companyGroupId', '==', userInfo?.selectedCompanyGroupId)
          .orderBy('updatedAt', 'desc')
          // .startAt('')
          .limit(pagination!)
          .onSnapshot(
            (querySnapshot: any) => {
              const waybills = querySnapshot.docs.map((doc: any) => {
                return { ...doc.data(), uid: doc.id };
              });
              loadRomaneio(waybills);
              const lastVisibleSnap = querySnapshot.docs[querySnapshot.docs.length - 1];
              const lastPreviousSnap = querySnapshot.docs[0];

              if (!setLastVisibleDoc) return;
              setLastVisibleDoc(lastVisibleSnap);
            },
            (err) => {
              console.log("erro: ", err);
            }
          )
        :
        db.collection("waybills")
          .where("company", "==", company)
          .where('companyGroupId', '==', userInfo?.selectedCompanyGroupId)
          .where("document", "==", driver)
          .orderBy("updatedAt", "desc")
          // .startAt('')
          .limit(pagination!)
          .onSnapshot(
            (querySnapshot: any) => {
              const waybills = querySnapshot.docs.map((doc: any) => {
                return { ...doc.data(), uid: doc.id };
              });
              loadRomaneio(waybills);
              const lastVisibleSnap = querySnapshot.docs[querySnapshot.docs.length - 1];
              const lastPreviousSnap = querySnapshot.docs[0];

              if (!setLastVisibleDoc) return;
              setLastVisibleDoc(lastVisibleSnap);
            },
            (err) => {
              console.log("erro: ", err);
            }
          )
      :
      !driver ?
        db.collection("waybills")
          .where("company", "==", company)
          .where('companyGroupId', '==', userInfo?.selectedCompanyGroupId)
          .where("status", "==", param)
          .orderBy("updatedAt", "desc")
          // .startAt('')
          .limit(pagination!)
          .onSnapshot(
            (querySnapshot: any) => {
              const waybills = querySnapshot.docs.map((doc: any) => {
                return { ...doc.data(), uid: doc.id };
              });
              loadRomaneio(waybills);
              const lastVisibleSnap = querySnapshot.docs[querySnapshot.docs.length - 1];
              const lastPreviousSnap = querySnapshot.docs[0];

              if (!setLastVisibleDoc) return;
              setLastVisibleDoc(lastVisibleSnap);
            },
            (err) => {
              console.log("erro: ", err);
            }
          )
        :
        db.collection("waybills")
          .where("company", "==", company)
          .where('companyGroupId', '==', userInfo?.selectedCompanyGroupId)
          .where("status", "==", param)
          .where("document", "==", driver)
          .orderBy("updatedAt", "desc")
          // .startAt('')
          .limit(pagination!)
          .onSnapshot(
            (querySnapshot: any) => {
              const waybills = querySnapshot.docs.map((doc: any) => {
                return { ...doc.data(), uid: doc.id };
              });
              loadRomaneio(waybills);
              const lastVisibleSnap = querySnapshot.docs[querySnapshot.docs.length - 1];
              const lastPreviousSnap = querySnapshot.docs[0];

              if (!setLastVisibleDoc) return;
              setLastVisibleDoc(lastVisibleSnap);
            },
            (err) => {
              console.log("erro: ", err);
            }
          )
    return unsubscribe;
  }

  const getNextPageWaybills = (pagination: number, setLastVisibleDoc: any, lastVisible: any, param: number, driver: string | null) => {
    if (!lastVisible) return unsubscribeGetWaybills.current;
    const unsubscribe = param === -1 ?
      !driver ?
        db.collection("waybills")
          .where("company", "==", company)
          .where('companyGroupId', '==', userInfo?.selectedCompanyGroupId)
          .orderBy("updatedAt", "desc")
          .startAfter(lastVisible)
          .limit(pagination)
          .onSnapshot(
            (querySnapshot: any) => {
              const waybills = querySnapshot.docs.map((doc: any) => {
                return { ...doc.data(), uid: doc.id };
              });
              if (waybills.length === 0) return;
              loadRomaneio(waybills);
              const lastVisibleSnap = querySnapshot.docs[querySnapshot.docs.length - 1];
              const lastPreviousSnap = querySnapshot.docs[0];
              if (!setLastVisibleDoc) return;
              setPreviousLastVisibleDoc(lastPreviousSnap);
              setLastVisibleDoc(lastVisibleSnap);
            },
            (err) => {
              console.log("erro: ", err);
            }
          )
        :
        db.collection("waybills")
          .where("company", "==", company)
          .where('companyGroupId', '==', userInfo?.selectedCompanyGroupId)
          .where("document", "==", driver)
          .orderBy("updatedAt", "desc")
          .startAfter(lastVisible)
          .limit(pagination)
          .onSnapshot(
            (querySnapshot: any) => {
              const waybills = querySnapshot.docs.map((doc: any) => {
                return { ...doc.data(), uid: doc.id };
              });
              if (waybills.length === 0) return;
              loadRomaneio(waybills);
              const lastVisibleSnap = querySnapshot.docs[querySnapshot.docs.length - 1];
              const lastPreviousSnap = querySnapshot.docs[0];
              if (!setLastVisibleDoc) return;
              setPreviousLastVisibleDoc(lastPreviousSnap);
              setLastVisibleDoc(lastVisibleSnap);
            },
            (err) => {
              console.log("erro: ", err);
            }
          )
      :
      !driver ?
        db.collection("waybills")
          .where("company", "==", company)
          .where('companyGroupId', '==', userInfo?.selectedCompanyGroupId)
          .where("status", "==", param)
          .orderBy("updatedAt", "desc")
          .startAfter(lastVisible)
          .limit(pagination)
          .onSnapshot(
            (querySnapshot: any) => {
              const waybills = querySnapshot.docs.map((doc: any) => {
                return { ...doc.data(), uid: doc.id };
              });

              if (waybills.length === 0) return;
              loadRomaneio(waybills);
              const lastVisibleSnap = querySnapshot.docs[querySnapshot.docs.length - 1];
              const lastPreviousSnap = querySnapshot.docs[0];
              if (!setLastVisibleDoc) return;

              setPreviousLastVisibleDoc(lastPreviousSnap);
              setLastVisibleDoc(lastVisibleSnap);
            },
            (err) => {
              console.log("erro: ", err);
            }
          )
        :
        db.collection("waybills")
          .where("company", "==", company)
          .where('companyGroupId', '==', userInfo?.selectedCompanyGroupId)
          .where("document", "==", driver)
          .where("status", "==", param)
          .orderBy("updatedAt", "desc")
          .startAfter(lastVisible)
          .limit(pagination)
          .onSnapshot(
            (querySnapshot: any) => {
              const waybills = querySnapshot.docs.map((doc: any) => {
                return { ...doc.data(), uid: doc.id };
              });

              if (waybills.length === 0) return;
              loadRomaneio(waybills);
              const lastVisibleSnap = querySnapshot.docs[querySnapshot.docs.length - 1];
              const lastPreviousSnap = querySnapshot.docs[0];
              if (!setLastVisibleDoc) return;

              setPreviousLastVisibleDoc(lastPreviousSnap);
              setLastVisibleDoc(lastVisibleSnap);
            },
            (err) => {
              console.log("erro: ", err);
            }
          )
    return unsubscribe;
  }

  const getPreviousPageWaybills = (pagination: number, setLastVisibleDoc: any, param: number, driver?: string | null) => {
    if (!previousLastVisibleDoc) return unsubscribeGetWaybills.current;
    const unsubscribe = param === -1 ?
      !driver ?
        db.collection("waybills")
          .where("company", "==", company)
          .where('companyGroupId', '==', userInfo?.selectedCompanyGroupId)
          .orderBy("updatedAt", "desc")
          .endBefore(previousLastVisibleDoc)
          .limitToLast(pagination)
          .onSnapshot(
            (querySnapshot: any) => {
              const waybills = querySnapshot.docs.map((doc: any) => {
                return { ...doc.data(), uid: doc.id };
              });
              if (waybills.length === 0) return;
              loadRomaneio(waybills);
              const lastVisibleSnap = querySnapshot.docs[querySnapshot.docs.length - 1];
              const lastPreviousSnap = querySnapshot.docs[0];
              if (!setLastVisibleDoc) return;
              setLastVisibleDoc(lastVisibleSnap);
              setPreviousLastVisibleDoc(lastPreviousSnap);
            },
            (err) => {
              console.log("erro: ", err);
            }
          )
        :
        db.collection("waybills")
          .where("company", "==", company)
          .where('companyGroupId', '==', userInfo?.selectedCompanyGroupId)
          .where("driver", "==", driver)
          .orderBy("updatedAt", "desc")
          .endBefore(previousLastVisibleDoc)
          .limitToLast(pagination)
          .onSnapshot(
            (querySnapshot: any) => {
              const waybills = querySnapshot.docs.map((doc: any) => {
                return { ...doc.data(), uid: doc.id };
              });
              if (waybills.length === 0) return;
              loadRomaneio(waybills);
              const lastVisibleSnap = querySnapshot.docs[querySnapshot.docs.length - 1];
              const lastPreviousSnap = querySnapshot.docs[0];
              if (!setLastVisibleDoc) return;
              setLastVisibleDoc(lastVisibleSnap);
              setPreviousLastVisibleDoc(lastPreviousSnap);
            },
            (err) => {
              console.log("erro: ", err);
            }
          )
      :
      !driver ?
        db.collection("waybills")
          .where("company", "==", company)
          .where('companyGroupId', '==', userInfo?.selectedCompanyGroupId)
          .where("status", "==", param)
          .orderBy("updatedAt", "desc")
          .endBefore(previousLastVisibleDoc)
          .limitToLast(pagination)
          .onSnapshot(
            (querySnapshot: any) => {
              const waybills = querySnapshot.docs.map((doc: any) => {
                return { ...doc.data(), uid: doc.id };
              });
              if (waybills.length === 0) return;
              loadRomaneio(waybills);
              const lastVisibleSnap = querySnapshot.docs[querySnapshot.docs.length - 1];
              const lastPreviousSnap = querySnapshot.docs[0];
              if (!setLastVisibleDoc) return;
              setLastVisibleDoc(lastVisibleSnap);
              setPreviousLastVisibleDoc(lastPreviousSnap);
            },
            (err) => {
              console.log("erro: ", err);
            }
          )
        :
        db.collection("waybills")
          .where("company", "==", company)
          .where('companyGroupId', '==', userInfo?.selectedCompanyGroupId)
          .where("driver", "==", driver)
          .where("status", "==", param)
          .orderBy("updatedAt", "desc")
          .endBefore(previousLastVisibleDoc)
          .limitToLast(pagination)
          .onSnapshot(
            (querySnapshot: any) => {
              const waybills = querySnapshot.docs.map((doc: any) => {
                return { ...doc.data(), uid: doc.id };
              });
              if (waybills.length === 0) return;
              loadRomaneio(waybills);
              const lastVisibleSnap = querySnapshot.docs[querySnapshot.docs.length - 1];
              const lastPreviousSnap = querySnapshot.docs[0];
              if (!setLastVisibleDoc) return;
              setLastVisibleDoc(lastVisibleSnap);
              setPreviousLastVisibleDoc(lastPreviousSnap);
            },
            (err) => {
              console.log("erro: ", err);
            }
          )
    return unsubscribe;
  }

  const getRomaneios = async ({ onComplete, company }: any) => {
    setLoading(true);
    db.collection("waybills")
      .orderBy("status", "asc")
      .orderBy("finishedAt", "desc")
      .where("company", "==", company)
      .where('companyGroupId', '==', userInfo?.selectedCompanyGroupId)
      .onSnapshot(
        (querySnapshot: any) => {
          const waybills = querySnapshot.docs.map((doc: any) => {
            return { ...doc.data(), uid: doc.id };
          });
          onComplete(waybills);
          setLoading(false);
        },
        (err) => {
          setLoading(false);
          console.log("erro: ", err);
        }
      );
    return () => {
      const timeout = setTimeout(() => controller.abort(), 5000);
      clearTimeout(timeout);
    };
  };

  return { loading, getRomaneios, setLoading, getWaybills, getNextPageWaybills, getPreviousPageWaybills, getWaybillsByStatus };
};
const useGetRomaneiosCards = () => {
  const db = firebase.firestore();
  const controller = new AbortController();
  const [loading, setLoading] = useState(false);

  const getRomaneiosCards = async ({ onComplete, uid }: any) => {
    setLoading(true);
    db.collection("cardsRomaneios")
      .where("fk_romaneios", "==", uid)
      .get()
      .then((querySnapshot: any) => {
        const romaneios = querySnapshot.docs.map((doc: any) => {
          return {
            ...doc.data(),
            uid: doc.id,
          };
        });
        onComplete(romaneios);
        setLoading(false);
      })
      .catch((error: any) => {
        setLoading(false);
        console.log("erro: ", error);
      });

    return () => {
      const timeout = setTimeout(() => controller.abort(), 5000);
      clearTimeout(timeout);
    };
  };

  return { loading, getRomaneiosCards };
};

export { useGetRomaneiosCards, useGetRomaneios as default };
