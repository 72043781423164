export const countries = {
  Argentina: "AR",
  Bolívia: "BO",
  Brasil: "BR",
  Chile: "CL",
  Colômbia: "CO",
  Equador: "EC",
  Paraguai: "PY",
  Peru: "PE",
  Uruguai: "UY",
}

const headers = new Headers();
headers.append("X-CSCAPI-KEY", "NVNQWlhWUFp4NHhOb29EMHJZdllaZDBtWmJCcHp2ZkFJNGdQRG0yRA==");

const requestOptions = {
  method: 'GET',
  headers: headers,
  // redirect: 'follow'
};

export async function getStates(country: string) {
  try {
    const response = await fetch(`https://api.countrystatecity.in/v1/countries/${countries[country as keyof typeof countries]}/states`, requestOptions)
    const result = await response.text()
    return JSON.parse(result)
  } catch (error) {
    console.log('error', error)
  }
}

export async function getCities(country: string, stateIso2: string) {
  try {
    const response = await fetch(`https://api.countrystatecity.in/v1/countries/${countries[country as keyof typeof countries]}/states/${stateIso2}/cities`, requestOptions)
    const result = await response.text()
    const allStatesNameByCountry = JSON.parse(result).map((stateObject: any) => stateObject.name)
    return allStatesNameByCountry
  } catch (error) {
    console.log('error', error)
  }
}
