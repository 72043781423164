import { Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { db } from "database/FirebaseConfig";
import { useContext, useState } from "react";
import timestampToDate from "utils/timestampToDate";
import { AuthContext } from "ctx/AuthContext";
import checkbox from 'assets/icons/checkTrue.svg';
import { useAlert } from "react-alert";
import CsvDownloader from 'react-csv-downloader';

import { AiOutlineDownload } from "react-icons/ai";
import filter from "assets/icons/filter.svg";

interface CompanyDataTable {
  key: number;
  numero: number;
  motorista: string;
  veiculo: string;
  documento: string;
  cliente: string;
  tipo: string;
  evento: string;
  data_prevista: Date;
  data: Date;
}

export function ReportTable() {
  const [documentsFetched, setDocumentsFetched] = useState<any>();
  const [param, setParam] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [initialDate, setInitialDate] = useState<any>();
  const [finalDate, setFinalDate] = useState<any>();
  const { userInfo } = useContext(AuthContext);
  const Alert = useAlert()

  async function loadTableData() {
    if (!userInfo || !userInfo?.company) return;
    if (initialDate === '' || !initialDate || finalDate === '' || !finalDate) {
      Alert.error('Datas iniciais e finais obrigatórioas para gerar o relatório')
      return
    }
    const dateInitial = new Date(initialDate)
    const dateFinal = new Date(finalDate)
    try {
      setLoading(true);
      let docsSnapshot
      if (param === 0) {
        docsSnapshot = await db.collection('documents')
          .where('company', '==', userInfo?.company)
          .where('status', '==', 4)
          .where('timeToArrival', '>=', dateInitial)
          .where('timeToArrival', '<', dateFinal)
          .orderBy('timeToArrival', 'asc')
          .get()
      } else {
        docsSnapshot = await db.collection('documents')
          .where('company', '==', userInfo?.company)
          .where('type', '==', param)
          .where('status', '==', 4)
          .where('timeToArrival', '>=', dateInitial)
          .where('timeToArrival', '<', dateFinal)
          .orderBy('timeToArrival', 'asc')
          .get()
      }

      let docsData = []
      let waybillsFetched: any = []
      for (const doc of docsSnapshot.docs) {
        const document = doc.data();
        const documentId = doc.id;
        const dataToString = timestampToDate(document.timeToArrival);
        let waybill = waybillsFetched.find((waybill: any) => waybill?.general_data === document.currentRomaneio)
        if (!waybill) {
          const waybillSnapshot = await db.collection('waybills').where('general_data', '==', document.currentRomaneio).get();
          const waybillData = waybillSnapshot?.docs[0]?.data();
          waybill = waybillData
          waybillsFetched.push(waybillData)
        }

        const eventSnapshot = await db.collection('documents').doc(documentId).collection('documentEvents').where('status', '==', 5).get()
        const finishEvent = eventSnapshot?.docs[0]?.data()?.createdAt
        const finishEventDate = finishEvent ? timestampToDate(finishEvent) : timestampToDate(waybill?.finishedAt)

        docsData.push({
          key: document.cod,
          numero: waybill?.general_data,
          motorista: waybill?.driver,
          veiculo: waybill?.vehicle,
          documento: document.cod,
          cliente: document.client.name,
          tipo: document.type === 1 ? 'Entrega' : 'Coleta',
          evento: document.status === 4 ? 'Finalizado' : '',
          data_prevista: dataToString,
          data: finishEventDate ?? '-',
        })
      }
      docsData = docsData.filter(doc => doc.numero !== undefined && doc.numero !== '');
      setLoading(false);
      setDocumentsFetched([...docsData])
    } catch (error) {
      setLoading(false)
      console.log(error)
      Alert.error(JSON.stringify(error))
    }
  }

  const columns: ColumnsType<CompanyDataTable> = [
    {
      title: 'Número',
      dataIndex: 'numero',
      key: 'numero',
    },
    {
      title: 'Motorista',
      dataIndex: 'motorista',
      key: 'motorista',
      width: 200,
      // align: 'center',
    },
    {
      title: 'Veículo',
      dataIndex: 'veiculo',
      key: 'veiculo',
    },
    {
      title: 'Documento',
      dataIndex: 'documento',
      key: 'documento',
    },
    {
      title: 'Cliente',
      dataIndex: 'cliente',
      key: 'cliente',
    },
    {
      title: 'Tipo',
      dataIndex: 'tipo',
      key: 'tipo',
    },
    {
      title: 'Evento',
      dataIndex: 'evento',
      key: 'evento',
    },
    {
      title: 'Data prevista',
      dataIndex: 'data_prevista',
      key: 'data_prevista',
    },
    {
      title: 'Data',
      dataIndex: 'data',
      key: 'data',
    },
  ];
  return (
    <section>
      <div className="relatorioTitle">
        <div className="inputTime" style={{ display: "flex" }}>
          <input
            value={initialDate}
            required
            type="date"
            onChange={(event) => setInitialDate(event.target.value)}
          />
        </div>
        <div className="inputTime" style={{ display: "flex" }}>
          <input
            value={finalDate}
            required
            type="date"
            min={initialDate}
            onChange={(event) => setFinalDate(event.target.value)}
          />
        </div>
        <button className="reportButton" onClick={loadTableData}>
          gerar relatório
        </button>
        <div className="block-checkbox">
          <img src={filter} alt="search" />
          <span>Filtrar:</span>

          <div className="input-checkbox">
            <input type="checkbox" onClick={() => {
              setParam(0);
            }} />
            <label>Ver todos</label>
            {param === 0 && (
              <img src={checkbox} alt="checkbox true" />
            )}
          </div>
          <div className="input-checkbox">
            <input type="checkbox" onClick={() => {
              setParam(1);
            }} />
            <label>Coleta</label>
            {param === 1 && (
              <img src={checkbox} alt="checkbox true" />
            )}
          </div>
          <div className="input-checkbox">
            <input type="checkbox" onClick={() => {
              setParam(2);
            }} />
            <label>Entrega</label>
            {param === 2 && (
              <img src={checkbox} alt="checkbox true" />
            )}
          </div>
        </div>
      </div>

      <div className="tableContainer">
        <CsvDownloader datas={documentsFetched} filename={`${initialDate}-${finalDate}_report`} extension=".xlsx">
          <div className="downloadContainer">
            <AiOutlineDownload fontSize={20} />
            <p>fazer download</p>
          </div>
        </CsvDownloader>
        <Table dataSource={documentsFetched} columns={columns} pagination={false} style={{ margin: '0 auto' }} size="small" loading={loading} />
      </div>
    </section>

  );
}