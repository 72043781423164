import { storage } from "../database/FirebaseConfig";
import { UseUpdateXMLDocument } from "./useUpdateCard";

async function handleUpload(
  document: any,
  type: string,
  company: string,
  setUrlFile: any
) {
  let uploadTask: any;
  if (document.type === "text/csv") {
    uploadTask = storage.ref(`${company}/CSV/${type}.csv`).put(document);
  } else {
    uploadTask = storage.ref(`${company}/XML/${type}.xml`).put(document);
  }

  return new Promise((resolve, reject) => {
    uploadTask.on(
      "state_changed",
      (snapshot: any) => {},
      (error: any) => {
        reject(error);
      },
      () => {
        storage
          .ref(`${company}/XML`)
          .child(type)
          .getDownloadURL()
          .then((url) => {
            setUrlFile(url);
            resolve(url);
          });
      }
    );
  });
}

const UseGetXML = async (type: string, company: string) => {
  var METADADOS;

  storage
    .ref()
    .child(`${company}/XML/${type}`)
    .getDownloadURL()
    .then((url) => {
      var xhr = new XMLHttpRequest();
      xhr.responseType = "blob";
      xhr.onload = (event) => {
        var blob = xhr.response;
        console.log(xhr);
        METADADOS = blob;
      };
      xhr.open("GET", url);
      xhr.send();

      var textarea: any = document.getElementById("xmlTag");
      textarea.setAttribute("src", url);
    })
    .catch((error) => {
      // Handle any errors
    });
  return METADADOS;
};

export { handleUpload, UseGetXML };
