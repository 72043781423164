import firebase from "../database/FirebaseConfig";

// interface
import { SectionRomaneiosProps } from "../types/InterfaceRomaneio";

function UpdateAllFields(romaneio: SectionRomaneiosProps, uid: string) {
  const db = firebase.firestore();

  db.collection("waybills")
    .doc(uid)
    .update(romaneio)
    .then(() => {
      console.log("Profile Successfully Edited!");
    })
    .catch((error: any) => {
      console.log("Error updating the document:", error);
    });
}

function UpdateStatusRomaneioToWainting(uid: string) {
  const db = firebase.firestore();

  db.collection("waybills")
    .doc(uid)
    .update({
      status: 0,
    })
    .then(() => {
      console.log("Profile Successfully Edited!");
    })
    .catch((error: any) => {
      console.log("Error updating the document:", error);
    });
}

function UpdateStatusRomaneioToDemandsDone(uid: string) {
  const db = firebase.firestore();

  db.collection("waybills")
    .doc(uid)
    .update({
      status: 3,
    })
    .then(() => {
      console.log("Profile Successfully Edited!");
    })
    .catch((error: any) => {
      console.log("Error updating the document:", error);
    });
}
function UpdateStatusRomaneioOccurrence(uid: string) {
  const db = firebase.firestore();

  db.collection("waybills")
    .doc(uid)
    .update({
      status: 2,
    })
    .then(() => {
      console.log("Profile Successfully Edited!");
    })
    .catch((error: any) => {
      console.log("Error updating the document:", error);
    });
}

function UpdateStatusRomaneioDelivery(uid: string) {
  const db = firebase.firestore();

  db.collection("waybills")
    .doc(uid)
    .update({
      status: 1,
    })
    .then(() => {
      console.log("Profile Successfully Edited!");
    })
    .catch((error: any) => {
      console.log("Error updating the document:", error);
    });
}

function UpdateStatusRomaneioToDone(uid: string) {
  const db = firebase.firestore();
  const date = new Date();

  db.collection("waybills")
    .doc(uid)
    .update({
      status: 4,
      finishedAt: date,
    })
    .then(() => {
      console.log("Profile Successfully Edited!");
    })
    .catch((error: any) => {
      console.log("Error updating the document:", error);
    });
}

function UpdateStatusTotalFinishRomaneio(uid: string, totalFinish: number) {
  const db = firebase.firestore();

  db.collection("waybills")
    .doc(uid)
    .update({
      finished_demands: totalFinish,
    })
    .then(() => {
      console.log("Profile Successfully Edited!");
    })
    .catch((error: any) => {
      console.log("Error updating the document:", error);
    });
}

export {
  UpdateAllFields,
  UpdateStatusRomaneioOccurrence,
  UpdateStatusRomaneioToWainting,
  UpdateStatusRomaneioDelivery,
  UpdateStatusTotalFinishRomaneio,
  UpdateStatusRomaneioToDemandsDone,
  UpdateStatusRomaneioToDone,
};
