import useGetRomaneios from 'hooks/useGetRomaneios';
import Cookies from 'js-cookie';
import { createContext, ReactNode, useContext, useEffect, useRef, useState } from 'react';

interface PaginationContextProps {
  pagination: number;
  setPagination: (pag: number) => void;
  lastVisibleDoc: any;
  setLastVisibleDoc: (doc: any) => void;
  previousLastVisibleDoc: any;
  setPreviousLastVisibleDoc: (doc: any) => void;
  unsubscribeGetWaybills: React.MutableRefObject<() => void>;
  status: number;
  setStatus: (status: number) => void;
}

interface PaginationProvider {
  children: ReactNode;
}

const PaginationContext = createContext({} as PaginationContextProps);

export function PaginationProvider({ children }: PaginationProvider) {
  const [pagination, setPagination] = useState(30);
  const [lastVisibleDoc, setLastVisibleDoc] = useState<any>();
  const [status, setStatus] = useState<number>(-1);
  const [previousLastVisibleDoc, setPreviousLastVisibleDoc] = useState<any>();

  const unsubscribeGetWaybills = useRef<any>(null);
  const waybills = useGetRomaneios();

  const company = Cookies.get('company');

  useEffect(() => {
    if (!company || !unsubscribeGetWaybills.current) return;
    unsubscribeGetWaybills.current();
    const unsubscribeGetRomaneiosNew = waybills.getWaybills(pagination, setLastVisibleDoc, status)
    unsubscribeGetWaybills.current = unsubscribeGetRomaneiosNew;

    return unsubscribeGetRomaneiosNew;
  }, [pagination])


  return (
    <PaginationContext.Provider value={{
      pagination,
      setPagination,
      lastVisibleDoc,
      setLastVisibleDoc,
      previousLastVisibleDoc,
      setPreviousLastVisibleDoc,
      unsubscribeGetWaybills,
      status,
      setStatus,
    }}>
      {children}
    </PaginationContext.Provider>
  )
}

export const usePagination = () => {
  return useContext(PaginationContext);
}