import { db } from '../database/FirebaseConfig';

function useUpdateDriversRasterMB(uid: string, access: number) {

    db.collection('users').doc(uid).update({
        'access.delivery': access,
    }).then(() => {
        console.log('Profile Successfully Edited!');
    }).catch((error: any) => {
        console.log('Error updating the document:', error);
    })

}

export {
    useUpdateDriversRasterMB
};