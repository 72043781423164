import { useState } from "react";
import firebase from "../database/FirebaseConfig";

const useGetDocuments = () => {
  const db = firebase.firestore();
  const controller = new AbortController();
  const [loadingActivities, setLoginActivities] = useState(false);

  const getDocuments = async ({ onComplete, company }: any) => {
    setLoginActivities(true);
    const unsubscribe = db.collection("documents")
      .where("company", "==", company)
      .onSnapshot(
        (querySnapshot: any) => {
          const documents = querySnapshot.docs.map((doc: any) => {
            return { ...doc.data(), uid: doc.id };
          });
          onComplete(documents);
          setLoginActivities(false);
        },
        (err) => {
          setLoginActivities(false);
          console.log("erro: ", err);
        }
      );
    return () => {
      const timeout = setTimeout(() => controller.abort(), 5000);
      clearTimeout(timeout);
      unsubscribe();
    };
  };
  return { loadingActivities, getDocuments, setLoginActivities };
};

const UseGetOneDocument = () => {
  const db = firebase.firestore();
  const controller = new AbortController();
  const [loadingActivities, setLoginActivities] = useState(false);

  const getDocument = async (props: any) => {
    const ref = db.doc("documents/" + props.reference);

    setLoginActivities(true);
    ref
      .get()
      .then((querySnapshot: any) => {
        const data = { ...querySnapshot.data(), uid: querySnapshot.id };
        props.onComplete(data);
        setLoginActivities(false);
      })
      .catch((error: any) => {
        setLoginActivities(false);
        console.log("erro: ", error);
      });
    return () => {
      const timeout = setTimeout(() => controller.abort(), 5000);
      clearTimeout(timeout);
    };
  };

  return { loadingActivities, getDocument, setLoginActivities };
};

const GetAllDocuments = () => {
  const db = firebase.firestore();
  const controller = new AbortController();
  const [loadingActivities, setLoginActivities] = useState(false);

  const getDocuments = async ({ company, onComplete }: any) => {
    setLoginActivities(true);
    db.collection("documents")
      .where("company", "==", company)
      .get()
      .then((querySnapshot: any) => {
        const documents = querySnapshot.docs.map((doc: any) => {
          return { ...doc.data(), uid: doc.id };
        });
        onComplete(documents);
        setLoginActivities(false);
      })
      .catch((error: any) => {
        setLoginActivities(false);
        console.log("erro: ", error);
      });
    return () => {
      const timeout = setTimeout(() => controller.abort(), 5000);
      clearTimeout(timeout);
    };
  };

  return { loadingActivities, getDocuments, setLoginActivities };
};
const GetDocumentTotracking = () => {
  const db = firebase.firestore();
  const controller = new AbortController();
  const [loadingActivities, setLoginActivities] = useState(false);

  const getDocuments = async ({ id, onComplete }: any) => {
    setLoginActivities(true);
    db.collection("documents")
      .where("client.cpf_cnpj", "==", id)
      .get()
      .then((querySnapshot: any) => {
        const documents = querySnapshot.docs.map((doc: any) => {
          return { ...doc.data(), uid: doc.id };
        });
        onComplete(documents);
        setLoginActivities(false);
      })
      .catch((error: any) => {
        setLoginActivities(false);
        console.log("erro: ", error);
      });
    return () => {
      const timeout = setTimeout(() => controller.abort(), 5000);
      clearTimeout(timeout);
    };
  };

  return { loadingActivities, getDocuments, setLoginActivities };
};
// get documents into Romaneio
function GetDocument() {
  const controller = new AbortController();
  const [loadingActivities, setLoginActivities] = useState(false);
  var documents: any = [];

  const getDocuments = async (props: any) => {
    for (let i = 0; i < props.reference.length; i++) {
      props.reference[i]
        .get()
        .then((res: any) => {
          const data = { ...res.data(), uid: res.id };
          if (data.type !== 7) {
            documents.push(data);
          }
          setLoginActivities(false);
          if (i === props.reference.length - 1) {
            props.onComplete(documents);
          }
        })
        .catch((error: any) => {
          setLoginActivities(false);
          console.log("erro: ", error);
        });
    }
    return () => {
      const timeout = setTimeout(() => controller.abort(), 5000);
      clearTimeout(timeout);
    };
  };
  return { loadingActivities, getDocuments, setLoginActivities };
}

export {
  UseGetOneDocument,
  GetDocumentTotracking,
  GetDocument,
  GetAllDocuments,
  useGetDocuments as default,
};
