// assets 
import travelingDelivery from '../assets/icons/travelingDelivery.svg';
import waiting from '../assets/icons/waiting.svg';
import warning from '../assets/icons/warning.svg';
import finish from '../assets/icons/finish.svg';

import { useEffect, useState } from 'react';

import styled from 'styled-components';

type TpeIconCardProps = {
    typeImage: number;
    hasWarningCard: boolean;
}

// 1 - in traveling to delivery

const Div = styled.div`
display: flex;
align-items: center;
`;


export default function TypeIconCardRomaneios({ typeImage, hasWarningCard }: TpeIconCardProps) {

    const [image, setImage] = useState<string>();
    const [msg, setMsg] = useState<string>();

    useEffect(() => {
        if (typeImage === 1) {
            setImage(travelingDelivery);
            setMsg('Em trânsito');
        }
        if (typeImage === 0) {
            setImage(waiting);
            setMsg('Aguardando');
        }
        if (typeImage === 3) {
            setImage(waiting);
            setMsg('A finalizar');
        }
        if (typeImage === 4) {
            setImage(finish);
            setMsg('Finalizado');
        }
        if (typeImage === 2 || hasWarningCard) {
            setImage(warning);
            setMsg('Ocorrência');
        }
    }, [typeImage, hasWarningCard]);

    return (
        <Div>
            <img src={image} alt={msg} style={{ paddingRight: '6px' }} />
            <p style={{ fontSize: '0.85rem', margin: 0 }}>{msg}</p>
        </Div>
    );
}