import firebase from '../database/FirebaseConfig';

const db = firebase.firestore();

async function useCreateDocument(data: any) {
    var uid = '';

    await db
        .collection('documents')
        .add(data)
        .then((ref: any) => {
            uid = ref.id;
        });

    return uid;
}

function useDeleteDocuments(data: any) {
    for (let i = 0; i < data.length; i++) {
        console.log(data[i].uid);
        removeRow(data[i].uid);

    }
}

function removeRow(uid: string) {
    db.collection('documents').doc(uid).delete()
}
export { useCreateDocument as default, useDeleteDocuments };