import { UserProps } from "components/ManagerAccounts";
import { UserInfoProps } from "ctx/AuthContext";
import { useEffect, useState } from "react";
import firebase from "../database/FirebaseConfig";
import Cookies from "js-cookie";

const db = firebase.firestore();

type GetUserInfoProps = {
  uid: string;
};

const useGetUsers = () => {
  const controller = new AbortController();
  const [loading, setLoading] = useState(false);
  const company = Cookies.get("company");

  const getUsers = async ({ login, onComplete }: any) => {
    setLoading(true);
    const username = login
      .replaceAll(".", "")
      .replaceAll("-", "")
      .replace("/", "");
    db.collection("users")
      .where("access", "==", 1)
      .where("cpf_cnpj", "==", username)
      .where("company", "==", company)
      .get()
      .then((querySnapshot: any) => {
        const users = querySnapshot.docs.map((doc: any) => doc.data());
        onComplete(users);
        setLoading(false);
      })
      .catch((error: any) => {
        setLoading(false);
        console.log("erro: ", error);
      });

    return () => {
      const timeout = setTimeout(() => controller.abort(), 5000);
      clearTimeout(timeout);
    };
  };

  return { loading, getUsers, setLoading };
};

const useGetUserInfo = () => {
  const [loading, setLoading] = useState(false);

  const getUserInfo = async (uid: string) => {
    setLoading(true);

    const doc = await db.collection("users").doc(uid).get();
    const userInfo = { uid: doc.id, ...doc.data() } as UserInfoProps;

    return userInfo;
  };

  return { getUserInfo };
};

const useUsers = () => {
  const [users, setUsers] = useState<any>({ loading: true });

  // useEffect(() => {
  //   const unsubscribe = db.collection("users").onSnapshot(
  //     (querySnapshot: any) => {
  //       const users = querySnapshot.docs.map((doc: any) => {
  //         return { ...doc.data(), uid: doc.id };
  //       });
  //       setUsers(users);
  //     },
  //     (err) => {
  //       console.log("erro: ", err);
  //     }
  //   );
  //   return unsubscribe;
  // }, []);

  return { users };
};

// const getManagersUsersByCompany = async (companyID: string) => {
//   let usersByCompany = [] as UserProps[];

//   await db.collection('users')
//     .where('company', '==', companyID)
//     .where('role', '==', 'manager')
//     .get()
//     .then((querySnapshot: any) => {

//       usersByCompany.push(...querySnapshot.docs.map((doc: any) => {
//         return { ...doc.data(), uid: doc.id };
//       }))
//     });

//   return usersByCompany;
// }

const getManagersUsersByCompany = async (companyID: string) => {
  let usersByCompany = [] as UserProps[];

  await db
    .collection("users")
    .where("company", "==", companyID)
    .where("role", "==", "manager")
    .get()
    .then((querySnapshot: any) => {
      usersByCompany.push(
        ...querySnapshot.docs.map((doc: any) => {
          return { ...doc.data(), uid: doc.id };
        })
      );
    });

  return usersByCompany;
};

export {
  useUsers,
  useGetUserInfo,
  getManagersUsersByCompany,
  useGetUsers as default,
};
