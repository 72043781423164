import { AiOutlineCloseCircle } from 'react-icons/ai'
import { FormEvent, useContext, useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import Cookies from 'js-cookie';

// components

// styles
import './index.scss';
import { AuthContext } from 'ctx/AuthContext';
import { Input, Select } from 'antd';
import { db } from 'database/FirebaseConfig';
import { CompanyItem } from 'components/CompanyItem';
const { Option } = Select;
type CompanyGroupsProps = {
  Target?: any;
  closeModal: any;
}

export type ManagerT = {
  id: string;
  accepteAt: any;
  access: {
    delivery: number;
    jor: number;
    position: number;
  };
  deletedAt: any;
  company: string;
  document: string;
  email: string;
  name: string;
  role: string;
  label: string;
  value: string;
}

export function CompanyGroups({ closeModal }: CompanyGroupsProps) {
  const alert = useAlert();

  const [companyName, setCompanyName] = useState('');
  const [rasterCode, setRasterCode] = useState('');
  const [managers, setManagers] = useState<ManagerT[]>([]);
  const [selectedManagers, setSelectedManagers] = useState<any[]>([]);
  const { userInfo } = useContext(AuthContext);

  const theme = Cookies.get("colorTheme");
  document.documentElement.style.setProperty('--secondary', String(theme));

  async function handleSubmit(event: FormEvent) {
    event.preventDefault();

    if (!companyName || !rasterCode) {
      alert.error('Preencha todos os campos obrigatórios! (Nome e Código)');
      return;
    }
    try {
      await db.collection('companyGroups').add({
        companyId: userInfo?.company,
        name: companyName,
        integrationCode: rasterCode,
        users: selectedManagers.reduce((acc, manager) => ({
          ...acc,
          [manager.id]: true,
        }), {})
      })
      clearFields()
      alert.success('Empresa salva com sucesso!')
    } catch (error) {
      alert.error(JSON.stringify(error))
    }
  }

  function clearFields() {
    setCompanyName('');
    setRasterCode('');
    setSelectedManagers([]);
  }

  useEffect(() => {
    const unsubscribe = db
      .collection('users').where('role', '==', 'manager')
      .where('company', '==', userInfo?.company)
      .where('deletedAt', '==', null)
      .onSnapshot(snap => {
        const data = snap.docs.map(doc => ({
          ...doc.data(),
          id: doc.id,
          label: doc.data().name,
          value: doc.data().name,
        } as ManagerT))
        setManagers(data);
      })
    return unsubscribe
  }, [])

  return (
    <div className="finalization" >
      <div className="header-finalization">
        <span>Cadastrar empresa</span>
        <AiOutlineCloseCircle onClick={() => closeModal()} size={22} style={{ cursor: 'pointer' }} />
      </div>
      <form className="form-finalization" onSubmit={handleSubmit}>
        <div style={{ marginBottom: 10 }}>
          <legend>Nome da empresa</legend>
          <Input
            value={companyName}
            placeholder="Nome da empresa"
            onChange={event => setCompanyName(event.target.value)}
          />
        </div>
        <div style={{ marginBottom: 10 }}>
          <legend>Código Raster</legend>
          <Input
            placeholder="Código de integração Raster"
            onChange={event => setRasterCode(event.target.value)}
            value={rasterCode}
            maxLength={100}
          />
        </div>
        <div style={{ marginBottom: 10 }}>
          <legend>Usuários</legend>
          <Select
            mode="multiple"
            allowClear
            value={selectedManagers}
            labelInValue
            style={{ width: '100%' }}
            placeholder="Selecione os usuários da empresa"
            optionFilterProp="label"
            onChange={(value) => {
              // console.log(value);
              setSelectedManagers(value.map((option: any) => managers.find(manager => manager.id === option.key)))
            }}
          >
            {managers.length > 0 && managers.map(manager => (
              <Option key={manager.id} value={manager.name}>
                {manager.name}
              </Option>
            ))}
          </Select>
        </div>
        <div className="button-form">
          <button type="button" onClick={() => closeModal()}>Cancelar</button>
          <button type="submit">Salvar</button>
        </div>
      </form>
      <div className="allOccurence">
        <CompanyItem closeModal={closeModal} managers={managers} />
      </div>
    </div>
  )
}