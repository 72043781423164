import { secondsToDateToClient } from 'utils/timestampToDate';

// styles 
import './index.scss';

export function Timeline({ history }: any) {
    return (
        <div className="container">
            <div className="wrapper scrollbar" id="style-1">

                <ul className="sessions">
                    {history.map((item: any, index: number) => {
                        return (
                            <li key={index} id="liId">
                                <div className="time">{secondsToDateToClient(item.createdAt.seconds)}</div>
                                <p>{item.driver}</p>
                            </li>
                        )
                    })}
                </ul>
            </div>
        </div>
    )
}