import firebase from 'firebase/app';
import "firebase/database"
import "firebase/firestore"
import "firebase/storage"
import "firebase/auth"
import "firebase/messaging";


const configHomolog = {
  apiKey: "AIzaSyBg8TmvxKxjHHhcwIl2SNHNrnoR6Q0cv0Y",
  authDomain: "raster-homolog-89931.firebaseapp.com",
  projectId: "raster-homolog-89931",
  storageBucket: "raster-homolog-89931.appspot.com",
  messagingSenderId: "949239257121",
  appId: "1:949239257121:web:fd5b3bdc318c026f97eb60",
  measurementId: "G-9YNH9KJR6N"
}

const configRaster = {
  apiKey: "AIzaSyBbpCUhcCLtVcwtCY96kiFgDWfFKycJsLs",
  authDomain: "raster-position.firebaseapp.com",
  databaseURL: "https://raster-position.firebaseio.com",
  projectId: "raster-position",
  storageBucket: "raster-position.appspot.com",
  messagingSenderId: "256820155775",
  appId: "1:256820155775:web:715764eac47dafa880f64a",
  measurementId: "G-3T4RBK38C9"
};

const app = firebase.initializeApp(configRaster);

const messaging = firebase.messaging(app);

// firebase.firestore.setLogLevel('debug'); // SET TO DISPLAY ALL API CALLS


const db = firebase.firestore(app);
const dbForms = db;

const storage = firebase.storage();


export { db, dbForms, storage, messaging, firebase as default };
