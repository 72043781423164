import { FormEvent, useEffect, useState } from "react";
import { GoChevronRight } from "react-icons/go";
import { Redirect } from 'react-router-dom';
import { AiOutlineEye, AiFillEyeInvisible } from "react-icons/ai";
import Cookies from "js-cookie";
import styled from 'styled-components';

// components
import { Companies } from "Page/PainelCompanieUsers";
import { UserProps } from "components/ManagerAccounts";

import { useAuth } from "ctx/AuthContext";

import useGetUsers from "hooks/useGetUsers";
import GetCompanie from "hooks/useGetCompanie";

import MaskCPFCNPJ from 'utils/MaskCpfCnpj';

// styles
import styles from './styles.module.scss';
import { Colors, Images } from "utils/template";


export default function Forms() {
  type WrapperProps = {
    bg: string;
  }

  const Wrapper = styled.div<Pick<WrapperProps, 'bg'>>`
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-image: url(${(props) => props.bg});
  background-size: cover;
`;
  return (
    <>
      <Wrapper bg={Images.background} >
        <img
          src={Images.logo}
          alt='Logo da marca'
        />
        <Login />
      </Wrapper>
    </>
  );

}

const Login = () => {
  const users = useGetUsers();

  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [loginResult, setStateloginResult] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  const { loadInfoUser, setStateCurrentUser, currentUser, userInfo, loadCompanieInfo, currentUserPainel, setStateCurrentUserPainel } = useAuth();

  const colorBtn = Colors.secondary;

  function handleClick(event: FormEvent) {
    event.preventDefault();
    users.getUsers({
      login,
      onComplete: (user: any) => {
        if (user.length > 0) {
          compareIt(password, user);
        } else {
          setStateloginResult(true);
        }
      }
    });
  }

  async function compareIt(passwd: string, user: UserProps) {
    const bcryptjs = require('bcryptjs');

    const validPassword = await bcryptjs.compare(passwd, user.password);
    if (validPassword) {
      loadInfoUser(user);

      // fecth a company and check wheter company is habilited
      const loadCompanie = (company: Companies) => {
        // 1 to habilited, 0 to restrict
        if (company.access.delivery === 0) {
          setStateloginResult(true);

        } else {
          loadCompanieInfo(company);
          // this is a map to each users to verify whether accounts is habilited
          for (var prop in company.users) {

            // check whether as account fetched is account in trying logged with field equal true
            if (prop === user.document) {

              if (company.users[prop]) {
                // setStateCurrentUser();
                setStateCurrentUserPainel();

              } else {
                setStateloginResult(true);
              }
            }
          }
        }
      }
      GetCompanie(user.company, loadCompanie);

    } else {
      setStateloginResult(true);
    }
  }


  useEffect(() => {
    return () => { // ComponentWillUnmount in Class Component
      users.setLoading(false);
    }
  }, [users]);

  if (currentUserPainel) {
    Cookies.set("currentUser", String(currentUserPainel));
    Cookies.set("company", String(userInfo?.company));
    Cookies.set("name", String(userInfo?.name));
    Cookies.set("cpf_cnpj", String(userInfo?.document));
    return <Redirect to="/painelForms" />
  } else {
    return (
      <div className="card-wrapper">
        <form onSubmit={handleClick} className="card">
          <div className="input-wrapper">
            <label htmlFor='identidade' className="input-label">
              CNPJ/CPF
              <span className="necessary-icon">*</span>
            </label>

            <input
              required
              id="identidade"
              type="text"
              className="input-text"
              placeholder="Digite seu CPF/CNPJ"
              maxLength={18}
              onChange={() => {
                setStateloginResult(false);
              }}
              onInput={(e: any) => {
                let doc = MaskCPFCNPJ(e.target.value, e.target, 'all')
                e.target.value = doc
                setLogin(doc);
              }}
            />
          </div>
          <div className="input-wrapper">
            <label htmlFor='senha' className="input-label">
              Senha
              <span className="necessary-icon">*</span>
            </label>

            <span>
              {showPassword ? (
                <AiOutlineEye
                  onClick={() => setShowPassword(!showPassword)}
                  className="password-view"
                />
              ) : (
                <AiFillEyeInvisible
                  onClick={() => setShowPassword(!showPassword)}
                  className="password-view"
                />
              )}
            </span>
            <input
              id="senha"
              type={showPassword ? 'text' : 'password'}
              className="input-text"
              placeholder="************"
              maxLength={18}
              required
              onChange={(e: any) => {
                setPassword(e.target.value)
                setStateloginResult(false);
              }}
            />
          </div>


          {loginResult && (
            <p className="login-alert">Conta inválida!</p>
          )}
          <button type="submit" className="access-button button-wrapper" style={{ background: colorBtn }}>
            <GoChevronRight className="button-icon" />
            Acessar
          </button>
        </form>
      </div >
    );
  };
};




